import { ITextCard } from '@interfaces/api/ITextCard';
import { externalFetch, IRequestState } from './apiUtils';

export const apiFavoritePresetCard = async (textCardId: number | number[] | null, data: string) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/favorite/results/${textCardId}/`,
    );

    return response;
};

export const apiReportPresetCard = async (textCardId: number | number[] | null, data: string) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/reported/texts/${textCardId}`,
    );

    return response;
};
