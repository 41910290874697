import PillButton from '@components/common/custom-button/pill-button/PillButton';
import RoundButton from '@components/common/custom-button/round-button/RoundButton';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import { P3 } from '@components/common/typography/Typography';
import { iconCollections } from '@components/icon-collection/IconCollection';
import { Box, SxProps } from '@mui/material';

export type ToolbarButtonOptions = 'MOVE' | 'SHARE' | 'DOWNLOAD' | 'DELETE' | 'REPORT' | 'SAVE';
interface IToolbarButtonGroupProps {
    /**
     * an array of selections
     */
    selections?: any[];
    /**
     * clear selection
     */
    onClear?: () => void;
    /**
     * tool bar menu
     */
    menus?: ToolbarButtonOptions[];
    /**
     * tool bar menu
     */
    onClick?: (menu: ToolbarButtonOptions) => void;
}

const toolbarButtonOptions: {
    [key: string]: {
        enabled: any;
        disabled: any;
        title: string;
    };
} = {
    DOWNLOAD: {
        enabled: iconCollections.DOWNLOAD.src,
        disabled: iconCollections.DOWNLOAD_DISABLED.src,
        title: 'Download',
    },
    SHARE: {
        enabled: iconCollections.LINK.src,
        disabled: iconCollections.LINK_DISABLED.src,
        title: 'Share',
    },
    MOVE: {
        enabled: iconCollections.MOVE.src,
        disabled: iconCollections.MOVE_DISABLED.src,
        title: 'Move to',
    },
    DELETE: {
        enabled: iconCollections.DELETE.src,
        disabled: iconCollections.DELETE_DISABLED.src,
        title: 'Delete',
    },
    REPORT: {
        enabled: iconCollections.WARNING.src,
        disabled: iconCollections.WARNING_DISABLED.src,
        title: 'Report bad output',
    },
    SAVE: {
        enabled: iconCollections.SAVE.src,
        disabled: iconCollections.SAVE_DISABLED.src,
        title: 'Save output as...',
    },
};

const toolbarButtonGroupStyle: SxProps<any> = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 5,
};

const ToolbarButtonGroup: React.FC<IToolbarButtonGroupProps> = ({
    selections = [],
    menus = [],
    onClear = () => {},
    onClick = () => {},
}) => {
    return (
        <Box sx={{ ...toolbarButtonGroupStyle }}>
            {/* <Box> TODO: add the filter component here </Box> */}

            <Box display={'inline-flex'} alignItems={'center'} gap={2}>
                {!!selections?.length && (
                    <CustomTooltip key={'clear-button'} title={'Clear selection'} placement={'bottom-end'}>
                        <PillButton
                            label={<P3 color={'fc-white'}>{`${selections?.length} Selected`}</P3>}
                            onClick={onClear}
                        />
                    </CustomTooltip>
                )}

                {menus.map(menu => {
                    return (
                        <CustomTooltip
                            key={menu}
                            title={toolbarButtonOptions[menu].title}
                            placement={'bottom-end'}
                            disabled={!selections?.length}
                        >
                            <RoundButton
                                label={
                                    <img
                                        src={
                                            !selections?.length
                                                ? toolbarButtonOptions[menu].disabled
                                                : toolbarButtonOptions[menu].enabled
                                        }
                                        alt={'icon-warning'}
                                    />
                                }
                                onClick={() => {
                                    onClick(menu);
                                }}
                                disabled={!selections?.length}
                            />
                        </CustomTooltip>
                    );
                })}
            </Box>
        </Box>
    );
};

export default ToolbarButtonGroup;
