export type colorOptions =
    | 'fc-black'
    | 'fc-white'
    | 'fc-grey'
    | 'bg-white'
    | 'bg-grey'
    | 'hl-blue'
    | 'hl-purple'
    | 'hl-light-purple'
    | 'ol-green';

export const setColor = (variant: colorOptions | undefined): string => {
    let color = '#0D182F';

    /*
     ** font color
     */
    if (variant === 'fc-black') color = '#0D182F';
    if (variant === 'fc-white') color = '#FFFFFF';
    if (variant === 'fc-grey') color = '#A0AABE';
    /*
     ** background color
     */
    if (variant === 'bg-white') color = '#FFFFFF';
    if (variant === 'bg-grey') color = '#FCFCFC';
    /*
     ** highlight
     */
    if (variant === 'hl-blue') color = '#006DF0';
    if (variant === 'hl-purple') color = '#7163FF';
    if (variant === 'hl-light-purple') color = 'rgba(113, 99, 255, 0.3)';
    /*
     ** overlay
     */
    if (variant === 'ol-green') color = 'rgba(86, 193, 68, 0.9)';

    return color;
};
