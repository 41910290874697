import { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, SxProps } from '@mui/material';
import CustomInput from '@components/common/custom-input/CustomInput';
import InputOverlay from '@components/common/input-overlay/InputOverlay';
import { IModalProps } from '@components/custom-modals/IModalProps';
import { H4, P2 } from '@components/common/typography/Typography';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';

const modalStyle: SxProps = {
    '& .MuiPaper-root': {
        width: '500px',
        minWidth: '280px',
        maxWidth: '500px',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};

const ShareLinkModal: React.FC<IModalProps> = ({
    header = 'Add Modal Component',
    selections = [],
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = {
        label: 'Confirm',
        onConfirm: () => {},
    },
}) => {
    const [link, setLink] = useState<string>('');
    const [uid, setUid] = useState<string>('');
    const [showInputOverlay, setShowInputOverlay] = useState<boolean>(false);

    const textCardsList = selections.map(selectedTextCard => {
        return {
            results: selectedTextCard.results,
            template_name: selectedTextCard.template_name,
        };
    });

    useEffect(() => {
        const appendUrl = Array.from({ length: 45 }, () => Math.random().toString(36)[2]).join('');

        setLink(`https://ai-copywriter.madgicx.com/d/${appendUrl}`);

        setUid(appendUrl);
    }, []);

    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>

            <DialogContent>
                <InputOverlay showOverlay={showInputOverlay} setShowOverlay={setShowInputOverlay}>
                    <CustomInput value={link} disabled={true} />
                </InputOverlay>
            </DialogContent>

            <DialogActions sx={{ padding: 2 }}>
                <BorderlessButton label={<P2>{cancelButton?.label}</P2>} onClick={() => cancelButton?.onCancel()} />
                <DefaultButton
                    label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                    onClick={() => {
                        setShowInputOverlay(true);
                        confirmButton?.onConfirm(textCardsList, uid, link);
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ShareLinkModal;
