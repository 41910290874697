const TOKEN_KEY = 'jwt';

export const login = () => {
    localStorage.setItem(TOKEN_KEY, 'TestLogin');
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
    if (JSON.parse(localStorage.getItem('authTokens') || '{}').access) {
        return true;
    }
    return false;
};

export const isAdmin = () => {
    if (localStorage.getItem('isAdmin') === 'true') {
        return true;
    }
    return false;
};
