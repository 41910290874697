import { ICategory } from '@interfaces/api/ICategory';
import { ITemplate } from '@interfaces/api/ITemplate';
import { externalFetch, IRequestState } from './apiUtils';
import { IAlgorithm } from '@interfaces/api/IAlgorithm';

export const apiGetCategories = async (): Promise<IRequestState<ICategory[]>> => {
    const response: IRequestState<ICategory[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/dynamic/categories/`,
    );

    return response;
};

export const apiCreateNewCategory = async (data: string): Promise<IRequestState<ICategory[]>> => {
    const response: IRequestState<ICategory[]> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/categories/`,
    );

    return response;
};

export const apiUpdateCategory = async (id: number | null, data: string): Promise<IRequestState<ICategory[]>> => {
    const response: IRequestState<ICategory[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/categories/${id}/`,
    );

    return response;
};

export const apiUpdateTextPurposeCategory = async (
    template_id: number | null,
    data: string,
): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/templates/${template_id}/`,
    );

    return response;
};

export const apiGetTemplateDetail = async (id: number | null): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/templates/detail/${id}`,
    );

    return response;
};

export const apiCreateNewTemplate = async (data: string): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/templates/`,
    );

    return response;
};

export const apiGetTemplateDefaultPreset = async (id: number | null): Promise<IRequestState<IAlgorithm[]>> => {
    const response: IRequestState<IAlgorithm[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/template/${id}/default/preset/`,
    );

    return response;
};

export const apiUpdateTemplatePreset = async (data: string): Promise<IRequestState<IAlgorithm[]>> => {
    const response: IRequestState<IAlgorithm[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/presets/`,
    );

    return response;
};

export const apiCreateNewTemplatePreset = async (data: string): Promise<IRequestState<IAlgorithm[]>> => {
    const response: IRequestState<IAlgorithm[]> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/presets/`,
    );

    return response;
};

export const apiAdminGenerateIdeas = async (data: string): Promise<IRequestState<string[]>> => {
    const response: IRequestState<string> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/gpt3/ai/?$`,
    );

    return response;
};

export const apiGetSettingsTemplates = async (): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/templates/settings/`,
    );

    return response;
};
