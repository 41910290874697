/*
 *   spacing this a multiplier that is applied on margin and padding
 */
export const SPACING = 4;
/*
 *   breakpoints
 */
export const BREAKPOINTS = {
    xs: 0,
    sm: 475,
    md: 750,
    lg: 900,
    xl: 1200,
};
/*
 *   width of the side drawer / side panel
 */
export const DRAWER_WIDTH = 400;
/*
 *   items shown per page when using the pagination component
 */
export const ITEMS_PER_PAGE = 15;
/*
 * text custom color of character count on the bottom right corner of the textarea component
 */
export const DEFAULT_COLOR: string = '#0D182F';
export const WARNING_COLOR: string = '#D74100';

/*
 * text custom color of character count on the bottom right corner of the textarea component
 */
export const SKELECTON_COUNT: number = 3;

export const FILTER_CRITERIA: string[] = [
    'purchase_roas_omni_purchase',
    'madgicx_custom_conversion_rate_ecommerce',
    'action_values_omni_purchase',
    'outbound_clicks_ctr_outbound_click',
    'spend',
    'impressions',
    'actions_offsite_conversion_fb_pixel_purchase',
    'actions_landing_page_view',
];

export const TONE_OF_VOICE_DATA = [
    { id: 1, tone_of_voice: 'Neutral' },
    { id: 2, tone_of_voice: 'Casual' },
    { id: 3, tone_of_voice: 'Excited' },
    { id: 4, tone_of_voice: 'Funny' },
    { id: 5, tone_of_voice: 'Optimistic' },
    { id: 6, tone_of_voice: 'Respectful' },
    { id: 7, tone_of_voice: 'Upbeat' },
    { id: 8, tone_of_voice: 'Urgent' },
    { id: 9, tone_of_voice: 'Curious' },
    { id: 10, tone_of_voice: 'Professional' },
    { id: 11, tone_of_voice: 'Provocative' },
];
export const TEXT_DATA = [
    { id: 1, category: 'Short', length: '< 150', completion: 38 },
    { id: 2, category: 'Medium', length: 'Between 150 - 450', completion: 113 },
    { id: 3, category: 'Long', length: '> 450', completion: 250 },
];

export const AD_COPY_DATA = {
    creatives: [
        {
            insights: {
                'purchase_roas.omni_purchase': 0.8668343096141882,
                'madgicx_custom.conversion_rate_ecommerce': 4.481434058898849,
                'action_values.omni_purchase': 2354.4000000000005,
                'outbound_clicks_ctr.outbound_click': 0.4966169452262015,
                spend: 2716.09,
                impressions: 205591.0,
                'actions.offsite_conversion.fb_pixel_purchase': 35.0,
                'actions.landing_page_view': 781.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/e3efdf286c6c15c32a1c88eda7e16942bcb9979ac9b96cc61f56077891be0528.jpg',
            aws_tags: ['Human', 'Person'],
            dimension: '1:1',
            adsets: [
                '23849863053510131',
                '23849863023140131',
                '23849862982920131',
                '23849870884270131',
                '23849870838880131',
                '23849862952740131',
                '23849691511040131',
                '23849841299230131',
                '23849862993810131',
                '23849893546280131',
                '23849734633880131',
                '23849862968420131',
                '23849863023170131',
                '23849863023120131',
                '23849862952710131',
                '23849863074590131',
                '23849893546220131',
                '23849862975790131',
                '23849863053570131',
                '23849893546240131',
                '23849863034130131',
                '23849862983010131',
                '23849863090460131',
                '23849893546250131',
                '23849862975740131',
                '23849863034030131',
                '23849863034090131',
                '23849863090420131',
                '23849723631110131',
                '23849862993830131',
                '23849893546210131',
                '23849893546270131',
                '23849863053550131',
                '23849863034050131',
                '23849862968470131',
                '23849862975670131',
                '23849893546320131',
                '23849863090510131',
                '23849862952700131',
                '23849863023180131',
                '23849862982960131',
                '23849862983000131',
                '23849803054850131',
                '23849862993840131',
                '23849863053620131',
                '23849862968540131',
                '23849862968450131',
                '23849862975700131',
                '23849893546230131',
                '23849893546290131',
                '23849863074610131',
                '23849893546310131',
                '23849863074650131',
                '23849893546260131',
                '23849862648690131',
                '23849863074690131',
                '23849862993770131',
                '23849863090520131',
            ],
            ads: [
                '23849893546340131',
                '23849863090500131',
                '23849803054860131',
                '23849893546420131',
                '23849862968460131',
                '23849862952720131',
                '23849863074670131',
                '23849893546350131',
                '23849863053610131',
                '23849893546450131',
                '23849893546470131',
                '23849862982950131',
                '23849862648710131',
                '23849862975720131',
                '23849862975730131',
                '23849893546410131',
                '23849862975660131',
                '23849863034070131',
                '23849863034100131',
                '23849893546380131',
                '23849862982930131',
                '23849862952680131',
                '23849862952760131',
                '23849862982940131',
                '23849691511260131',
                '23849862975750131',
                '23849862993800131',
                '23849863023090131',
                '23849863074660131',
                '23849863090410131',
                '23849893546520131',
                '23849862968430131',
                '23849862968520131',
                '23849841299160131',
                '23849863074570131',
                '23849862993760131',
                '23849862968500131',
                '23849863023080131',
                '23849863053530131',
                '23849863023130131',
                '23849734633750131',
                '23849863023150131',
                '23849863053500131',
                '23849863034020131',
                '23849863053590131',
                '23849862993850131',
                '23849870838830131',
                '23849863090430131',
                '23849870884150131',
                '23849893546300131',
                '23849723631120131',
                '23849863090440131',
                '23849893546500131',
                '23849863074680131',
                '23849863034110131',
                '23849862993740131',
                '23849893546530131',
                '23849862982890131',
            ],
            dco_ads: [],
            creative_id: 1532507,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 5.158554572271386,
                'madgicx_custom.conversion_rate_ecommerce': 50.0,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 13.56,
                impressions: 448.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/374213345dbbc934584830dc7df2d1a1847df532bf62c20f63a748511bc1e5fd.jpg',
            aws_tags: ['Cushion', 'Pillow'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131'],
            creative_id: 1923867,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.836577453365775,
                'madgicx_custom.conversion_rate_ecommerce': 8.333333333333332,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.5351412971815912,
                spend: 24.659999999999997,
                impressions: 2803.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 12.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/a18cf4095b3fb26dc5ffbee74f41a95cbf5fc9ea1f2dcea677f853d4cb567e7e.jpg',
            aws_tags: ['Asleep', 'Sleeping'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849655273410131', '23849655273400131'],
            creative_id: 4330164,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.7968129880478088,
                spend: 5.45,
                impressions: 251.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/d004cea33bedc960069d6c03e9d9b03b1a2d6790e408d380de40829926b875c.jpg',
            aws_tags: ['Person', 'Clothing'],
            dimension: '1:1',
            adsets: ['23849812041060131', '23849812062420131'],
            ads: ['23849812062450131', '23849812041050131'],
            dco_ads: [],
            creative_id: 5028907,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 4.046388564359837,
                'madgicx_custom.conversion_rate_ecommerce': 14.516129032258066,
                'action_values.omni_purchase': 588.79,
                'outbound_clicks_ctr.outbound_click': 0.787324,
                spend: 145.51,
                impressions: 10161.0,
                'actions.offsite_conversion.fb_pixel_purchase': 9.0,
                'actions.landing_page_view': 62.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/1458940c19082b7c4de81224a847c7f21c0df096550f26da5599c8454f583e8b.jpg',
            aws_tags: ['No tags found'],
            dimension: 'other',
            adsets: ['23848835914040131'],
            ads: ['23848835916310131'],
            dco_ads: [],
            creative_id: 5573264,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.41696,
                spend: 136.85,
                impressions: 18467.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 52.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/ae917470f877ab5dac4939295c57f3cf56f7903a905e8213b6bd58082e89463a.jpg',
            aws_tags: ['Person', 'Human'],
            dimension: '1:1',
            adsets: ['23849453578980131'],
            ads: ['23849453595200131'],
            dco_ads: [],
            creative_id: 5931748,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 64.32183908045977,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 55.96,
                'outbound_clicks_ctr.outbound_click': 2.631579,
                spend: 0.87,
                impressions: 38.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/88e1d5c7ead4492b9676a3464a955cf33afed8f98132c5de273aa0368aed103e.mp4',
            aws_tags: ['Person', 'Human', 'Tabletop', 'Room', 'Indoors', 'Furniture'],
            dimension: '1:1',
            adsets: ['23848835914040131'],
            ads: ['23848835916320131'],
            dco_ads: [],
            creative_id: 5931804,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.384083,
                spend: 7.26,
                impressions: 289.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/880a3924d34f502a1eb3f72707e9d7fb4eb1a5b3df7af25073660e59a7931350.mp4',
            aws_tags: ['Face', 'Clothing', 'Human', 'Person', 'Apparel'],
            dimension: '16:9',
            adsets: ['23849691511040131'],
            ads: ['23849691511270131'],
            dco_ads: [],
            creative_id: 5971116,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 16.85,
                impressions: 225.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/a7805da15f92547cfbdc9785cddb655543032f7cd346e03780bd8dccc1134139.mp4',
            aws_tags: ['Plant', 'Vase', 'Head', 'Pottery', 'Person', 'Human', 'Jar', 'Potted Plant', 'Face'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849832988680131'],
            creative_id: 6095924,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.475900782567818,
                'madgicx_custom.conversion_rate_ecommerce': 16.129032258064516,
                'action_values.omni_purchase': 895.36,
                'outbound_clicks_ctr.outbound_click': 0.713546,
                spend: 361.63,
                impressions: 17378.0,
                'actions.offsite_conversion.fb_pixel_purchase': 15.0,
                'actions.landing_page_view': 93.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/85f66f2bf4226e15f1f97e6d0a61389c3a3816b1079c293737827c9133c15193.mp4',
            aws_tags: ['Room', 'Indoors'],
            dimension: '1:1',
            adsets: ['23849691511040131'],
            ads: ['23849691511230131'],
            dco_ads: [],
            creative_id: 6150025,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.872093,
                spend: 10.93,
                impressions: 344.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 1.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/26cccf02f12002185aabe5c55fa47886c7047bc402265b39e237f2b97324a314.mp4',
            aws_tags: ['Face', 'Furniture', 'Human', 'Person', 'Diaper', 'Toothpaste', 'Cushion', 'Bed', 'Pillow'],
            dimension: '16:9',
            adsets: ['23849655273550131'],
            ads: ['23849655273340131'],
            dco_ads: [],
            creative_id: 6153938,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 3.1752156150703588,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 22.03,
                impressions: 203.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/e5482bd2e110539b9ecf6bc7436c5cd8ac9080300353368650171711aff39225.mp4',
            aws_tags: ['Human', 'Person', 'Cushion', 'Pillow'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849832988680131'],
            creative_id: 6192271,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.36504540235883526,
                'madgicx_custom.conversion_rate_ecommerce': 1.9607843137254901,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.24522453588022716,
                spend: 191.61999999999998,
                impressions: 23244.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 51.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/656c81add4c4d905c4825fecc87801ab9f08462e8a3cbbfabb0361908110f800.jpg',
            aws_tags: ['Human', 'Person'],
            dimension: '1:1',
            adsets: [
                '23849893546270131',
                '23849893546210131',
                '23849893546230131',
                '23849893546290131',
                '23849893546310131',
                '23849893546320131',
                '23849893546220131',
                '23849893546260131',
                '23849893546240131',
                '23849893546280131',
                '23849893546250131',
            ],
            ads: [
                '23849893827330131',
                '23849893841960131',
                '23849893822820131',
                '23849893824680131',
                '23849893823930131',
                '23849893823520131',
                '23849893825210131',
                '23849893824070131',
                '23849893834250131',
                '23849893829080131',
                '23849893826530131',
            ],
            dco_ads: ['23849886442300131', '23849886610800131'],
            creative_id: 6210021,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.9661923969967627,
                'madgicx_custom.conversion_rate_ecommerce': 6.065857885615253,
                'action_values.omni_purchase': 2280.3299999999995,
                'outbound_clicks_ctr.outbound_click': 0.5347276385558887,
                spend: 2360.12,
                impressions: 201785.0,
                'actions.offsite_conversion.fb_pixel_purchase': 35.0,
                'actions.landing_page_view': 577.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/9f0eb0e55a70fe56684f4b15ae55bf8a7116eb7be7db40489206eff9357e5ad6.mp4',
            aws_tags: [
                'Lamp',
                'Table Lamp',
                'Face',
                'Clothing',
                'Logo',
                'Symbol',
                'Trademark',
                'Human',
                'Person',
                'Toothpaste',
                'Apparel',
                'Jaw',
            ],
            dimension: '1:1',
            adsets: [
                '23849734633890131',
                '23849723631090131',
                '23849803058010131',
                '23849734633870131',
                '23849870884280131',
                '23849723631080131',
                '23849870838800131',
                '23849870884250131',
                '23849841299170131',
                '23849841299090131',
                '23849734588940131',
                '23849870838890131',
            ],
            ads: [
                '23849723631150131',
                '23849841299120131',
                '23849723631140131',
                '23849734633850131',
                '23849870838840131',
                '23849734633740131',
                '23849803058000131',
                '23849870838770131',
                '23849870884210131',
                '23849841299100131',
                '23849870884130131',
                '23849734588930131',
            ],
            dco_ads: [],
            creative_id: 6217709,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.4069364601972056,
                'madgicx_custom.conversion_rate_ecommerce': 6.711409395973155,
                'action_values.omni_purchase': 739.12,
                'outbound_clicks_ctr.outbound_click': 0.4631964539327177,
                spend: 525.34,
                impressions: 67358.0,
                'actions.offsite_conversion.fb_pixel_purchase': 10.0,
                'actions.landing_page_view': 149.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/7b14c3c9739cb1e9dae5f58ca5bd1d9d68c617dd9130ce99fc38f1f4d0e653ff.mp4',
            aws_tags: ['Diaper', 'Sink Faucet', 'Label', 'Text', 'Cushion'],
            dimension: '1:1',
            adsets: ['23849691511040131', '23849690163100131'],
            ads: ['23849690163110131', '23849691511280131'],
            dco_ads: [],
            creative_id: 6218564,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.3367054290595908,
                'madgicx_custom.conversion_rate_ecommerce': 7.017543859649123,
                'action_values.omni_purchase': 3066.83,
                'outbound_clicks_ctr.outbound_click': 0.9928671285156454,
                spend: 2294.3199999999997,
                impressions: 192070.0,
                'actions.offsite_conversion.fb_pixel_purchase': 48.0,
                'actions.landing_page_view': 684.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/e40db377c363c9455e7ac60780b3801072dd53b4d80b5480c5b36dbd32b851f3.mp4',
            aws_tags: [
                'Kid',
                'Cup',
                'Woman',
                'Coffee Cup',
                'Human',
                'Girl',
                'Female',
                'Person',
                'Teen',
                'Blonde',
                'Child',
            ],
            dimension: '4:5',
            adsets: ['23849734633830131', '23849841299080131', '23849734599990131', '23849690163090131'],
            ads: ['23849734633780131', '23849734599980131', '23849690163130131', '23849841299190131'],
            dco_ads: [],
            creative_id: 6218569,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 2.702703,
                spend: 11.32,
                impressions: 1591.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 4.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/f76fe599555291e866116f10e8b184948dd61bf4df91d293cd9e407301f70fe0.mp4',
            aws_tags: ['Poster', 'Paper', 'Advertisement', 'Human', 'Person', 'Brochure', 'Flyer'],
            dimension: '1:1',
            adsets: ['23849872860060131'],
            ads: ['23849872860110131'],
            dco_ads: [],
            creative_id: 6237738,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 3.293027812451762,
                spend: 32.69,
                impressions: 3887.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 19.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/3fa4ab1fce851ca8c1e1fdbba596c3016b0b8eb309d584a25e7285369bac6f9c.mp4',
            aws_tags: ['Face', 'Person', 'Human'],
            dimension: '1:1',
            adsets: ['23849812041060131', '23849748837950131'],
            ads: ['23849748838150131', '23849812041080131'],
            dco_ads: [],
            creative_id: 6237739,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.201249,
                spend: 81.86,
                impressions: 24348.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 15.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/3ca9b20ec07c05e586b3a6911c3e8ead56cebf54e77dbefd649ef756163df803.mp4',
            aws_tags: ['Accessory', 'Face', 'Human', 'Glasses', 'Person', 'Room', 'Indoors', 'Accessories'],
            dimension: '1:1',
            adsets: ['23849691917680131'],
            ads: ['23849691917670131'],
            dco_ads: [],
            creative_id: 6237754,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.8034705177236146,
                'madgicx_custom.conversion_rate_ecommerce': 15.789473684210526,
                'action_values.omni_purchase': 982.2800000000001,
                'outbound_clicks_ctr.outbound_click': 0.6423084983942284,
                spend: 350.38,
                impressions: 21485.0,
                'actions.offsite_conversion.fb_pixel_purchase': 15.0,
                'actions.landing_page_view': 95.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/e8ba40f941387a17a4293abd4731c9da0dff36c66d8fb507f0fda765dcc4220.mp4',
            aws_tags: [
                'Face',
                'Diaper',
                'Human',
                'Sleeping',
                'Person',
                'Hand',
                'Room',
                'Asleep',
                'Indoors',
                'Cushion',
                'Pillow',
            ],
            dimension: '1:1',
            adsets: [
                '23849841299180131',
                '23849812062420131',
                '23849734633840131',
                '23849870884290131',
                '23849734595390131',
                '23849870838910131',
            ],
            ads: [
                '23849870884220131',
                '23849734595380131',
                '23849870838870131',
                '23849812062430131',
                '23849734633800131',
                '23849841299130131',
            ],
            dco_ads: [],
            creative_id: 6237757,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 4.391595033428843,
                'madgicx_custom.conversion_rate_ecommerce': 100.0,
                'action_values.omni_purchase': 91.96,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 20.94,
                impressions: 276.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 1.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/e1ba824f01047977b8701086380098b5d0545ed14d52caab84ef941b958d48a4.mp4',
            aws_tags: ['Text'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849832988680131'],
            creative_id: 6248043,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.7878683782317841,
                'madgicx_custom.conversion_rate_ecommerce': 6.84931506849315,
                'action_values.omni_purchase': 988.8699999999999,
                'outbound_clicks_ctr.outbound_click': 0.6446578947440867,
                spend: 553.1,
                impressions: 43589.0,
                'actions.offsite_conversion.fb_pixel_purchase': 15.0,
                'actions.landing_page_view': 219.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/f77674b398da88c9f580da448b43061ebce4fe384d8c7ad7f9dddc37dce8fb13.mp4',
            aws_tags: ['Diaper', 'Sink Faucet', 'Label', 'Text', 'Cushion'],
            dimension: '1:1',
            adsets: ['23849723631130131', '23849841299210131', '23849734633860131'],
            ads: ['23849841299220131', '23849734633820131', '23849723631100131'],
            dco_ads: [],
            creative_id: 6257930,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.9653955210925295,
                'madgicx_custom.conversion_rate_ecommerce': 8.771929824561404,
                'action_values.omni_purchase': 333.65999999999997,
                'outbound_clicks_ctr.outbound_click': 0.5375981432699416,
                spend: 345.61999999999995,
                impressions: 14881.0,
                'actions.offsite_conversion.fb_pixel_purchase': 5.0,
                'actions.landing_page_view': 57.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/17c2bf56fcf5082ad3c96f157b28041508cc5aea7e711594658f6d61d7129cc3.jpg',
            aws_tags: ['Human', 'Person'],
            dimension: '1:1',
            adsets: [
                '23849734633770131',
                '23849870838820131',
                '23849734824970131',
                '23849841299110131',
                '23849870884240131',
            ],
            ads: [
                '23849870884190131',
                '23849870838900131',
                '23849734633730131',
                '23849841299150131',
                '23849734824960131',
            ],
            dco_ads: [],
            creative_id: 6271832,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.7625017032293228,
                'madgicx_custom.conversion_rate_ecommerce': 3.0303030303030303,
                'action_values.omni_purchase': 55.96,
                'outbound_clicks_ctr.outbound_click': 1.972556,
                spend: 73.39,
                impressions: 2332.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 33.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_long'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/22c75a22263af51bd62cf1c8d1ee98e7fe14975178ea40754ff6c3e9675c608a.mp4',
            aws_tags: ['Human', 'Face', 'Indoors', 'Accessory', 'Accessories', 'Glasses', 'Person', 'Room'],
            dimension: '1:1',
            adsets: ['23849691511040131'],
            ads: ['23849691511240131'],
            dco_ads: [],
            creative_id: 6328012,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.6760677308024159,
                'madgicx_custom.conversion_rate_ecommerce': 5.223880597014925,
                'action_values.omni_purchase': 752.22,
                'outbound_clicks_ctr.outbound_click': 0.5942074415544173,
                spend: 1112.64,
                impressions: 54863.0,
                'actions.offsite_conversion.fb_pixel_purchase': 14.0,
                'actions.landing_page_view': 268.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/5625a63d8e531b13daaed74163c0de030757a184304234448e6fd8eb8db43cc2.jpg',
            aws_tags: ['Room', 'Indoors'],
            dimension: '1:1',
            adsets: [
                '23849804482080131',
                '23849870838920131',
                '23849804505690131',
                '23849841299240131',
                '23849691511040131',
                '23849734605220131',
                '23849870884160131',
            ],
            ads: [
                '23849870884230131',
                '23849804482070131',
                '23849804505680131',
                '23849734605210131',
                '23849841299140131',
                '23849804512950131',
                '23849870838780131',
            ],
            dco_ads: [],
            creative_id: 6354664,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.341297,
                spend: 11.2,
                impressions: 586.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/4d7c70102a616f5e3055483b210547e0c2701aa564b115bba9149c050fed6607.mp4',
            aws_tags: ['Human', 'Person'],
            dimension: '16:9',
            adsets: ['23849830715840131'],
            ads: ['23849830715910131'],
            dco_ads: [],
            creative_id: 6392476,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.3886683077900421,
                'madgicx_custom.conversion_rate_ecommerce': 10.869565217391305,
                'action_values.omni_purchase': 349.75,
                'outbound_clicks_ctr.outbound_click': 1.0129233216905345,
                spend: 251.85999999999999,
                impressions: 5726.0,
                'actions.offsite_conversion.fb_pixel_purchase': 5.0,
                'actions.landing_page_view': 46.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_short'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/477dca9a762bbf0fe8c17f579528dd18b841e55333740a91ba10641f00e2c04d.mp4',
            aws_tags: ['Human', 'Person'],
            dimension: '1:1',
            adsets: ['23849733739760131'],
            ads: ['23849733739770131'],
            dco_ads: ['23849831347930131'],
            creative_id: 6392478,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 3.9726155282402367,
                'madgicx_custom.conversion_rate_ecommerce': 44.44444444444444,
                'action_values.omni_purchase': 255.32,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 64.27,
                impressions: 1809.0,
                'actions.offsite_conversion.fb_pixel_purchase': 4.0,
                'actions.landing_page_view': 9.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/5c9641760dfdec6743e9d216a6884b0043573b3a88fe09c6c9ff2eccb74d141e.jpg',
            aws_tags: ['Mobile Phone', 'Electronics', 'Phone', 'Cell Phone'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131'],
            creative_id: 6392479,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 24.47,
                impressions: 651.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 1.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/9a30b93dda047eac6e32fa91c321b4d51081db1d36cda37108505cb3315f7e76.jpg',
            aws_tags: ['Lab Coat', 'Doctor', 'Accessory', 'Accessories', 'Clothing', 'Person', 'Tie', 'Coat'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131'],
            creative_id: 6392480,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.3284862043251304,
                'madgicx_custom.conversion_rate_ecommerce': 50.0,
                'action_values.omni_purchase': 124.9,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 53.64,
                impressions: 1389.0,
                'actions.offsite_conversion.fb_pixel_purchase': 2.0,
                'actions.landing_page_view': 4.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/8c53bf9190cb2a30d062465c0e2c9ecea1fdfab05ba4bdbf76a3318bffa4a5d3.jpg',
            aws_tags: ['Human', 'Person'],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849733758200131'],
            creative_id: 6392481,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.238930216082182,
                'madgicx_custom.conversion_rate_ecommerce': 7.142857142857143,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 1.3470679722662444,
                spend: 56.46,
                impressions: 1262.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 14.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/8d6025ba9c17ec0444a5e22eb6ca0f334e835e64cfd2b542388a86a58886edda.mp4',
            aws_tags: [
                'Human',
                'Apparel',
                'Pillow',
                'Bed',
                'Trademark',
                'Person',
                'Cushion',
                'Clothing',
                'Logo',
                'Symbol',
                'Furniture',
                'Word',
            ],
            dimension: '1:1',
            adsets: ['23849733755820131', '23849733762250131'],
            ads: ['23849733762240131', '23849733755830131'],
            dco_ads: [],
            creative_id: 6392538,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 34.89,
                impressions: 479.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/5dd4ea27dc986251500f10ef7d0ad8f5db1852c7bd36e0d61e5a3190a12a8b6d.mp4',
            aws_tags: [
                'Human',
                'Face',
                'Apparel',
                'Lamp',
                'Trademark',
                'Table Lamp',
                'Person',
                'Clothing',
                'Jaw',
                'Logo',
                'Symbol',
            ],
            dimension: '1:1',
            adsets: [],
            ads: [],
            dco_ads: ['23849832988680131'],
            creative_id: 6392539,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.928881313913852,
                'madgicx_custom.conversion_rate_ecommerce': 9.090909090909092,
                'action_values.omni_purchase': 59.95,
                'outbound_clicks_ctr.outbound_click': 0.4168708771456597,
                spend: 64.53999999999999,
                impressions: 4078.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 11.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/d47321eb9082b6298b2654e2534bfb049a741c00832f2bc33ac814a7947e517d.jpg',
            aws_tags: ['Electronics'],
            dimension: '1:1',
            adsets: ['23849886610790131', '23849733666750131', '23849886442040131'],
            ads: ['23849733666740131', '23849893849830131', '23849893848700131'],
            dco_ads: [],
            creative_id: 6419600,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 4.2263226649248855,
                'madgicx_custom.conversion_rate_ecommerce': 100.0,
                'action_values.omni_purchase': 129.41,
                'outbound_clicks_ctr.outbound_click': 0.674157,
                spend: 30.62,
                impressions: 445.0,
                'actions.offsite_conversion.fb_pixel_purchase': 2.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/ec9a068b622ed071c9ba5820d89df7899cad8eed4c5a3d372286e2648edf45d7.mp4',
            aws_tags: ['Human', 'Person', 'Cushion', 'Pillow'],
            dimension: '1:1',
            adsets: ['23849733666760131'],
            ads: ['23849733666770131'],
            dco_ads: [],
            creative_id: 6419609,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.384615,
                spend: 21.51,
                impressions: 520.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/d9403f33f25674e5ed8ae8acd16db608cf489531633d9c520711f2c226eecc2a.mp4',
            aws_tags: [
                'Mouth',
                'Teeth',
                'Hardware',
                'Human',
                'Jaw',
                'Pillow',
                'Person',
                'Head',
                'Computer',
                'Mouse',
                'Logo',
                'Trademark',
                'Face',
                'Electronics',
                'Sleeping',
                'Asleep',
                'Symbol',
                'Cushion',
                'Furniture',
                'Lip',
            ],
            dimension: '4:5',
            adsets: ['23849733666730131'],
            ads: ['23849733666720131'],
            dco_ads: [],
            creative_id: 6419610,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 2.768166,
                spend: 25.35,
                impressions: 289.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 5.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/d35c1d3ddab21ba0bd35714c76938f15a5896123ab893317f84a57c66669c280.mp4',
            aws_tags: [
                'Apparel',
                'Human',
                'Word',
                'Pillow',
                'Person',
                'Logo',
                'Trademark',
                'Bed',
                'Clothing',
                'Symbol',
                'Cushion',
                'Furniture',
            ],
            dimension: '1:1',
            adsets: ['23849733728520131'],
            ads: ['23849733728510131'],
            dco_ads: [],
            creative_id: 6419612,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.042945,
                spend: 26.17,
                impressions: 1630.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 11.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/1c21ca69db82d62c44d844d698dec0990d0d22869c36e8453e4ad1ec74819184.mp4',
            aws_tags: [
                'Apparel',
                'Face',
                'Symbol',
                'Human',
                'Lamp',
                'Tub',
                'Person',
                'Table Lamp',
                'Logo',
                'Clothing',
                'Bathtub',
                'Jaw',
                'Trademark',
            ],
            dimension: '1:1',
            adsets: ['23849886442040131'],
            ads: ['23849886505680131'],
            dco_ads: [],
            creative_id: 6439126,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.19926323047964561,
                'madgicx_custom.conversion_rate_ecommerce': 1.510574018126888,
                'action_values.omni_purchase': 320.76,
                'outbound_clicks_ctr.outbound_click': 0.32505997592672353,
                spend: 1609.7300000000002,
                impressions: 148588.0,
                'actions.offsite_conversion.fb_pixel_purchase': 5.0,
                'actions.landing_page_view': 331.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/8acedc528c25faaaad5edede6ea1249ecd590bdcc268a279e992596c78096916.jpg',
            aws_tags: ['Phone', 'Electronics', 'Cell Phone', 'Mobile Phone'],
            dimension: '1:1',
            adsets: [
                '23849893546270131',
                '23849893546210131',
                '23849893546230131',
                '23849893546290131',
                '23849893546310131',
                '23849893546320131',
                '23849893546220131',
                '23849893546260131',
                '23849893546240131',
                '23849893546280131',
                '23849893546250131',
            ],
            ads: [
                '23849893860790131',
                '23849893873860131',
                '23849893862760131',
                '23849893861960131',
                '23849893863630131',
                '23849893867630131',
                '23849893866300131',
                '23849893862290131',
                '23849893864650131',
                '23849893866750131',
                '23849893869000131',
            ],
            dco_ads: [],
            creative_id: 6468753,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 0.49,
                impressions: 630.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 1.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/c484ed4ab3bbca5bf5aaa304bba8b468f321e9bb967393685a8504750e63f1f6.jpg',
            aws_tags: ['Person'],
            dimension: 'other',
            adsets: [],
            ads: [],
            dco_ads: ['23849886442300131', '23849886610800131'],
            creative_id: 6468754,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 1.67,
                impressions: 40.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['CreativeTypes.image'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-images/act_110959719471933/45494508a3aaa241df26c3e42bff8305ff11f3293a4f095ca358a550d882929c.jpg',
            aws_tags: ['Human', 'Person'],
            dimension: '9:16',
            adsets: [],
            ads: [],
            dco_ads: ['23849733758200131'],
            creative_id: 6468756,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.035078,
                impressions: 1739.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 9.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/ea4e5004241c9cd6c916cb90c8c484e43ba3bfdc6ab42a9ba1826da603dff7a8.mp4',
            aws_tags: [
                'Apparel',
                'Table Lamp',
                'Human',
                'Jaw',
                'Logo',
                'Person',
                'Trademark',
                'Face',
                'Clothing',
                'Symbol',
                'Lamp',
            ],
            dimension: '1:1',
            adsets: ['23849886610790131'],
            ads: ['23849886610780131'],
            dco_ads: [],
            creative_id: 6468777,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.666667,
                spend: 2.98,
                impressions: 180.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['CreativeTypes.video', 'VideoTypes.video_medium'],
            url: 'https://s3-us-east-2.amazonaws.com/madgicx-cc-videos/act_110959719471933/1c7809f856234758f50594682a181c91eaf51e56f8b7c0555bfa30a8abaaf114.mp4',
            aws_tags: ['Face', 'Person', 'Human'],
            dimension: '16:9',
            adsets: ['23849892848480131'],
            ads: ['23849892848500131'],
            dco_ads: [],
            creative_id: 6468778,
        },
    ],
    texts: [
        {
            insights: {
                'purchase_roas.omni_purchase': 0.8668343096141882,
                'madgicx_custom.conversion_rate_ecommerce': 4.481434058898849,
                'action_values.omni_purchase': 2354.4000000000005,
                'outbound_clicks_ctr.outbound_click': 0.4966169452262015,
                spend: 2716.09,
                impressions: 205591.0,
                'actions.offsite_conversion.fb_pixel_purchase': 35.0,
                'actions.landing_page_view': 781.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.quote', 'TextTypes.long_text', 'TextTypes.link'],
            text: '\u2b50\u2b50\u2b50\u2b50\u2b50\n""Stopped snoring the first night. My husband was so excited to finally get a full nights sleep instead of listening to me cutting logs all night!"" \nBreanne - VitalSleep Customer\n\nThe VitalSleep anti-snoring mouthpiece is backed by a 60-Night, 100% Money-Back Guarantee. Plus it\'s proudly made in the USA! \ud83c\uddfa\ud83c\uddf8 \n\nTry the adjustable, anti-snoring mouthpiece with the patented Accu-Adjust System and custom teeth impressions for 60-nights risk free.\n\nVitalSleep holds the lower jaw forward to keep the airway clear and alleviate snoring.\n\n\u27a1\ufe0f Patented Accu-Adjust System: Provides precise jaw placement for a personalized fit.\n\n\u27a1\ufe0f Advanced Comfort: Easy fitting process provides a custom molded for comfort.\n\n\u27a1\ufe0f Effective: VitalSleep is FDA-cleared to reduce snoring for improved breathing and healthier sleep. Clearer airways = less snoring!! \ud83d\ude4c\n\nClick here to learn more. >> https://www.vitalsleep.com/products/vitalsleep-anti-snoring-device',
            language: 'en',
            aws_phrases: [
                'the first night',
                'My husband',
                'a full nights sleep',
                'logs',
                'all night',
                'Breanne - VitalSleep Customer',
                'The VitalSleep anti-snoring mouthpiece',
                'a 60-Night, 100% Money-Back Guarantee.',
                'the USA',
                'the adjustable, anti-snoring mouthpiece',
                'the patented Accu-Adjust System',
                'custom teeth',
                'impressions',
                '60-nights risk',
                'VitalSleep',
                'the lower jaw',
                'the airway',
                '\u27a1\ufe0f',
                'Patented Accu-Adjust System',
                'Provides precise jaw placement',
                'a personalized fit',
                '\u27a1\ufe0f',
                'Advanced Comfort',
                'process',
                'a custom',
                'comfort',
                '\u27a1\ufe0f Effective',
                'VitalSleep',
                'improved breathing',
                'healthier sleep',
            ],
            aws_tags: {
                QUANTITY: ['first night', 'nights', '60-Night', '100%', '60-nights'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                LOCATION: ['USA'],
                ORGANIZATION: ['FDA'],
                OTHER: ['https://www.vitalsleep.com/products/vitalsleep-anti-snoring-device'],
            },
            adsets: [
                '23849863053510131',
                '23849863023140131',
                '23849862982920131',
                '23849870884270131',
                '23849870838880131',
                '23849862952740131',
                '23849691511040131',
                '23849841299230131',
                '23849862993810131',
                '23849893546280131',
                '23849734633880131',
                '23849862968420131',
                '23849863023170131',
                '23849863023120131',
                '23849862952710131',
                '23849863074590131',
                '23849893546220131',
                '23849862975790131',
                '23849863053570131',
                '23849893546240131',
                '23849863034130131',
                '23849862983010131',
                '23849863090460131',
                '23849893546250131',
                '23849862975740131',
                '23849863034030131',
                '23849863034090131',
                '23849863090420131',
                '23849723631110131',
                '23849862993830131',
                '23849893546210131',
                '23849893546270131',
                '23849863053550131',
                '23849863034050131',
                '23849862968470131',
                '23849862975670131',
                '23849893546320131',
                '23849863090510131',
                '23849862952700131',
                '23849863023180131',
                '23849862982960131',
                '23849862983000131',
                '23849803054850131',
                '23849862993840131',
                '23849863053620131',
                '23849862968540131',
                '23849862968450131',
                '23849862975700131',
                '23849893546230131',
                '23849893546290131',
                '23849863074610131',
                '23849893546310131',
                '23849863074650131',
                '23849893546260131',
                '23849862648690131',
                '23849863074690131',
                '23849862993770131',
                '23849863090520131',
            ],
            ads: [
                '23849893546340131',
                '23849863090500131',
                '23849803054860131',
                '23849893546420131',
                '23849862968460131',
                '23849862952720131',
                '23849863074670131',
                '23849893546350131',
                '23849863053610131',
                '23849893546450131',
                '23849893546470131',
                '23849862982950131',
                '23849862648710131',
                '23849862975720131',
                '23849862975730131',
                '23849893546410131',
                '23849862975660131',
                '23849863034070131',
                '23849863034100131',
                '23849893546380131',
                '23849862982930131',
                '23849862952680131',
                '23849862952760131',
                '23849862982940131',
                '23849691511260131',
                '23849862975750131',
                '23849862993800131',
                '23849863023090131',
                '23849863074660131',
                '23849863090410131',
                '23849893546520131',
                '23849862968430131',
                '23849862968520131',
                '23849841299160131',
                '23849863074570131',
                '23849862993760131',
                '23849862968500131',
                '23849863023080131',
                '23849863053530131',
                '23849863023130131',
                '23849734633750131',
                '23849863023150131',
                '23849863053500131',
                '23849863034020131',
                '23849863053590131',
                '23849862993850131',
                '23849870838830131',
                '23849863090430131',
                '23849870884150131',
                '23849893546300131',
                '23849723631120131',
                '23849863090440131',
                '23849893546500131',
                '23849863074680131',
                '23849863034110131',
                '23849862993740131',
                '23849893546530131',
                '23849862982890131',
            ],
            dco_ads: [],
            links: ['https://www.vitalsleep.com/products/vitalsleep-anti-snoring-device'],
            emojis: ['\ud83c\uddfa', '\ud83d\ude4c', '\u2b50', '\ud83c\uddfa\ud83c\uddf8', '\ud83c\uddf8', '\u27a1'],
            text_id: 1336449,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.0029805013927577,
                'madgicx_custom.conversion_rate_ecommerce': 5.992509363295881,
                'action_values.omni_purchase': 1080.21,
                'outbound_clicks_ctr.outbound_click': 0.43257049524966074,
                spend: 1077.0,
                impressions: 125991.0,
                'actions.offsite_conversion.fb_pixel_purchase': 16.0,
                'actions.landing_page_view': 267.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.quote', 'TextTypes.long_text', 'TextTypes.link'],
            text: "\"My husband has had his appliance for about 3 years. Our bedroom was silent for the first time ever.\" \ud83d\ude34\nD. Branson - VitalSleep Customer \u2b50\u2b50\u2b50\u2b50\n\nSnoring is caused by a narrowing of the airway in the throat.\n\nUnlike other devices, VitalSleep targets the root cause of snoring.\n\nVitalSleep's patented Accu-Adjust System holds the jaw and tongue forward to open the airway. Plus, for comfort, it molds to a user's teeth for a personalized fit.\n\nThis combination is the best way for a snorer to experience restful sleep.\n\nIt's the same method that doctors and dentists use with their snoring patients. Now available in sizes for men and women.\n\nTry VitalSleep for 60-nights. It comes with a 100% money-back guarantee, a 1-year warranty and it's made in the USA.\n\nOrder at https://www.VitalSleep.com for free, same-day shipping for fast delivery.",
            language: 'en',
            aws_phrases: [
                'My husband',
                'his appliance',
                'about 3 years',
                'Our bedroom',
                'the first time',
                '\ud83d\ude34',
                'D. Branson - VitalSleep Customer \u2b50\u2b50\u2b50\u2b50\n\nSnoring',
                'a narrowing',
                'the airway',
                'the throat',
                'other devices',
                'VitalSleep',
                'the root cause',
                'snoring',
                'VitalSleep',
                'patented Accu-Adjust System',
                'the jaw and tongue',
                'the airway',
                'comfort',
                'a user',
                'teeth',
                'a personalized fit',
                'This combination',
                'the best way',
                'a snorer',
                'restful sleep',
                'the same method',
                'doctors and dentists',
                'their snoring patients',
                'sizes',
                'men and women',
                'VitalSleep',
                '60',
                'a 100% money-back guarantee',
                'a 1-year warranty',
                'the USA',
                'Order',
                'free, same-day shipping',
                'fast delivery',
            ],
            aws_tags: {
                QUANTITY: ['about 3 years', 'first time', '60-nights', '100%', '1-year'],
                PERSON: ['D. Branson'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                ORGANIZATION: ['VitalSleep', 'Accu'],
                LOCATION: ['USA'],
                OTHER: ['https://www.VitalSleep.com'],
            },
            adsets: [
                '23849723631090131',
                '23849734633870131',
                '23849870838800131',
                '23849870884250131',
                '23849841299170131',
                '23849734588940131',
            ],
            ads: [
                '23849723631150131',
                '23849841299120131',
                '23849870838840131',
                '23849734633740131',
                '23849870884130131',
                '23849734588930131',
            ],
            dco_ads: [],
            links: ['https://www.VitalSleep.com'],
            emojis: ['\ud83d\ude34', '\u2b50'],
            text_id: 1457369,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.2220347917764893,
                'madgicx_custom.conversion_rate_ecommerce': 5.405405405405405,
                'action_values.omni_purchase': 115.91,
                'outbound_clicks_ctr.outbound_click': 1.6754850623162847,
                spend: 94.85,
                impressions: 3402.0,
                'actions.offsite_conversion.fb_pixel_purchase': 2.0,
                'actions.landing_page_view': 37.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.quote', 'TextTypes.long_text'],
            text: "\u2b50\u2b50\u2b50\u2b50\u2b50\n\"Stopped snoring the first night. My husband was so excited to finally get a full nights sleep instead of listening to me sawing logs all night!\"\n\nBreanne - VitalSleep Customer\n\nSnoring is caused by a narrowing of the airway in the throat.\n\nLack of sleep causing tiredness, health issues, and relationship problems.\n\nUnlike other devices, VitalSleep targets the root cause of snoring.\n\nVitalSleep's patented Accu-Adjust System holds the jaw and tongue forward to open the airway. Plus, for comfort, it molds to a user's teeth for a personalized fit.\n\nThis combination is the best way for a snorer to experience restful sleep.\n\nIt's the same method that doctors and dentists use with their snoring patients. Now available in sizes for men and women.\n\nTry VitalSleep for 60-nights. It comes with a 100% money-back guarantee, a 2-year warranty.",
            language: 'en',
            aws_phrases: [
                'the first night.',
                'My husband',
                'a full nights sleep',
                'logs',
                'all night',
                'Breanne - VitalSleep Customer\n\nSnoring',
                'a narrowing',
                'the airway',
                'the throat',
                'Lack',
                'sleep',
                'tiredness',
                'health issues',
                'relationship problems',
                'other devices',
                'VitalSleep',
                'the root cause',
                'snoring',
                'VitalSleep',
                'patented Accu-Adjust System',
                'the jaw and tongue',
                'the airway',
                'comfort',
                'a user',
                'teeth',
                'a personalized fit',
                'This combination',
                'the best way',
                'a snorer',
                'restful sleep',
                'the same method',
                'doctors and dentists',
                'their snoring patients',
                'sizes',
                'men and women',
                'VitalSleep',
                '60-nights',
                'a 100% money-back guarantee',
                'a 2-year warranty',
            ],
            aws_tags: {
                QUANTITY: ['first night', '60-nights', '100%', '2-year warranty'],
                PERSON: ['Breanne'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                ORGANIZATION: ['VitalSleep'],
            },
            adsets: ['23849691511040131', '23849803058010131'],
            ads: ['23849691511240131', '23849803058000131'],
            dco_ads: [],
            links: [],
            emojis: ['\u2b50'],
            text_id: 2723905,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.872093,
                spend: 10.93,
                impressions: 344.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 1.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: '\u05d4\u05d2\u05d9\u05e2 \u05d4\u05d6\u05de\u05df \u05dc\u05e9\u05e4\u05e8 \u05d0\u05ea \u05e9\u05e0\u05ea \u05d4\u05dc\u05d9\u05dc\u05d4! \ud83d\udca4\n\n\u05d4\u05db\u05d9\u05e8\u05d5 \u05d0\u05ea VitalSleep - \u05de\u05db\u05e9\u05d9\u05e8 \u05d7\u05d3\u05e9\u05e0\u05d9 \u05e0\u05d2\u05d3 \u05e0\u05d7\u05d9\u05e8\u05d5\u05ea \u05e9\u05e2\u05d5\u05e9\u05d4 \u05e9\u05d9\u05de\u05d5\u05e9 \u05d1\u05e4\u05d8\u05e0\u05d8 \u05dc\u05e7\u05d9\u05d1\u05d5\u05e2 \u05d4\u05dc\u05e9\u05d5\u05df \u05d5\u05d4\u05dc\u05e1\u05ea \u05d5\u05e4\u05ea\u05d9\u05d7\u05ea \u05d3\u05e8\u05db\u05d9 \u05d4\u05e0\u05e9\u05d9\u05de\u05d4 - \u05dc\u05dc\u05d9\u05dc\u05d4 \u05e9\u05e7\u05d8 \u05d5\u05e9\u05d9\u05e0\u05d4 \u05d8\u05d5\u05d1\u05d4.\n\n\u2705 \u05de\u05d9\u05d5\u05e6\u05e8 \u05d1\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea -  FDA Cleared \ud83c\uddfa\ud83c\uddf8 \n\u2705 \u05de\u05e9\u05dc\u05d5\u05d7 \u05d7\u05d9\u05e0\u05dd\n\u2705 \u05d0\u05d7\u05e8\u05d9\u05d5\u05ea \u05dc\u05e9\u05e0\u05ea\u05d9\u05d9\u05dd\n\u2705 \u05d4\u05d7\u05d6\u05e8 \u05db\u05e1\u05e4\u05d9 \u05de\u05dc\u05d0 \u05dc\u05d0\u05d7\u05e8 60 \u05dc\u05d9\u05dc\u05d5\u05ea\n\u2705 \u05d9\u05d5\u05ea\u05e8 \u05de-400,000 \u05d0\u05dc\u05e3 \u05dc\u05e7\u05d5\u05d7\u05d5 \u05de\u05e8\u05d5\u05e6\u05d9\u05dd \n\n\u05e8\u05d5\u05e6\u05d9\u05dd \u05dc\u05d3\u05e2\u05ea \u05e2\u05d5\u05d3? \u05dc\u05d9\u05d7\u05e6\u05d5 \u05db\u05d0\u05df \ud83d\udc47\nwww.vitalsleep.com',
            language: 'iw',
            aws_phrases: [],
            aws_tags: [],
            adsets: ['23849655273550131'],
            ads: ['23849655273340131'],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [
                '\ud83d\udc47',
                '\ud83c\uddfa',
                '\u2705',
                '\ud83d\udca4',
                '\ud83c\uddfa\ud83c\uddf8',
                '\ud83c\uddf8',
            ],
            text_id: 2912732,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.9037062283023952,
                'madgicx_custom.conversion_rate_ecommerce': 5.88235294117647,
                'action_values.omni_purchase': 1140.17,
                'outbound_clicks_ctr.outbound_click': 0.6999093689979122,
                spend: 1261.66,
                impressions: 74724.0,
                'actions.offsite_conversion.fb_pixel_purchase': 18.0,
                'actions.landing_page_view': 306.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text'],
            text: "Discover the method that doctors and dentists use to treat snoring that is easily available to you.\n\nIf you're looking for the snoring solution that has helped over 400,000+ customers that includes a 60-night money back guarantee, and a 2 year warranty, then you\u2019ll love the VitalSleep Anti-Snoring Mouthpiece.\n\nIt reduces snoring with its patented adjustment system that opens the airway for improved breathing and quiet sleep\n\n\ud83c\uddfa\ud83c\uddf8  Made in the USA \u2605 FDA Cleared \u2605 Personalized Fit & Adjustable \u2605 60-Night Guaranteed \u2605 Free Shipping \u2605 400,000+ Happy Sleepers and counting! \n\n Don't lose another night's sleep! Get VitalSleep today!",
            language: 'en',
            aws_phrases: [
                'the method',
                'doctors and dentists',
                'snoring',
                'the snoring solution',
                '400,000+ customers',
                'a 60-night money back guarantee',
                'a 2 year warranty',
                'the VitalSleep Anti-Snoring Mouthpiece',
                'its patented adjustment system',
                'the airway',
                'improved breathing',
                'quiet sleep',
                'the USA \u2605 FDA Cleared \u2605 Personalized Fit & Adjustable \u2605 60-Night Guaranteed \u2605 Free Shipping \u2605 400,000+ Happy Sleepers',
                'counting',
                'another night',
                'sleep',
                'VitalSleep',
                'today',
            ],
            aws_tags: {
                QUANTITY: ['over 400,000+ customers', '60-night', '2 year warranty', '60-Night', '400,000+'],
                ORGANIZATION: ['VitalSleep', 'FDA'],
                LOCATION: ['USA'],
                DATE: ['today'],
            },
            adsets: [
                '23849734633890131',
                '23849870884280131',
                '23849723631080131',
                '23849841299090131',
                '23849870838890131',
            ],
            ads: [
                '23849723631140131',
                '23849734633850131',
                '23849870838770131',
                '23849841299100131',
                '23849870884210131',
            ],
            dco_ads: [],
            links: [],
            emojis: ['\ud83c\uddf8', '\ud83c\uddfa', '\ud83c\uddfa\ud83c\uddf8'],
            text_id: 3357794,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 4.046388564359837,
                'madgicx_custom.conversion_rate_ecommerce': 14.516129032258066,
                'action_values.omni_purchase': 588.79,
                'outbound_clicks_ctr.outbound_click': 0.787324,
                spend: 145.51,
                impressions: 10161.0,
                'actions.offsite_conversion.fb_pixel_purchase': 9.0,
                'actions.landing_page_view': 62.0,
            },
            tags: ['TextTypes.medium_text', 'TextTypes.link'],
            text: "If you are struggling to get restful sleep then this could be the most important Facebook post you read this year.\n\nToday we are offering a special Buy 1 Get 1 Free offer on the VitalSleep anti-snoring mouthpiece.\n\nVitalSleep works by using the same method that doctors and dentist use to treat snoring.\n\nClick here to get today's offer >> https://bit.ly/3ljQ1Xd",
            language: 'en',
            aws_phrases: [
                'restful sleep',
                'the most important Facebook post',
                'this year',
                'Today',
                'a special Buy 1',
                '1 Free offer',
                'the VitalSleep anti-snoring mouthpiece',
                'VitalSleep',
                'the same method',
                'doctors and dentist',
                'snoring',
                'today',
            ],
            aws_tags: {
                ORGANIZATION: ['Facebook'],
                DATE: ['this year', 'Today', 'today'],
                QUANTITY: ['Buy', '1', '1 Free offer'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                OTHER: ['https://bit.ly/3ljQ1Xd'],
            },
            adsets: ['23848835914040131'],
            ads: ['23848835916310131'],
            dco_ads: [],
            links: ['https://bit.ly/3ljQ1Xd'],
            emojis: [],
            text_id: 3392773,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.7968129880478088,
                spend: 5.45,
                impressions: 251.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.discount', 'TextTypes.medium_text'],
            text: "Want an easy and fast way to fight fatigue? Quit snoring! \n\nImagine what you could accomplish with a good night's sleep, night after night? \n\nThe Vital Sleep mouthpiece is the most comfortable and effective anti-snoring device you'll find. \n\nIt works on the first night.\n\nGive it a try for 60 nights - If you don't love it, we'll issue you a full refund. No questions asked. \n\nTap below to get a discount on your first purchase \ud83d\udc47",
            language: 'en',
            aws_phrases: [
                'an easy and fast way',
                'fatigue',
                'Quit',
                'a good night',
                'sleep',
                'night',
                'night',
                'The Vital Sleep mouthpiece',
                'the most comfortable and effective anti-snoring device',
                'the first night',
                'a try',
                '60 nights',
                'a full refund',
                'No questions',
                'a discount',
            ],
            aws_tags: {
                ORGANIZATION: ['Vital'],
                QUANTITY: ['first night', '60 nights', 'first purchase'],
            },
            adsets: ['23849812041060131', '23849812062420131'],
            ads: ['23849812062450131', '23849812041050131'],
            dco_ads: [],
            links: [],
            emojis: ['\ud83d\udc47'],
            text_id: 3423506,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.8034705177236146,
                'madgicx_custom.conversion_rate_ecommerce': 15.789473684210526,
                'action_values.omni_purchase': 982.2800000000001,
                'outbound_clicks_ctr.outbound_click': 0.6423084983942284,
                spend: 350.38,
                impressions: 21485.0,
                'actions.offsite_conversion.fb_pixel_purchase': 15.0,
                'actions.landing_page_view': 95.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.quote', 'TextTypes.medium_text'],
            text: 'Free Shipping To Australia \ud83c\udde6\ud83c\uddfa\n\n"I believe yours to be the best on the market, the most comfortable, and the most slimline. \n\nI have used it for years now and I have tried around 7 different styles and brands. Yours is the best."\n\nDean L. - Sydney, Australia',
            language: 'en',
            aws_phrases: [
                'Free Shipping',
                'Australia',
                'the market',
                'the most comfortable',
                'the most slimline.',
                'years',
                '7 different styles and brands',
                'the best',
                'Dean L.',
                'Sydney',
            ],
            aws_tags: {
                LOCATION: ['Australia', 'Sydney, Australia'],
                QUANTITY: ['around 7 different styles'],
                PERSON: ['Dean L.'],
            },
            adsets: [
                '23849841299180131',
                '23849812062420131',
                '23849734633840131',
                '23849870884290131',
                '23849734595390131',
                '23849870838910131',
            ],
            ads: [
                '23849870884220131',
                '23849734595380131',
                '23849870838870131',
                '23849812062430131',
                '23849734633800131',
                '23849841299130131',
            ],
            dco_ads: [],
            links: [],
            emojis: ['\ud83c\uddfa', '\ud83c\udde6', '\ud83c\udde6\ud83c\uddfa'],
            text_id: 3585496,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.41696,
                spend: 136.85,
                impressions: 18467.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 52.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "The VitalSleep anti-snoring mouthpiece is fully adjustable to gently hold your jaw forward as you sleep.\n\nThis opens your airway to help you breathe quietly and easily. Plus, it easily molds to your teeth for a comfortable and secure fit.\n\nTry one of the world's top anti-snoring devices that has helped hundreds of thousands of snorers get the restful sleep they deserve.\n\nStop waking up feeling like the Walking Dead and reclaim a good night's sleep.\n\nYou'll get a 100% money-back guarantee, a 2-year warranty, and free 2-way shipping. \n\nClick here to order today at www.vitalsleep.com and enjoy a peaceful sleep.",
            language: 'en',
            aws_phrases: [
                'The VitalSleep anti-snoring mouthpiece',
                'your jaw',
                'your airway',
                'your teeth',
                'a comfortable and secure fit',
                'the world',
                'top anti-snoring devices',
                'hundreds of thousands',
                'snorers',
                'the restful sleep',
                'the Walking Dead',
                'a good night',
                'sleep',
                'a 100% money-back guarantee',
                'a 2-year warranty',
                'free 2-way shipping',
                'today',
                'a peaceful sleep',
            ],
            aws_tags: {
                COMMERCIAL_ITEM: ['VitalSleep'],
                QUANTITY: [
                    'one',
                    'anti-snoring devices',
                    'hundreds of thousands of snorers',
                    '100%',
                    '2-year warranty',
                    '2-way',
                ],
                TITLE: ['Walking Dead'],
                DATE: ['today'],
                OTHER: ['www.vitalsleep.com'],
            },
            adsets: ['23849453578980131'],
            ads: ['23849453595200131'],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [],
            text_id: 3743526,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 64.32183908045977,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 55.96,
                'outbound_clicks_ctr.outbound_click': 2.631579,
                spend: 0.87,
                impressions: 38.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: "Start the new year with better health and rest \ud83c\udf89\n\nIf you are struggling to get restful sleep then this could be the most important Facebook post you read this year\n\nToday we are offering a special Buy 1 Get 1 Free offer on the VitalSleep anti-snoring mouthpiece.\n\nVitalSleep works by using the same method that doctors and dentists use to treat snoring.\n\nClick here to get today's offer >>  https://bit.ly/3ljQ1Xd",
            language: 'en',
            aws_phrases: [
                'the new year',
                'better health and rest',
                'restful sleep',
                'the most important Facebook post',
                'year',
                'Today',
                'special Buy 1 Get 1 Free offer',
                'the VitalSleep anti-snoring mouthpiece.',
                'VitalSleep',
                'the same method',
                'doctors and dentists',
                'snoring.',
            ],
            aws_tags: {
                ORGANIZATION: ['Facebook'],
                DATE: ['this year', 'Today', 'today'],
                QUANTITY: ['1', '1 Free'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                OTHER: ['https://bit.ly/3ljQ1Xd'],
            },
            adsets: ['23848835914040131'],
            ads: ['23848835916320131'],
            dco_ads: [],
            links: ['https://bit.ly/3ljQ1Xd'],
            emojis: ['\ud83c\udf89'],
            text_id: 3815229,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 3.293027812451762,
                spend: 32.69,
                impressions: 3887.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 19.0,
            },
            tags: ['TextTypes.quote', 'TextTypes.long_text'],
            text: '"Hello. My name is Lorne Ray and I use the VitalSleep. It has been amazing. It has improved my relationship with my wife. I kept her up the whole time we\u2019ve been together and now she can sleep! Now, she says sometimes she hears me a little bit, but it\u2019s unbelievable the difference. I feel better. It\u2019s been the most amazing device that I have ever used, that I bought and wasn\u2019t sure it was gonna work. And it actually works. So thank you VitalSleep. You\u2019ve made an incredible difference in my life and anybody out there that snores should look into this. Thank you very much." Learn more about Vitalsleep by clicking the link in our bio and get access to special offers.',
            language: 'en',
            aws_phrases: [
                'My name',
                'Lorne Ray',
                'the VitalSleep',
                'my relationship',
                'my wife',
                'the whole time',
                'a little bit',
                'the difference',
                'the most amazing device',
                'an incredible difference',
                'my life and',
                'Vitalsleep',
                'the link',
                'our bio',
                'access',
                'special offers',
            ],
            aws_tags: {
                PERSON: ['Lorne Ray'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                ORGANIZATION: ['VitalSleep', 'Vitalsleep'],
            },
            adsets: ['23849812041060131', '23849748837950131'],
            ads: ['23849748838150131', '23849812041080131'],
            dco_ads: [],
            links: [],
            emojis: [],
            text_id: 3830296,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.384083,
                spend: 7.26,
                impressions: 289.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "Want an easy and fast way to fight fatigue?\n\nQuit snoring! \n\nImagine what you could accomplish with a good night's sleep, night after night? \n\nThe VitalSleep mouthpiece is a comfortable and effective anti-snoring device that works using the same method of jaw advancement that doctors and dentists use.\n\nIt works on the first night for most users.\n\nGive it a try for 60 nights - If you don't love it, we'll issue you a full refund. \n\nPlus it's made in the USA and its FDA-cleared.\n\nWatch Joe's review about how it help improve his sleep.\n\nLearn more at www.vitalsleep.com",
            language: 'en',
            aws_phrases: [
                'an easy and fast way',
                'fatigue',
                'Quit',
                'a good night',
                'sleep',
                'night',
                'night',
                'The VitalSleep mouthpiece',
                'a comfortable and effective anti-snoring device',
                'the same method',
                'jaw advancement',
                'doctors and dentists',
                'the first night',
                'most users',
                'a try',
                '60 nights',
                'a full refund',
                'the USA',
                'its FDA-cleared',
                'Watch Joe',
                'review',
                'his sleep',
            ],
            aws_tags: {
                COMMERCIAL_ITEM: ['VitalSleep'],
                QUANTITY: ['first night', '60 nights'],
                LOCATION: ['USA'],
                ORGANIZATION: ['FDA'],
                PERSON: ['Joe'],
                OTHER: ['www.vitalsleep.com'],
            },
            adsets: ['23849691511040131'],
            ads: ['23849691511270131'],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [],
            text_id: 3842834,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.836577453365775,
                'madgicx_custom.conversion_rate_ecommerce': 8.333333333333332,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.5351412971815912,
                spend: 24.659999999999997,
                impressions: 2803.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 12.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: '\u05de\u05ea\u05e7\u05e9\u05d9\u05dd \u05dc\u05d4\u05d9\u05e8\u05d3\u05dd \u05d1\u05dc\u05d9\u05dc\u05d4 \u05d1\u05d2\u05dc\u05dc \u05d4\u05e0\u05d7\u05d9\u05e8\u05d5\u05ea \u05e9\u05dc \u05d1\u05df/\u05d1\u05ea \u05d4\u05d6\u05d5\u05d2? \ud83d\ude34\n\u05d4\u05db\u05d9\u05e8\u05d5 \u05d0\u05ea VitalSleep! \u05d4\u05ea\u05e7\u05df \u05d7\u05d3\u05e9\u05e0\u05d9 \u05dc\u05d4\u05e4\u05d7\u05ea\u05ea \u05d4\u05e0\u05d7\u05d9\u05e8\u05d5\u05ea - \u05dc\u05e9\u05d9\u05e0\u05d4 \u05e9\u05e7\u05d8\u05d4 \u05d5\u05d8\u05d5\u05d1\u05d4!\n\n\u2705 \u05de\u05d9\u05d5\u05e6\u05e8 \u05d1\u05d0\u05e8\u05e6\u05d5\u05ea \u05d4\u05d1\u05e8\u05d9\u05ea -  FDA Cleared \ud83c\uddfa\ud83c\uddf8 \n\u2705 \u05de\u05e9\u05dc\u05d5\u05d7 \u05d7\u05d9\u05e0\u05dd\n\u2705 \u05d4\u05d7\u05d6\u05e8 \u05db\u05e1\u05e4\u05d9 \u05de\u05dc\u05d0 \u05dc\u05d0\u05d7\u05e8 60 \u05dc\u05d9\u05dc\u05d5\u05ea\n\u2705 \u05d9\u05d5\u05ea\u05e8 \u05de-400,000 \u05d0\u05dc\u05e3 \u05dc\u05e7\u05d5\u05d7\u05d5\u05ea \u05de\u05e8\u05d5\u05e6\u05d9\u05dd\n\n\u05e8\u05d5\u05e6\u05d9\u05dd \u05dc\u05d3\u05e2\u05ea \u05e2\u05d5\u05d3? \u05dc\u05d9\u05d7\u05e6\u05d5 \u05db\u05d0\u05df \ud83d\udc47\nwww.vitalsleep.com',
            language: 'iw',
            aws_phrases: [],
            aws_tags: [],
            adsets: [],
            ads: [],
            dco_ads: ['23849655273410131', '23849655273400131', '23849655273400131'],
            links: ['www.vitalsleep.com'],
            emojis: [
                '\ud83d\udc47',
                '\ud83c\uddfa',
                '\ud83d\ude34',
                '\u2705',
                '\ud83c\uddfa\ud83c\uddf8',
                '\ud83c\uddf8',
            ],
            text_id: 3978778,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.6023051815585474,
                'madgicx_custom.conversion_rate_ecommerce': 6.7934782608695645,
                'action_values.omni_purchase': 1727.99,
                'outbound_clicks_ctr.outbound_click': 0.5344892580962081,
                spend: 1078.44,
                impressions: 110947.0,
                'actions.offsite_conversion.fb_pixel_purchase': 25.0,
                'actions.landing_page_view': 368.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text', 'TextTypes.link'],
            text: "Stop Snoring From Turning Restless Nights Into Restless Days\n\nIf you're looking for the snoring solution that has helped over 400,000+ customers that includes a 60-night money back guarantee, and a 1-year warranty, then you\u2019ll love the VitalSleep Anti-Snoring Mouthpiece.\n\nIt reduces snoring with its patented adjustment system that opens the airway for improved breathing and quiet sleep\n\nReclaim a good night's sleep here \u27a1 https://bit.ly/3yFKyi6\n\n\ud83c\uddfa\ud83c\uddf8  Made in the USA \n\u2605 FDA Cleared \n\u2605 Personalized Fit & Adjustable \n\u2605 60-Night Guaranteed \n\u2605 Free Shipping \n\u2605 400,000+ Happy Sleepers and counting! \n\n Don't lose another night's sleep! Get VitalSleep today!",
            language: 'en',
            aws_phrases: [
                'Stop Snoring',
                'Restless Nights',
                'Restless Days',
                'the snoring solution',
                '400,000+ customers',
                'a 60-night money back guarantee',
                'a 1-year warranty',
                'the VitalSleep Anti-Snoring Mouthpiece',
                'its patented adjustment system',
                'the airway',
                'improved breathing and quiet sleep\n\nReclaim',
                'a good night',
                'sleep',
                'USA \n\u2605 FDA Cleared \n\u2605 Personalized Fit & Adjustable \n\u2605 60-Night Guaranteed \n\u2605 Free Shipping \n\u2605',
                '400,000+ Happy Sleepers',
                'counting',
                'another night',
                'sleep',
                'VitalSleep',
                'today',
            ],
            aws_tags: {
                QUANTITY: ['over 400,000+ customers', '60-night', '1-year warranty', '60-Night', '400,000+'],
                ORGANIZATION: ['VitalSleep', 'FDA'],
                OTHER: ['https://bit.ly/3yFKyi6'],
                LOCATION: ['USA'],
                DATE: ['today'],
            },
            adsets: [
                '23849734633860131',
                '23849691511040131',
                '23849841299210131',
                '23849723631130131',
                '23849690163100131',
            ],
            ads: [
                '23849841299220131',
                '23849691511280131',
                '23849734633820131',
                '23849723631100131',
                '23849690163110131',
            ],
            dco_ads: [],
            links: ['https://bit.ly/3yFKyi6'],
            emojis: ['\ud83c\uddf8', '\ud83c\uddfa', '\ud83c\uddfa\ud83c\uddf8', '\u27a1'],
            text_id: 3993966,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.3367054290595908,
                'madgicx_custom.conversion_rate_ecommerce': 7.017543859649123,
                'action_values.omni_purchase': 3066.83,
                'outbound_clicks_ctr.outbound_click': 0.9928671285156454,
                spend: 2294.3199999999997,
                impressions: 192070.0,
                'actions.offsite_conversion.fb_pixel_purchase': 48.0,
                'actions.landing_page_view': 684.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "The VitalSleep anti-snoring mouthpiece is fully adjustable to gently hold your jaw forward as you sleep.\n\nThis opens your airway to help you breathe quietly and easily. Plus, it easily molds to your teeth for a comfortable and secure fit.\n\nTry one of the world's top anti-snoring devices that has helped hundreds of thousands of snorers get the restful sleep they deserve.\n\nStop waking up feeling like the Walking Dead and reclaim a good night's sleep.\n\nYou'll get a 100% money-back guaranteed, a 1-year warranty, and free 2-way shipping. \n\nClick here to order today at www.vitalsleep.com and enjoy peaceful sleep.",
            language: 'en',
            aws_phrases: [
                'The VitalSleep anti-snoring mouthpiece',
                'your jaw',
                'your airway',
                'your teeth',
                'a comfortable and secure fit',
                'the world',
                'top anti-snoring devices',
                'hundreds of thousands',
                'snorers',
                'the restful sleep',
                'the Walking Dead',
                'a good night',
                'sleep',
                'a 100% money-back guaranteed',
                'a 1-year warranty',
                'free 2-way shipping',
                'today',
                'peaceful sleep',
            ],
            aws_tags: {
                COMMERCIAL_ITEM: ['VitalSleep'],
                QUANTITY: [
                    'one',
                    'anti-snoring devices',
                    'hundreds of thousands of snorers',
                    '100%',
                    '1-year warranty',
                    '2-way',
                ],
                TITLE: ['Walking Dead'],
                DATE: ['today'],
                OTHER: ['www.vitalsleep.com'],
            },
            adsets: ['23849734633830131', '23849841299080131', '23849734599990131', '23849690163090131'],
            ads: ['23849734633780131', '23849734599980131', '23849690163130131', '23849841299190131'],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [],
            text_id: 3993967,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.475900782567818,
                'madgicx_custom.conversion_rate_ecommerce': 16.129032258064516,
                'action_values.omni_purchase': 895.36,
                'outbound_clicks_ctr.outbound_click': 0.713546,
                spend: 361.63,
                impressions: 17378.0,
                'actions.offsite_conversion.fb_pixel_purchase': 15.0,
                'actions.landing_page_view': 93.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text', 'TextTypes.link'],
            text: "Use Promo code SNORE20 & get an additional 20% off!\n\nStop Snoring From Turning Restless Nights Into Restless Days.\n\nIf you're looking for the snoring solution that has helped over 400,000+ customers that includes a 60-night money-back guarantee, and a 1- year warranty, then you\u2019ll love the VitalSleep Anti-Snoring Mouthpiece.\n\nIt reduces snoring with its patented adjustment system that opens the airway for improved breathing and quiet sleep.\n\nReclaim a good night's sleep here \u27a1 https://bit.ly/33RG39o\n\n\u2605 Made in the USA \n\u2605 FDA Cleared\n\u2605 Personalized Fit & Adjustable\n\u2605 60-Night Money-Back Guarantee\n\u2605 Free Shipping\n\nWake up feeling well-rested with one of the top anti-snoring devices in the world.",
            language: 'en',
            aws_phrases: [
                'Use Promo code SNORE20',
                'an additional 20%',
                'Stop Snoring',
                'Restless Nights',
                'Restless Days',
                'the snoring solution',
                '400,000+ customers',
                'a 60-night money-back guarantee',
                'a 1- year warranty',
                'the VitalSleep Anti-Snoring Mouthpiece',
                'its patented adjustment system',
                'the airway',
                'improved breathing and',
                'quiet sleep',
                'a good night',
                'sleep',
                'https://bit.ly/33RG39o\n\n\u2605 Made',
                'the USA \n\u2605 FDA Cleared\n\u2605 Personalized Fit & Adjustable\n\u2605 60-Night Money-Back Guarantee\n\u2605 Free Shipping\n\nWake',
                'the top anti-snoring devices',
                'the world',
            ],
            aws_tags: {
                OTHER: ['SNORE20', 'https://bit.ly/33RG39o'],
                QUANTITY: [
                    '20%',
                    'over 400,000+ customers',
                    '60-night',
                    '1- year warranty',
                    '60-Night',
                    'one of the top anti-snoring devices',
                ],
                COMMERCIAL_ITEM: ['VitalSleep', '-Snoring'],
                LOCATION: ['USA'],
                ORGANIZATION: ['FDA'],
            },
            adsets: ['23849691511040131'],
            ads: ['23849691511230131'],
            dco_ads: [],
            links: ['https://bit.ly/33RG39o'],
            emojis: ['\u27a1'],
            text_id: 4005068,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 2.702703,
                spend: 11.32,
                impressions: 1591.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 4.0,
            },
            tags: ['TextTypes.quote', 'TextTypes.long_text', 'TextTypes.link'],
            text: "\"It's amazing. I'm shocked at the results.\"\u2063\n\u2063\nLeon shares how with the use of VitalSleep, he was able to reduce his snoring from up to 4 hours a night to only 3 minutes. \u2063\n\u2063\nHe used a sleep app to measure the amount of snoring he had at night and he's now sleeping better than he has in many years.\u2063\n\u2063\nWatch his story and learn how VitalSleep can help improve your sleep too.\u2063\n\u2063\nLearn more at www.vitalsleep.com or click the link in our bio for a special offer.",
            language: 'en',
            aws_phrases: [
                'the results',
                '\u2063\n\u2063\nLeon',
                'the use',
                'VitalSleep',
                'his snoring',
                'up to 4 hours',
                'a night',
                'only 3 minutes',
                'sleep app',
                'amount',
                'night and',
                'years.\u2063',
                'story',
                'VitalSleep',
                'sleep too.\u2063',
                'link',
                'bio',
                'special offer.',
            ],
            aws_tags: {
                PERSON: ['Leon'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                QUANTITY: ['up', '4 hours a night', '3 minutes', 'many'],
                OTHER: ['www.vitalsleep.com'],
            },
            adsets: ['23849872860060131'],
            ads: ['23849872860110131'],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [],
            text_id: 4005087,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.201249,
                spend: 81.86,
                impressions: 24348.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 15.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.quote', 'TextTypes.long_text'],
            text: "\u2b50\u2b50\u2b50\u2b50\u2b50\n\"Stopped snoring the first night. My husband was so excited to finally get a full nights sleep instead of listening to me sawing logs all night!\"\nBreanne - VitalSleep Customer\n\nSnoring is caused by a narrowing of the airway in the throat.\nLack of sleep causing tiredness, health issues, and relationship problems.\n\nUnlike other devices, VitalSleep targets the root cause of snoring.\nVitalSleep's patented Accu-Adjust System holds the jaw and tongue forward to open the airway. Plus, for comfort, it molds to a user's teeth for a personalized fit.\n\nThis combination is the best way for a snorer to experience restful sleep.\n\nIt's the same method that doctors and dentists use with their snoring patients. Now available in sizes for men and women.\n\nTry VitalSleep for 60-nights. It comes with a 100% money-back guarantee, a 1-year warranty.",
            language: 'en',
            aws_phrases: [
                'the first night.',
                'husband',
                'full nights sleep',
                'logs',
                'all night!',
                'Breanne',
                'VitalSleep Customer\n\nSnoring',
                'a narrowing',
                'the airway',
                'the throat',
                'Lack',
                'sleep',
                'tiredness',
                'health issues',
                'relationship problems',
                'other devices',
                'VitalSleep',
                'the root cause',
                'snoring',
                'VitalSleep',
                'patented Accu-Adjust System',
                'the jaw and tongue',
                'the airway',
                'comfort',
                'a user',
                'teeth',
                'a personalized fit',
                'This combination',
                'the best way',
                'a snorer',
                'restful sleep',
                'the same method',
                'doctors and dentists',
                'their snoring patients',
                'sizes',
                'men and women',
                'VitalSleep',
                '60-nights',
                'a 100% money-back guarantee',
                'a 1-year warranty',
            ],
            aws_tags: {
                QUANTITY: ['first night', '60-nights', '100%', '1-year warranty'],
                PERSON: ['Breanne'],
                ORGANIZATION: ['VitalSleep'],
            },
            adsets: ['23849691917680131'],
            ads: ['23849691917670131'],
            dco_ads: [],
            links: [],
            emojis: ['\u2b50'],
            text_id: 4005098,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.9653955210925295,
                'madgicx_custom.conversion_rate_ecommerce': 8.771929824561404,
                'action_values.omni_purchase': 333.65999999999997,
                'outbound_clicks_ctr.outbound_click': 0.5375981432699416,
                spend: 345.61999999999995,
                impressions: 14881.0,
                'actions.offsite_conversion.fb_pixel_purchase': 5.0,
                'actions.landing_page_view': 57.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "The VitalSleep anti-snoring mouthpiece is fully adjustable to gently hold your jaw forward as you sleep.\n\nThis opens your airway to help you breathe quietly and easily. Plus, it easily molds to your teeth for a comfortable and secure fit.\n\nTry one of the world's top anti-snoring devices that has helped hundreds of thousands of snorers get the restful sleep they deserve.\n\nStop waking up feeling like the Walking Dead and reclaim a good night's sleep.\n\nYou'll get a 100% money-back guarantee, a 1-year warranty, and free 2-way shipping.\n\nClick here to order today at www.vitalsleep.com and enjoy a peaceful sleep.",
            language: 'en',
            aws_phrases: [
                'The VitalSleep anti-snoring mouthpiece',
                'your jaw',
                'your airway',
                'your teeth',
                'a comfortable and secure fit',
                'the world',
                'top anti-snoring devices',
                'hundreds of thousands',
                'snorers',
                'the restful sleep',
                'the Walking Dead',
                'a good night',
                'sleep',
                'a 100% money-back guarantee',
                'a 1-year warranty',
                'free 2-way shipping',
                'today',
                'a peaceful sleep',
            ],
            aws_tags: {
                COMMERCIAL_ITEM: ['VitalSleep'],
                QUANTITY: [
                    'one',
                    'anti-snoring devices',
                    'hundreds of thousands of snorers',
                    '100%',
                    '1-year warranty',
                    '2-way',
                ],
                TITLE: ['Walking Dead'],
                DATE: ['today'],
                OTHER: ['www.vitalsleep.com'],
            },
            adsets: [
                '23849734633770131',
                '23849870838820131',
                '23849734824970131',
                '23849841299110131',
                '23849870884240131',
            ],
            ads: [
                '23849870884190131',
                '23849870838900131',
                '23849734633730131',
                '23849841299150131',
                '23849734824960131',
            ],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [],
            text_id: 4025210,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.6760677308024159,
                'madgicx_custom.conversion_rate_ecommerce': 5.223880597014925,
                'action_values.omni_purchase': 752.22,
                'outbound_clicks_ctr.outbound_click': 0.5942074415544173,
                spend: 1112.64,
                impressions: 54863.0,
                'actions.offsite_conversion.fb_pixel_purchase': 14.0,
                'actions.landing_page_view': 268.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "Whether you snore or know someone who does, VitalSleep is here to help all reclaim a good night\u2019s sleep without snoring.\n\nThe VitalSleep Anti-Snoring Mouthpiece is designed with you in mind and it's one of the top-selling snoring devices in the world.\n\nWith personalized jaw adjustment to help open your airway and reduce snoring and easy-to-mold teeth impression trays, VitalSleep will provide quiet sleep the first night of use by targeting the cause of snoring. \n\nWe work hard to put a restful night's sleep within the reach of snorers and their loved ones every night. \n\nIt's made in the USA, it's FDA-cleared, plus it includes a 60-day money-back guarantee and a 1-year warranty.  \n\nOrder today and get free shipping at \nwww.vitalsleep.com",
            language: 'en',
            aws_phrases: [
                'VitalSleep',
                'a good night',
                '\u2019s sleep',
                'snoring',
                'The VitalSleep Anti-Snoring Mouthpiece',
                'mind',
                'the top-selling snoring devices',
                'the world',
                'personalized jaw adjustment',
                'your airway',
                'snoring and easy-to-mold teeth impression trays',
                'VitalSleep',
                'quiet sleep',
                'the first night',
                'the cause',
                'snoring',
                'a restful night',
                'sleep',
                'the reach',
                'snorers',
                'their loved ones',
                'every night',
                'the USA',
                'a 60-day money-back guarantee',
                'a 1-year warranty',
                'today',
                'free shipping',
            ],
            aws_tags: {
                ORGANIZATION: ['VitalSleep', 'FDA'],
                QUANTITY: ['one of the top-selling snoring devices', 'first night', '60-day', '1-year warranty'],
                LOCATION: ['USA'],
                DATE: ['today'],
                OTHER: ['www.vitalsleep.com'],
            },
            adsets: [
                '23849804482080131',
                '23849870838920131',
                '23849804505690131',
                '23849841299240131',
                '23849691511040131',
                '23849734605220131',
                '23849870884160131',
            ],
            ads: [
                '23849870884230131',
                '23849804482070131',
                '23849804505680131',
                '23849734605210131',
                '23849841299140131',
                '23849804512950131',
                '23849870838780131',
            ],
            dco_ads: [],
            links: ['www.vitalsleep.com'],
            emojis: [],
            text_id: 4067675,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.341297,
                spend: 11.2,
                impressions: 586.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "This month, we're giving away a free VitalSleep to 3 lucky winners. \n\nThis is your chance to try one of the top snoring devices in the world - on us.\n\nEntering is easy! Just leave a comment on this post and tell us 1 health-related goal that you'd like to achieve in 2022.\n\nWe're giving away 3 free VitalSleep anti-snoring mouthpieces. It is the personalized snoring solution that has been helping snorers get quiet sleep for 10 years.\n\nIt only takes seconds to enter this giveaway by posting 1 health-related goal you have for 2022.\n\nFor more chances to win, visit www.vitalsleep.com/pages/contest",
            language: 'en',
            aws_phrases: [
                'This month',
                'a free VitalSleep',
                '3 lucky winners',
                'your chance',
                'the top snoring devices',
                'the world - on',
                'Entering',
                'a comment',
                'this post',
                '1 health-related goal',
                '2022',
                '3 free VitalSleep anti-snoring mouthpieces',
                'the personalized snoring solution',
                'snorers',
                'quiet sleep',
                '10 years',
                'seconds',
                'this giveaway',
                '1 health-related goal',
                '2022',
                'more chances',
            ],
            aws_tags: {
                DATE: ['This month', '2022'],
                QUANTITY: [
                    '3 lucky winners',
                    'one of',
                    'top snoring devices',
                    '1 health',
                    '3 free VitalSleep',
                    '10 years',
                    'seconds',
                ],
                OTHER: ['www.vitalsleep.com/pages/contest'],
            },
            adsets: ['23849830715840131'],
            ads: ['23849830715910131'],
            dco_ads: [],
            links: ['www.vitalsleep.com/pages/contest'],
            emojis: [],
            text_id: 4095809,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.810356811862836,
                'madgicx_custom.conversion_rate_ecommerce': 20.0,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 86.32,
                impressions: 1513.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 5.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: 'With Free Same-Day Shipping, a 60-Night Guarantee, and 10% off. Now is the time to get your VitalSleep Anti-Snore Guard for Quiet Restful Sleep. \nUse the code SAVE10 for 10% off. \nShop Now \ud83d\udc47\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: [
                'Free Same-Day Shipping',
                'a 60-Night Guarantee',
                '10%',
                'the time',
                'your VitalSleep Anti-Snore Guard',
                'Quiet Restful Sleep',
                'the code SAVE10',
                '10%',
                'Shop',
            ],
            aws_tags: {
                QUANTITY: ['60-Night', '10%'],
                COMMERCIAL_ITEM: ['VitalSleep Anti-Snore Guard'],
                OTHER: ['SAVE10', 'https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\ud83d\udc47'],
            text_id: 4095812,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 3.4969099716051444,
                'madgicx_custom.conversion_rate_ecommerce': 33.33333333333333,
                'action_values.omni_purchase': 209.36,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 59.870000000000005,
                impressions: 915.0,
                'actions.offsite_conversion.fb_pixel_purchase': 3.0,
                'actions.landing_page_view': 9.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: '60-Night Guaranteed \u2605 Free Shipping \ud83d\udce6  400,000+ Happy Sleepers \ud83d\ude4c  \nUse SAVE10 for 10% off! Shop Now \u2b07\ufe0f\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: [
                '60-Night Guaranteed \u2605 Free Shipping \ud83d\udce6  400,000+ Happy Sleepers \ud83d\ude4c  \nUse SAVE10 for',
                'Shop',
            ],
            aws_tags: {
                QUANTITY: ['60-Night', '400,000+ Happy', '10%'],
                OTHER: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\u2b07', '\ud83d\udce6', '\ud83d\ude4c'],
            text_id: 4095813,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 3.351373942116811,
                'madgicx_custom.conversion_rate_ecommerce': 71.42857142857143,
                'action_values.omni_purchase': 320.76,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 95.71000000000001,
                impressions: 2720.0,
                'actions.offsite_conversion.fb_pixel_purchase': 5.0,
                'actions.landing_page_view': 7.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: 'Hey Did You Forget About Us?\nUse the code SAVE10 for 10% off Your First Purchase\nShop Now \ud83d\udc47\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: ['the code SAVE10', '10%', 'Your First Purchase\nShop'],
            aws_tags: {
                OTHER: ['SAVE10', 'https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
                QUANTITY: ['10%', 'First Purchase\nShop'],
            },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\ud83d\udc47'],
            text_id: 4095814,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 4.390000000000001,
                impressions: 79.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['TextTypes.short_text'],
            text: 'Use SAVE10 For 10% Off',
            language: 'en',
            aws_phrases: ['SAVE10', '10%'],
            aws_tags: { COMMERCIAL_ITEM: ['SAVE10'], QUANTITY: ['10%'] },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: [],
            emojis: [],
            text_id: 4095815,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 3.25,
                impressions: 78.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['TextTypes.short_text'],
            text: 'Sleep Quieter and Wake Up More Energized - Now 10% off',
            language: 'en',
            aws_phrases: ['Sleep Quieter', 'Wake', 'More Energized', '10%'],
            aws_tags: { QUANTITY: ['10%'] },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: [],
            emojis: [],
            text_id: 4095817,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 5.75,
                impressions: 104.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['TextTypes.short_text'],
            text: 'Noticeable Change Your First Night or Your Money Back',
            language: 'en',
            aws_phrases: ['Noticeable Change', 'Your First Night'],
            aws_tags: { QUANTITY: ['First Night'] },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: [],
            emojis: [],
            text_id: 4095818,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 15.639455782312924,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 91.96,
                'outbound_clicks_ctr.outbound_click': 0.0,
                spend: 5.88,
                impressions: 129.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['TextTypes.short_text'],
            text: 'End Snoring and Wake Up Refreshed - Now 10% Off',
            language: 'en',
            aws_phrases: ['End Snoring and Wake', 'Refreshed', '10%'],
            aws_tags: { QUANTITY: ['10%'] },
            adsets: [],
            ads: [],
            dco_ads: ['23849831347930131', '23849832988680131', '23849832988680131'],
            links: [],
            emojis: [],
            text_id: 4095819,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 2.9811039283938343,
                'madgicx_custom.conversion_rate_ecommerce': 100.0,
                'action_values.omni_purchase': 59.95,
                'outbound_clicks_ctr.outbound_click': 1.694915,
                spend: 20.11,
                impressions: 177.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 1.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: '\u2705 Free Same-Day Shipping\n\u2705 60-Night *No Questions Asked* Guarantee\n\u2705 500,000+ Satisfied Customers\n\nNow is the time to get your VitalSleep Anti-Snore Guard for Quiet Restful Sleep. \nUse the code SAVE10 for $10 off. \nShop Now \ud83d\udc47\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: [
                '\u2705 Free Same-Day Shipping\n\u2705 60-Night',
                'No Questions',
                'Guarantee\n\u2705 500,000+ Satisfied Customers',
                'the time',
                'your VitalSleep Anti-Snore Guard',
                'Quiet Restful Sleep',
                'the code SAVE10',
                '$10',
                'Shop',
            ],
            aws_tags: {
                QUANTITY: ['60-Night', '500,000+ Satisfied Customers', '$10'],
                COMMERCIAL_ITEM: ['VitalSleep', 'Anti-Snore Guard'],
                OTHER: ['SAVE10', 'https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            },
            adsets: ['23849733666750131'],
            ads: ['23849733666740131'],
            dco_ads: [],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\u2705', '\ud83d\udc47'],
            text_id: 4111782,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.238930216082182,
                'madgicx_custom.conversion_rate_ecommerce': 7.142857142857143,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 1.3470679722662444,
                spend: 56.46,
                impressions: 1262.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 14.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: "For a limited time, we are offering a ridiculous bargain! \n\nTry Vital Sleep for free for the next 30 days If you don't love it, send it back. We'll even cover return postage.\n\nThis offer is only available to the next 50 customers. Don't miss out!\n\nShop Now \ud83d\udc47\nhttps://www.tryvitalsleep.com/sign-up-offer",
            language: 'en',
            aws_phrases: [
                'a limited time',
                'a ridiculous bargain',
                'Vital Sleep',
                'the next 30 days',
                'return postage',
                'This offer',
                'the next 50 customers',
                'Shop',
            ],
            aws_tags: {
                ORGANIZATION: ['Try Vital Sleep'],
                QUANTITY: ['30 days', '50 customers'],
                OTHER: ['https://www.tryvitalsleep.com/sign-up-offer'],
            },
            adsets: ['23849733755820131', '23849733762250131'],
            ads: ['23849733762240131', '23849733755830131'],
            dco_ads: [],
            links: ['https://www.tryvitalsleep.com/sign-up-offer'],
            emojis: ['\ud83d\udc47'],
            text_id: 4111783,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 1.1647886715883091,
                'madgicx_custom.conversion_rate_ecommerce': 9.30232558139535,
                'action_values.omni_purchase': 269.8,
                'outbound_clicks_ctr.outbound_click': 1.093308,
                spend: 231.63,
                impressions: 5305.0,
                'actions.offsite_conversion.fb_pixel_purchase': 4.0,
                'actions.landing_page_view': 43.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: 'With Free Same-Day Shipping and a 60-Night Guarantee, Vital Sleep is Committed To Getting You better Quality Sleep OR YOUR MONEY BACK\n\nGet yours for 15% off today only using the code VITAL15\n\nShop Now \ud83d\udc47\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: [
                'Free Same-Day Shipping',
                'a 60-Night Guarantee',
                'Vital Sleep',
                'Quality Sleep OR YOUR MONEY BACK',
                '15%',
                'today',
                'the code',
                'VITAL15\n\nShop',
            ],
            aws_tags: {
                QUANTITY: ['60-Night', '15%'],
                ORGANIZATION: ['Vital Sleep'],
                DATE: ['today'],
                OTHER: ['VITAL15', 'https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            },
            adsets: ['23849733739760131'],
            ads: ['23849733739770131'],
            dco_ads: [],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\ud83d\udc47'],
            text_id: 4111787,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 4.2263226649248855,
                'madgicx_custom.conversion_rate_ecommerce': 100.0,
                'action_values.omni_purchase': 129.41,
                'outbound_clicks_ctr.outbound_click': 0.674157,
                spend: 30.62,
                impressions: 445.0,
                'actions.offsite_conversion.fb_pixel_purchase': 2.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.short_text'],
            text: 'Noticeable Change Your First Night or Your Money Back! Use VITAL15 for 15% off. \nShop Now \ud83d\udc47',
            language: 'en',
            aws_phrases: ['Noticeable Change', 'Your First Night', 'Your Money', 'VITAL15', '15%'],
            aws_tags: {
                QUANTITY: ['First Night', '15%'],
                ORGANIZATION: ['VITAL15'],
            },
            adsets: ['23849733666760131'],
            ads: ['23849733666770131'],
            dco_ads: [],
            links: [],
            emojis: ['\ud83d\udc47'],
            text_id: 4111790,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.384615,
                spend: 21.51,
                impressions: 520.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['TextTypes.medium_text', 'TextTypes.link'],
            text: "Unlike other devices, VitalSleep targets the root cause of snoring.\n\nTry VitalSleep for 60-nights. It comes with a 100% money-back guarantee, a 1-year warranty and it's made in the USA.\n\nUse the code SAVE15 for an additional 15% off your first purchase.\n\nOrder at https://www.VitalSleep.com for free, same-day shipping and fast delivery.\n\nShop Now",
            language: 'en',
            aws_phrases: [
                'other devices',
                'VitalSleep',
                'the root cause',
                'snoring',
                'VitalSleep',
                '60-',
                'a 100% money-back guarantee',
                'a 1-year warranty',
                'the USA',
                'the code SAVE15',
                'an additional 15%',
                'your first purchase',
                'Order',
                'free, same-day shipping',
                'fast delivery',
            ],
            aws_tags: {
                ORGANIZATION: ['VitalSleep'],
                COMMERCIAL_ITEM: ['VitalSleep'],
                QUANTITY: ['60-nights', '100%', '1-year warranty', '15%', 'first purchase'],
                LOCATION: ['USA'],
                OTHER: ['SAVE15', 'https://www.VitalSleep.com'],
            },
            adsets: ['23849733666730131'],
            ads: ['23849733666720131'],
            dco_ads: [],
            links: ['https://www.VitalSleep.com'],
            emojis: [],
            text_id: 4111791,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 2.768166,
                spend: 25.35,
                impressions: 289.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 5.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.short_text'],
            text: 'Sleep Quieter and Wake Up More Energized\ud83d\udd0b\nUse SAVE10 for $10 off your first purchase \nShop Now \ud83d\udc47',
            language: 'en',
            aws_phrases: [
                'Sleep Quieter and Wake',
                'More Energized',
                'Use SAVE10',
                '$10',
                'your first purchase \nShop',
            ],
            aws_tags: {
                COMMERCIAL_ITEM: ['SAVE10'],
                QUANTITY: ['$10', 'first purchase'],
            },
            adsets: ['23849733728520131'],
            ads: ['23849733728510131'],
            dco_ads: [],
            links: [],
            emojis: ['\ud83d\udd0b', '\ud83d\udc47'],
            text_id: 4111793,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.3002145922746781,
                'madgicx_custom.conversion_rate_ecommerce': 1.5384615384615385,
                'action_values.omni_purchase': 69.95,
                'outbound_clicks_ctr.outbound_click': 0.3226763427787256,
                spend: 233.0,
                impressions: 26962.0,
                'actions.offsite_conversion.fb_pixel_purchase': 1.0,
                'actions.landing_page_view': 65.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text', 'TextTypes.link'],
            text: 'Snoring means you are not getting enough air while you sleep.\n\nThis happens because as your muscles relax, your jaw moves back, covering the airways which causes snoring.\n\nSnoring may not only disturb restful sleep for others. It can reduce energy levels the next day and affect the quality of work that you do.\n\nThe Vital Sleep Anti-Snore mouthguard is the custom snoring solution that molds to your teeth for the perfect fit!\n\nWith our patented and FDA-approved accu-adjust system, it is the only device that lets you choose how close or how far you want your jaw to be.\n\nBecause snoring is caused when your jaw relaxes and moves close to your airway.\n\nJoin over 400,000 satisfied customers and try Vital Sleep today!\n\nIt works the first night to provide quiet sleep.\n\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece\n\nBacked by our 60-Night *No Questions Asked* Guarantee\n\nIt works or your money back!\n\nReclaim a Good Night\u2019s Sleep\n\nShop Now \ud83d\udc47\n\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: [
                'Snoring',
                'enough air',
                'your muscles',
                'your jaw',
                'the airways',
                'snoring',
                'Snoring',
                'restful sleep',
                'energy levels',
                'the next day',
                'the quality',
                'work',
                'The Vital Sleep Anti-Snore mouthguard',
                'the custom snoring solution',
                'your teeth',
                'the perfect fit',
                'our patented and FDA-approved accu-adjust system',
                'the only device',
                'your jaw',
                'snoring',
                'your jaw',
                'your airway',
                '400,000 satisfied customers',
                'Vital Sleep',
                'today',
                'the first night',
                'quiet sleep',
                'our 60-Night',
                'No Questions',
                'Guarantee',
                'your money',
                'a Good Night',
                'Sleep\n\nShop',
            ],
            aws_tags: {
                DATE: ['next day', 'today'],
                ORGANIZATION: ['Vital Sleep', 'FDA'],
                QUANTITY: ['over 400,000 satisfied customers', 'first night', '60-Night'],
                OTHER: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            },
            adsets: [
                '23849893546270131',
                '23849893546210131',
                '23849893546230131',
                '23849893546290131',
                '23849893546310131',
                '23849893546320131',
                '23849893546220131',
                '23849893546260131',
                '23849893546240131',
                '23849893546280131',
                '23849893546250131',
                '23849886442040131',
            ],
            ads: [
                '23849893827330131',
                '23849893841960131',
                '23849893822820131',
                '23849893824680131',
                '23849893823930131',
                '23849893848700131',
                '23849893823520131',
                '23849893825210131',
                '23849893824070131',
                '23849893834250131',
                '23849893829080131',
                '23849886505680131',
                '23849893826530131',
            ],
            dco_ads: ['23849886442300131', '23849886442300131'],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\ud83d\udc47'],
            text_id: 4123326,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.49119911543184613,
                spend: 29.71,
                impressions: 2443.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 8.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text', 'TextTypes.link'],
            text: 'Snoring means you are not getting enough air while you sleep.\n\nThis happens because as your muscles relax, your jaw moves back, covering the airways which causes snoring.\n\nSnoring may not only disturb restful sleep for others. It can reduce energy levels the next day and affect the quality of work that you do.\n\nThe Vital Sleep Anti-Snore mouthguard is the custom snoring solution that molds to your teeth for the perfect fit!\n\nWith our patented and FDA-approved accu-adjust system, it is the only device that lets you choose how close or how far you want your jaw to be.\n\nBecause snoring is caused when your jaw relaxes and moves close to your airway.\n\nJoin over 400,000 satisfied customers and try Vital Sleep today!\n\nIt works the first night to provide quiet sleep.\n\nhttps://www.vitalsleep.com/\n\nBacked by our 60-Night *No Questions Asked* Guarantee\n\nIt works or your money back!\n\nReclaim a Good Night\u2019s Sleep\n\nShop Now \ud83d\udc47\n\nhttps://www.vitalsleep.com/',
            language: 'en',
            aws_phrases: [
                'Snoring',
                'enough air',
                'your muscles',
                'your jaw',
                'the airways',
                'snoring',
                'Snoring',
                'restful sleep',
                'energy levels',
                'the next day',
                'the quality',
                'work',
                'The Vital Sleep Anti-Snore mouthguard',
                'the custom snoring solution',
                'your teeth',
                'the perfect fit',
                'our patented and FDA-approved accu-adjust system',
                'the only device',
                'your jaw',
                'snoring',
                'your jaw',
                'your airway',
                '400,000 satisfied customers',
                'Vital Sleep',
                'today',
                'the first night',
                'quiet sleep',
                'https://www.vitalsleep.com/\n\nBacked',
                'our 60-Night *',
                'No Questions',
                'your money',
                'a Good Night',
                '\u2019s Sleep\n\nShop',
            ],
            aws_tags: {
                DATE: ['next day', 'today'],
                ORGANIZATION: ['Vital Sleep', 'FDA'],
                QUANTITY: ['over 400,000 satisfied customers', 'first night', '60-Night'],
                OTHER: ['https://www.vitalsleep.com/'],
            },
            adsets: ['23849886610790131'],
            ads: ['23849893849830131'],
            dco_ads: ['23849886610800131', '23849886610800131'],
            links: ['https://www.vitalsleep.com'],
            emojis: ['\ud83d\udc47'],
            text_id: 4128331,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.19926323047964561,
                'madgicx_custom.conversion_rate_ecommerce': 1.510574018126888,
                'action_values.omni_purchase': 320.76,
                'outbound_clicks_ctr.outbound_click': 0.32505997592672353,
                spend: 1609.7300000000002,
                impressions: 148588.0,
                'actions.offsite_conversion.fb_pixel_purchase': 5.0,
                'actions.landing_page_view': 331.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text', 'TextTypes.link'],
            text: 'Snoring means you are not getting enough air while you sleep.\n\nThis happens because as your muscles relax, your jaw moves back, covering the airways which causes snoring.\n\nSnoring may not only disturb restful sleep for others. It can reduce energy levels the next day and affect the quality of work that you do.\n\nThe Vital Sleep Anti-Snore mouthguard is the custom snoring solution that molds to your teeth for the perfect fit!\n\nWith our patented and FDA-approved accu-adjust system, it is the only device that lets you choose how close or how far you want your jaw to be.\n\nBecause snoring is caused when your jaw relaxes and moves close to your airway.\n\nJoin over 400,000 satisfied customers and try Vital Sleep today!\n\nIt works the first night to provide quiet sleep.\n\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece\n\nBacked by our 60-Night *No Questions Asked* Guarantee\n\nIt works or your money back!\n\nReclaim a Good Night\u2019s Sleep with Vital Sleep\n\nShop Now \ud83d\udc47\nhttps://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece',
            language: 'en',
            aws_phrases: [
                'Snoring',
                'enough air',
                'your muscles',
                'your jaw',
                'the airways',
                'snoring',
                'Snoring',
                'restful sleep',
                'energy levels',
                'the next day',
                'the quality',
                'work',
                'The Vital Sleep Anti-Snore mouthguard',
                'the custom snoring solution',
                'your teeth',
                'the perfect fit',
                'our patented and FDA-approved accu-adjust system',
                'the only device',
                'your jaw',
                'snoring',
                'your jaw',
                'your airway',
                '400,000 satisfied customers',
                'Vital Sleep',
                'today',
                'the first night',
                'quiet sleep',
                'our 60-Night',
                'No Questions',
                'your money',
                'a Good Night',
                '\u2019s Sleep',
                'Vital Sleep\n\nShop',
            ],
            aws_tags: {
                DATE: ['next day', 'today'],
                ORGANIZATION: ['Vital Sleep', 'FDA', 'Vital Sleep\n\nShop'],
                QUANTITY: ['over 400,000 satisfied customers', 'first night', '60-Night'],
                OTHER: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            },
            adsets: [
                '23849893546270131',
                '23849893546210131',
                '23849893546230131',
                '23849893546290131',
                '23849893546310131',
                '23849893546320131',
                '23849893546220131',
                '23849893546260131',
                '23849893546240131',
                '23849893546280131',
                '23849893546250131',
            ],
            ads: [
                '23849893860790131',
                '23849893873860131',
                '23849893862760131',
                '23849893861960131',
                '23849893863630131',
                '23849893867630131',
                '23849893866300131',
                '23849893862290131',
                '23849893864650131',
                '23849893866750131',
                '23849893869000131',
            ],
            dco_ads: [],
            links: ['https://www.vitalsleep.com/products/vitalsleep-snoring-mouthpiece'],
            emojis: ['\ud83d\udc47'],
            text_id: 4140567,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 0.513699,
                spend: 23.18,
                impressions: 584.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 2.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.medium_text', 'TextTypes.link'],
            text: 'Experience the difference a quiet nights sleep can have on your health, happiness and relationships.\n\nTry the Vital Sleep Patented Anti- Snore Mouthguard for 30 days risk-free!\n\nThis offer is only available to the next 50 customers. \n\nShop Now \ud83d\udc47\nhttps://www.tryvitalsleep.com/sign-up-offer',
            language: 'en',
            aws_phrases: [
                'the difference',
                'a quiet nights sleep',
                'your health, happiness and relationships',
                'the Vital Sleep Patented Anti- Snore Mouthguard',
                '30 days',
                'This offer',
                'the next 50 customers',
                'Shop',
            ],
            aws_tags: {
                COMMERCIAL_ITEM: ['Vital', 'Sleep Patented Anti- Snore Mouthguard'],
                QUANTITY: ['30 days', '50 customers'],
                OTHER: ['https://www.tryvitalsleep.com/sign-up-offer'],
            },
            adsets: [],
            ads: [],
            dco_ads: ['23849733758200131'],
            links: ['https://www.tryvitalsleep.com/sign-up-offer'],
            emojis: ['\ud83d\udc47'],
            text_id: 4140570,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.035078,
                spend: 29.22,
                impressions: 1739.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 9.0,
            },
            tags: ['TextTypes.emoji', 'TextTypes.long_text', 'TextTypes.link'],
            text: 'Snoring means you are not getting enough air while you sleep.\n\nThis happens because as your muscles relax, your jaw moves back, covering the airways which causes snoring.\n\nSnoring may not only disturb restful sleep for others. It can reduce energy levels the next day and affect the quality of work that you do.\n\nThe Vital Sleep Anti-Snore mouthguard is the custom snoring solution that molds to your teeth for the perfect fit!\n\nWith our patented and FDA-approved accu-adjust system, it is the only device that lets you choose how close or how far you want your jaw to be.\n\nBecause snoring is caused when your jaw relaxes and moves close to your airway.\n\nJoin over 400,000 satisfied customers and try Vital Sleep today!\n\nIt works the first night to provide quiet sleep.\n\nhttps://www.vitalsleep.com/\n\nBacked by our 60-Night *No Questions Asked* Guarantee\n\nIt works or your money back!\n\nReclaim a Good Night\u2019s Sleep\n\nShop Now \ud83d\udc47\nhttps://www.vitalsleep.com/',
            language: 'en',
            aws_phrases: [
                'Snoring',
                'enough air',
                'your muscles',
                'your jaw',
                'the airways',
                'snoring',
                'Snoring',
                'restful sleep',
                'energy levels',
                'the next day',
                'the quality',
                'work',
                'The Vital Sleep Anti-Snore mouthguard',
                'the custom snoring solution',
                'your teeth',
                'the perfect fit',
                'our patented and FDA-approved accu-adjust system',
                'the only device',
                'your jaw',
                'snoring',
                'your jaw',
                'your airway',
                '400,000 satisfied customers',
                'Vital Sleep',
                'today',
                'the first night',
                'quiet sleep',
                'https://www.vitalsleep.com/\n\nBacked',
                'our 60-Night *',
                'No Questions',
                'your money',
                'a Good Night',
                '\u2019s Sleep\n\nShop',
            ],
            aws_tags: {
                DATE: ['next day', 'today'],
                ORGANIZATION: ['Vital Sleep', 'FDA'],
                QUANTITY: ['over 400,000 satisfied customers', 'first night', '60-Night'],
                OTHER: ['https://www.vitalsleep.com/'],
            },
            adsets: ['23849886610790131'],
            ads: ['23849886610780131'],
            dco_ads: [],
            links: ['https://www.vitalsleep.com'],
            emojis: ['\ud83d\udc47'],
            text_id: 4140576,
        },
        {
            insights: {
                'purchase_roas.omni_purchase': 0.0,
                'madgicx_custom.conversion_rate_ecommerce': 0.0,
                'action_values.omni_purchase': 0.0,
                'outbound_clicks_ctr.outbound_click': 1.666667,
                spend: 2.98,
                impressions: 180.0,
                'actions.offsite_conversion.fb_pixel_purchase': 0.0,
                'actions.landing_page_view': 0.0,
            },
            tags: ['TextTypes.long_text', 'TextTypes.link'],
            text: "{FREE VITALSLEEP]  This month, we're giving away a free VitalSleep to 3 lucky winners. \u2063\n\u2063\nThis is your chance to try one of the top snoring devices in the world - on us.\u2063\n\u2063\nEntering is easy! Just leave a comment on this post and tell us what would you like to do if you had more energy during the day.\u2063\n\u2063\nWe're giving away 3 free VitalSleep anti-snoring mouthpieces. It is the personalized snoring solution that has been helping snorers get quiet sleep for 10 years.\u2063\n\u2063\nIt only takes seconds to enter this giveaway. \u2063\n\u2063\nFor more chances to win, visit https://www.vitalsleep.com/pages/contest\u2063\n\u2063\nLeave a comment on this post to increase your winning chances.",
            language: 'en',
            aws_phrases: [
                '{FREE VITALSLEEP]',
                'This month',
                'a free VitalSleep',
                '3 lucky winners',
                'your chance',
                'the top snoring devices',
                'the world - on us.\u2063\n\u2063\nEntering',
                'comment',
                'this post',
                'more energy',
                'day.\u2063\n\u2063',
                '3 free VitalSleep anti-snoring mouthpieces.',
                'personalized snoring solution',
                'snorers',
                'quiet sleep',
                'giveaway.',
                'chances',
                '\u2063\n\u2063',
                'comment',
                'post',
                'winning chances.',
            ],
            aws_tags: {
                DATE: ['This month'],
                QUANTITY: [
                    '3 lucky winners',
                    'one of the top snoring devices',
                    '3 free VitalSleep',
                    '-snoring mouthpieces',
                    '10 years.\u2063',
                    'seconds',
                ],
                OTHER: ['https://www.vitalsleep.com/pages/contest'],
            },
            adsets: ['23849892848480131'],
            ads: ['23849892848500131'],
            dco_ads: [],
            links: ['https://www.vitalsleep.com/pages/contest\u2063'],
            emojis: [],
            text_id: 4140577,
        },
    ],
    adsets: [
        '23849863053510131',
        '23849863023140131',
        '23849723631080131',
        '23849733666750131',
        '23849862952740131',
        '23849862993810131',
        '23849893546280131',
        '23849870884250131',
        '23849870838910131',
        '23849870838820131',
        '23849870838920131',
        '23849655273480131',
        '23849863034050131',
        '23849862975670131',
        '23849872860060131',
        '23849841299110131',
        '23849863023180131',
        '23849734599990131',
        '23849803054850131',
        '23849723631130131',
        '23849748837950131',
        '23849893546230131',
        '23849893546290131',
        '23849863074690131',
        '23849862993770131',
        '23849863090520131',
        '23849886442040131',
        '23849862982920131',
        '23849655273550131',
        '23849841299230131',
        '23849733739760131',
        '23849804505690131',
        '23849862952710131',
        '23849733762250131',
        '23849862975790131',
        '23849841299090131',
        '23849862975740131',
        '23849863034030131',
        '23849863034090131',
        '23849870884160131',
        '23849893546270131',
        '23849893546210131',
        '23849803058010131',
        '23849893546320131',
        '23849733728520131',
        '23849734824970131',
        '23849862952700131',
        '23849863090510131',
        '23849733758210131',
        '23849862975700131',
        '23849734633860131',
        '23849893546260131',
        '23849690163090131',
        '23849733666760131',
        '23849870838890131',
        '23849734633890131',
        '23849812062420131',
        '23849870884270131',
        '23849886610790131',
        '23849832988690131',
        '23849870838880131',
        '23849841299240131',
        '23849691511040131',
        '23849830715840131',
        '23849734633880131',
        '23849862968420131',
        '23849723631090131',
        '23849863023120131',
        '23849863074590131',
        '23849863053570131',
        '23849870838800131',
        '23849862983010131',
        '23849863090460131',
        '23849893546250131',
        '23849723631110131',
        '23849863053550131',
        '23849691921870131',
        '23849862968470131',
        '23849870884240131',
        '23849862982960131',
        '23849862993840131',
        '23849734588940131',
        '23849734595390131',
        '23849862968540131',
        '23849862968450131',
        '23849893546310131',
        '23849863074650131',
        '23849734605220131',
        '23849733666730131',
        '23848835914040131',
        '23849804482080131',
        '23849733755820131',
        '23849863023170131',
        '23849893546220131',
        '23849893546240131',
        '23849870884290131',
        '23849863034130131',
        '23849831347830131',
        '23849863090420131',
        '23849841299180131',
        '23849862993830131',
        '23849734633770131',
        '23849870884280131',
        '23849691917680131',
        '23849812041060131',
        '23849892848480131',
        '23849734633840131',
        '23849453578980131',
        '23849841299080131',
        '23849841299170131',
        '23849863053620131',
        '23849862983000131',
        '23849690163100131',
        '23849734633870131',
        '23849863074610131',
        '23849862648690131',
        '23849734633830131',
        '23849841299210131',
    ],
    insights: [
        {
            spend: '145.51',
            adset_id: '23848835914040131',
            impressions: '10161',
            ad_id: '23848835916310131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 14.516129032258066,
            'action_values.omni_purchase': 588.79,
            'outbound_clicks_ctr.outbound_click': '0.787324',
            'actions.landing_page_view': '62',
            'actions.omni_purchase': 9.0,
            'actions.offsite_conversion.fb_pixel_purchase': '9',
            'purchase_roas.omni_purchase': 4.046389,
        },
        {
            spend: '0.87',
            adset_id: '23848835914040131',
            impressions: '38',
            ad_id: '23848835916320131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 55.96,
            'outbound_clicks_ctr.outbound_click': '2.631579',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 64.321839,
        },
        {
            spend: '136.85',
            adset_id: '23849453578980131',
            impressions: '18467',
            ad_id: '23849453595200131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.41696',
            'actions.landing_page_view': '52',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '22.33',
            adset_id: '23849655273480131',
            impressions: '2736',
            ad_id: '23849655273410131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 8.333333333333332,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.548246',
            'actions.landing_page_view': '12',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 3.132557,
        },
        {
            spend: '10.93',
            adset_id: '23849655273550131',
            impressions: '344',
            ad_id: '23849655273340131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.872093',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.33',
            adset_id: '23849655273550131',
            impressions: '67',
            ad_id: '23849655273400131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '82.11',
            adset_id: '23849690163090131',
            impressions: '30523',
            ad_id: '23849690163130131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.524195',
            'actions.landing_page_view': '43',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '106.14',
            adset_id: '23849690163100131',
            impressions: '51366',
            ad_id: '23849690163110131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.288128',
            'actions.landing_page_view': '28',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '361.63',
            adset_id: '23849691511040131',
            impressions: '17378',
            ad_id: '23849691511230131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 16.129032258064516,
            'action_values.omni_purchase': 895.36,
            'outbound_clicks_ctr.outbound_click': '0.713546',
            'actions.landing_page_view': '93',
            'actions.omni_purchase': 15.0,
            'actions.offsite_conversion.fb_pixel_purchase': '15',
            'purchase_roas.omni_purchase': 2.475901,
        },
        {
            spend: '73.39',
            adset_id: '23849691511040131',
            impressions: '2332',
            ad_id: '23849691511240131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 3.0303030303030303,
            'action_values.omni_purchase': 55.96,
            'outbound_clicks_ctr.outbound_click': '1.972556',
            'actions.landing_page_view': '33',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.762502,
        },
        {
            spend: '53.74',
            adset_id: '23849691511040131',
            impressions: '2002',
            ad_id: '23849691511260131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.899101',
            'actions.landing_page_view': '10',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '7.26',
            adset_id: '23849691511040131',
            impressions: '289',
            ad_id: '23849691511270131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.384083',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '419.2',
            adset_id: '23849691511040131',
            impressions: '15992',
            ad_id: '23849691511280131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 8.264462809917356,
            'action_values.omni_purchase': 739.12,
            'outbound_clicks_ctr.outbound_click': '1.025513',
            'actions.landing_page_view': '121',
            'actions.omni_purchase': 10.0,
            'actions.offsite_conversion.fb_pixel_purchase': '10',
            'purchase_roas.omni_purchase': 1.763168,
        },
        {
            spend: '7.19',
            adset_id: '23849691511040131',
            impressions: '335',
            ad_id: '23849691511300131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.492537',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '85.56',
            adset_id: '23849691511040131',
            impressions: '3369',
            ad_id: '23849804512950131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 15.0,
            'action_values.omni_purchase': 77.9,
            'outbound_clicks_ctr.outbound_click': '0.62333',
            'actions.landing_page_view': '20',
            'actions.omni_purchase': 3.0,
            'actions.offsite_conversion.fb_pixel_purchase': '3',
            'purchase_roas.omni_purchase': 0.910472,
        },
        {
            spend: '81.86',
            adset_id: '23849691917680131',
            impressions: '24348',
            ad_id: '23849691917670131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.201249',
            'actions.landing_page_view': '15',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '82.77',
            adset_id: '23849691921870131',
            impressions: '31941',
            ad_id: '23849691921880131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.278639',
            'actions.landing_page_view': '21',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1020.33',
            adset_id: '23849723631080131',
            impressions: '63473',
            ad_id: '23849723631140131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 6.4,
            'action_values.omni_purchase': 1000.27,
            'outbound_clicks_ctr.outbound_click': '0.697935',
            'actions.landing_page_view': '250',
            'actions.omni_purchase': 16.0,
            'actions.offsite_conversion.fb_pixel_purchase': '16',
            'purchase_roas.omni_purchase': 0.98034,
        },
        {
            spend: '363.7',
            adset_id: '23849723631090131',
            impressions: '23191',
            ad_id: '23849723631150131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 8.235294117647058,
            'action_values.omni_purchase': 464.65,
            'outbound_clicks_ctr.outbound_click': '0.689923',
            'actions.landing_page_view': '85',
            'actions.omni_purchase': 7.0,
            'actions.offsite_conversion.fb_pixel_purchase': '7',
            'purchase_roas.omni_purchase': 1.277564,
        },
        {
            spend: '74.49',
            adset_id: '23849723631110131',
            impressions: '3824',
            ad_id: '23849723631120131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 18.181818181818183,
            'action_values.omni_purchase': 149.9,
            'outbound_clicks_ctr.outbound_click': '0.41841',
            'actions.landing_page_view': '11',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 2.012351,
        },
        {
            spend: '73.45',
            adset_id: '23849723631130131',
            impressions: '3112',
            ad_id: '23849723631100131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 14.285714285714285,
            'action_values.omni_purchase': 139.9,
            'outbound_clicks_ctr.outbound_click': '0.674807',
            'actions.landing_page_view': '14',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 1.904697,
        },
        {
            spend: '21.51',
            adset_id: '23849733666730131',
            impressions: '520',
            ad_id: '23849733666720131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.384615',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '20.11',
            adset_id: '23849733666750131',
            impressions: '177',
            ad_id: '23849733666740131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 100.0,
            'action_values.omni_purchase': 59.95,
            'outbound_clicks_ctr.outbound_click': '1.694915',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 2.981104,
        },
        {
            spend: '30.62',
            adset_id: '23849733666760131',
            impressions: '445',
            ad_id: '23849733666770131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 100.0,
            'action_values.omni_purchase': 129.41,
            'outbound_clicks_ctr.outbound_click': '0.674157',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 4.226323,
        },
        {
            spend: '25.35',
            adset_id: '23849733728520131',
            impressions: '289',
            ad_id: '23849733728510131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '2.768166',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '231.63',
            adset_id: '23849733739760131',
            impressions: '5305',
            ad_id: '23849733739770131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 9.30232558139535,
            'action_values.omni_purchase': 269.8,
            'outbound_clicks_ctr.outbound_click': '1.093308',
            'actions.landing_page_view': '43',
            'actions.omni_purchase': 4.0,
            'actions.offsite_conversion.fb_pixel_purchase': '4',
            'purchase_roas.omni_purchase': 1.164789,
        },
        {
            spend: '27.8',
            adset_id: '23849733755820131',
            impressions: '747',
            ad_id: '23849733755830131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 11.11111111111111,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '1.338688',
            'actions.landing_page_view': '9',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 2.516187,
        },
        {
            spend: '23.18',
            adset_id: '23849733758210131',
            impressions: '584',
            ad_id: '23849733758200131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.513699',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '28.66',
            adset_id: '23849733762250131',
            impressions: '515',
            ad_id: '23849733762240131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.359223',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '106.14',
            adset_id: '23849734588940131',
            impressions: '53770',
            ad_id: '23849734588930131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.195276',
            'actions.landing_page_view': '26',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '31.75',
            adset_id: '23849734595390131',
            impressions: '1949',
            ad_id: '23849734595380131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.359159',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '395.57',
            adset_id: '23849734599990131',
            impressions: '22149',
            ad_id: '23849734599980131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 12.380952380952381,
            'action_values.omni_purchase': 768.58,
            'outbound_clicks_ctr.outbound_click': '1.074541',
            'actions.landing_page_view': '105',
            'actions.omni_purchase': 13.0,
            'actions.offsite_conversion.fb_pixel_purchase': '13',
            'purchase_roas.omni_purchase': 1.942968,
        },
        {
            spend: '480.23',
            adset_id: '23849734605220131',
            impressions: '22901',
            ad_id: '23849734605210131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 1.550387596899225,
            'action_values.omni_purchase': 114.52,
            'outbound_clicks_ctr.outbound_click': '0.67246',
            'actions.landing_page_view': '129',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 0.238469,
        },
        {
            spend: '89.19',
            adset_id: '23849734633770131',
            impressions: '4693',
            ad_id: '23849734633730131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 8.333333333333332,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.447475',
            'actions.landing_page_view': '12',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.784281,
        },
        {
            spend: '1574.71',
            adset_id: '23849734633830131',
            impressions: '125335',
            ad_id: '23849734633780131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 6.415929203539823,
            'action_values.omni_purchase': 1893.55,
            'outbound_clicks_ctr.outbound_click': '1.083496',
            'actions.landing_page_view': '452',
            'actions.omni_purchase': 29.0,
            'actions.offsite_conversion.fb_pixel_purchase': '29',
            'purchase_roas.omni_purchase': 1.202475,
        },
        {
            spend: '205.71',
            adset_id: '23849734633840131',
            impressions: '13544',
            ad_id: '23849734633800131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 22.033898305084744,
            'action_values.omni_purchase': 866.37,
            'outbound_clicks_ctr.outbound_click': '0.649734',
            'actions.landing_page_view': '59',
            'actions.omni_purchase': 13.0,
            'actions.offsite_conversion.fb_pixel_purchase': '13',
            'purchase_roas.omni_purchase': 4.211609,
        },
        {
            spend: '343.06',
            adset_id: '23849734633860131',
            impressions: '29660',
            ad_id: '23849734633820131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 4.827586206896552,
            'action_values.omni_purchase': 444.27,
            'outbound_clicks_ctr.outbound_click': '0.640593',
            'actions.landing_page_view': '145',
            'actions.omni_purchase': 7.0,
            'actions.offsite_conversion.fb_pixel_purchase': '7',
            'purchase_roas.omni_purchase': 1.295021,
        },
        {
            spend: '164.37',
            adset_id: '23849734633870131',
            impressions: '13314',
            ad_id: '23849734633740131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 7.6923076923076925,
            'action_values.omni_purchase': 279.8,
            'outbound_clicks_ctr.outbound_click': '0.615893',
            'actions.landing_page_view': '52',
            'actions.omni_purchase': 4.0,
            'actions.offsite_conversion.fb_pixel_purchase': '4',
            'purchase_roas.omni_purchase': 1.702257,
        },
        {
            spend: '11.31',
            adset_id: '23849734633880131',
            impressions: '598',
            ad_id: '23849734633750131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.167224',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '5.39',
            adset_id: '23849734633890131',
            impressions: '238',
            ad_id: '23849734633850131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.680672',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '178.84',
            adset_id: '23849734824970131',
            impressions: '6813',
            ad_id: '23849734824960131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 11.428571428571429,
            'action_values.omni_purchase': 263.71,
            'outbound_clicks_ctr.outbound_click': '0.631146',
            'actions.landing_page_view': '35',
            'actions.omni_purchase': 4.0,
            'actions.offsite_conversion.fb_pixel_purchase': '4',
            'purchase_roas.omni_purchase': 1.474558,
        },
        {
            spend: '15.7',
            adset_id: '23849748837950131',
            impressions: '3065',
            ad_id: '23849748838150131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '3.915171',
            'actions.landing_page_view': '11',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '22.64',
            adset_id: '23849803054850131',
            impressions: '1560',
            ad_id: '23849803054860131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 15.384615384615385,
            'action_values.omni_purchase': 124.9,
            'outbound_clicks_ctr.outbound_click': '0.961538',
            'actions.landing_page_view': '13',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 5.516784,
        },
        {
            spend: '21.46',
            adset_id: '23849803058010131',
            impressions: '1070',
            ad_id: '23849803058000131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 25.0,
            'action_values.omni_purchase': 59.95,
            'outbound_clicks_ctr.outbound_click': '1.028037',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 2.793569,
        },
        {
            spend: '11.85',
            adset_id: '23849804482080131',
            impressions: '1222',
            ad_id: '23849804482070131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.409165',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '263.11',
            adset_id: '23849804505690131',
            impressions: '17473',
            ad_id: '23849804505680131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 4.838709677419355,
            'action_values.omni_purchase': 194.85,
            'outbound_clicks_ctr.outbound_click': '0.457849',
            'actions.landing_page_view': '62',
            'actions.omni_purchase': 3.0,
            'actions.offsite_conversion.fb_pixel_purchase': '3',
            'purchase_roas.omni_purchase': 0.740565,
        },
        {
            spend: '2.79',
            adset_id: '23849812041060131',
            impressions: '121',
            ad_id: '23849812041050131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '16.99',
            adset_id: '23849812041060131',
            impressions: '822',
            ad_id: '23849812041080131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.973236',
            'actions.landing_page_view': '8',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '15.56',
            adset_id: '23849812062420131',
            impressions: '574',
            ad_id: '23849812062430131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 14.285714285714285,
            'action_values.omni_purchase': 55.96,
            'outbound_clicks_ctr.outbound_click': '1.567944',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 3.596401,
        },
        {
            spend: '2.66',
            adset_id: '23849812062420131',
            impressions: '130',
            ad_id: '23849812062450131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.538462',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '11.2',
            adset_id: '23849830715840131',
            impressions: '586',
            ad_id: '23849830715910131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.341297',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '159.19',
            adset_id: '23849831347830131',
            impressions: '4241',
            ad_id: '23849831347930131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 47.05882352941176,
            'action_values.omni_purchase': 530.12,
            'outbound_clicks_ctr.outbound_click': '0.518746',
            'actions.landing_page_view': '17',
            'actions.omni_purchase': 8.0,
            'actions.offsite_conversion.fb_pixel_purchase': '8',
            'purchase_roas.omni_purchase': 3.330109,
        },
        {
            spend: '101.98',
            adset_id: '23849832988690131',
            impressions: '1297',
            ad_id: '23849832988680131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 33.33333333333333,
            'action_values.omni_purchase': 161.91,
            'outbound_clicks_ctr.outbound_click': '0.539707',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 1.587664,
        },
        {
            spend: '241.93',
            adset_id: '23849841299080131',
            impressions: '14063',
            ad_id: '23849841299190131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 7.142857142857142,
            'action_values.omni_purchase': 404.7,
            'outbound_clicks_ctr.outbound_click': '1.07374',
            'actions.landing_page_view': '84',
            'actions.omni_purchase': 6.0,
            'actions.offsite_conversion.fb_pixel_purchase': '6',
            'purchase_roas.omni_purchase': 1.672798,
        },
        {
            spend: '65.38',
            adset_id: '23849841299090131',
            impressions: '4068',
            ad_id: '23849841299100131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.565388',
            'actions.landing_page_view': '17',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '30.79',
            adset_id: '23849841299110131',
            impressions: '1647',
            ad_id: '23849841299150131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.425015',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '328.86',
            adset_id: '23849841299170131',
            impressions: '30558',
            ad_id: '23849841299120131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 4.166666666666666,
            'action_values.omni_purchase': 195.86,
            'outbound_clicks_ctr.outbound_click': '0.507232',
            'actions.landing_page_view': '72',
            'actions.omni_purchase': 3.0,
            'actions.offsite_conversion.fb_pixel_purchase': '3',
            'purchase_roas.omni_purchase': 0.595573,
        },
        {
            spend: '18.73',
            adset_id: '23849841299180131',
            impressions: '1070',
            ad_id: '23849841299130131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.654206',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '136.59',
            adset_id: '23849841299210131',
            impressions: '10817',
            ad_id: '23849841299220131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 10.0,
            'action_values.omni_purchase': 404.7,
            'outbound_clicks_ctr.outbound_click': '0.64713',
            'actions.landing_page_view': '60',
            'actions.omni_purchase': 6.0,
            'actions.offsite_conversion.fb_pixel_purchase': '6',
            'purchase_roas.omni_purchase': 2.962882,
        },
        {
            spend: '17.24',
            adset_id: '23849841299230131',
            impressions: '1049',
            ad_id: '23849841299160131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.953289',
            'actions.landing_page_view': '9',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.74',
            adset_id: '23849841299240131',
            impressions: '641',
            ad_id: '23849841299140131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 33.33333333333333,
            'action_values.omni_purchase': 54.57,
            'outbound_clicks_ctr.outbound_click': '0.624025',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 3.702171,
        },
        {
            spend: '13.7',
            adset_id: '23849862648690131',
            impressions: '946',
            ad_id: '23849862648710131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.211416',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.37',
            adset_id: '23849862648690131',
            impressions: '115',
            ad_id: '23849862730470131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.95',
            adset_id: '23849862952700131',
            impressions: '155',
            ad_id: '23849862952690131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.290323',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.18',
            adset_id: '23849862952700131',
            impressions: '1153',
            ad_id: '23849862952720131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.8',
            adset_id: '23849862952710131',
            impressions: '971',
            ad_id: '23849862952680131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 14.285714285714285,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.720906',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 4.726351,
        },
        {
            spend: '3.44',
            adset_id: '23849862952710131',
            impressions: '212',
            ad_id: '23849862952750131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.471698',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.84',
            adset_id: '23849862952740131',
            impressions: '185',
            ad_id: '23849862952730131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.540541',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.27',
            adset_id: '23849862952740131',
            impressions: '1377',
            ad_id: '23849862952760131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.363108',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.63',
            adset_id: '23849862968420131',
            impressions: '230',
            ad_id: '23849862968490131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.73913',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.39',
            adset_id: '23849862968420131',
            impressions: '1192',
            ad_id: '23849862968520131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.755034',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.06',
            adset_id: '23849862968450131',
            impressions: '1138',
            ad_id: '23849862968500131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.351494',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.96',
            adset_id: '23849862968450131',
            impressions: '126',
            ad_id: '23849862968510131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.36',
            adset_id: '23849862968470131',
            impressions: '997',
            ad_id: '23849862968430131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.401204',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.77',
            adset_id: '23849862968470131',
            impressions: '186',
            ad_id: '23849862968440131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '11.54',
            adset_id: '23849862968540131',
            impressions: '759',
            ad_id: '23849862968460131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 50.0,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.395257',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 6.061525,
        },
        {
            spend: '3.53',
            adset_id: '23849862968540131',
            impressions: '245',
            ad_id: '23849862968480131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '2.44898',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.91',
            adset_id: '23849862975670131',
            impressions: '754',
            ad_id: '23849862975660131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.66313',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.15',
            adset_id: '23849862975670131',
            impressions: '111',
            ad_id: '23849862975710131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.900901',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.71',
            adset_id: '23849862975700131',
            impressions: '878',
            ad_id: '23849862975730131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.113895',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.46',
            adset_id: '23849862975700131',
            impressions: '206',
            ad_id: '23849862975770131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.456311',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.57',
            adset_id: '23849862975740131',
            impressions: '829',
            ad_id: '23849862975720131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.120627',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.48',
            adset_id: '23849862975740131',
            impressions: '120',
            ad_id: '23849862975760131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.666667',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.19',
            adset_id: '23849862975790131',
            impressions: '979',
            ad_id: '23849862975750131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.20429',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.84',
            adset_id: '23849862975790131',
            impressions: '100',
            ad_id: '23849862975780131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.47',
            adset_id: '23849862982920131',
            impressions: '1455',
            ad_id: '23849862982950131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 10.0,
            'action_values.omni_purchase': 55.96,
            'outbound_clicks_ctr.outbound_click': '0.756014',
            'actions.landing_page_view': '10',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 3.867312,
        },
        {
            spend: '3.6',
            adset_id: '23849862982920131',
            impressions: '339',
            ad_id: '23849862982990131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.589971',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.83',
            adset_id: '23849862982960131',
            impressions: '931',
            ad_id: '23849862982940131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.644468',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.26',
            adset_id: '23849862982960131',
            impressions: '134',
            ad_id: '23849862982980131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.98',
            adset_id: '23849862983000131',
            impressions: '97',
            ad_id: '23849862982910131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '2.061856',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.07',
            adset_id: '23849862983000131',
            impressions: '1013',
            ad_id: '23849862982930131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.5923',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.86',
            adset_id: '23849862983010131',
            impressions: '1141',
            ad_id: '23849862982890131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.613497',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.33',
            adset_id: '23849862983010131',
            impressions: '171',
            ad_id: '23849862982970131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.169591',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.65',
            adset_id: '23849862993770131',
            impressions: '155',
            ad_id: '23849862993820131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.290323',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.37',
            adset_id: '23849862993770131',
            impressions: '1111',
            ad_id: '23849862993850131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.270027',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.53',
            adset_id: '23849862993810131',
            impressions: '1066',
            ad_id: '23849862993740131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 16.666666666666664,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.469043',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 5.169993,
        },
        {
            spend: '1.62',
            adset_id: '23849862993810131',
            impressions: '144',
            ad_id: '23849862993750131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '2.083333',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '3.43',
            adset_id: '23849862993830131',
            impressions: '295',
            ad_id: '23849862993730131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.338983',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '11.72',
            adset_id: '23849862993830131',
            impressions: '1236',
            ad_id: '23849862993760131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.647249',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.03',
            adset_id: '23849862993840131',
            impressions: '78',
            ad_id: '23849862993790131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.98',
            adset_id: '23849862993840131',
            impressions: '908',
            ad_id: '23849862993800131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.881057',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '16.01',
            adset_id: '23849863023120131',
            impressions: '1200',
            ad_id: '23849863023090131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.5',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.25',
            adset_id: '23849863023120131',
            impressions: '239',
            ad_id: '23849863023160131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 50.0,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.83682',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 31.088889,
        },
        {
            spend: '1.98',
            adset_id: '23849863023140131',
            impressions: '153',
            ad_id: '23849863023110131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.09',
            adset_id: '23849863023140131',
            impressions: '845',
            ad_id: '23849863023130131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.473373',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.89',
            adset_id: '23849863023170131',
            impressions: '104',
            ad_id: '23849863023060131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.961538',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.15',
            adset_id: '23849863023170131',
            impressions: '1199',
            ad_id: '23849863023150131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.500417',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.16',
            adset_id: '23849863023180131',
            impressions: '1295',
            ad_id: '23849863023080131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.46332',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.88',
            adset_id: '23849863023180131',
            impressions: '138',
            ad_id: '23849863023100131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.88',
            adset_id: '23849863034030131',
            impressions: '1080',
            ad_id: '23849863034070131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.555556',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.24',
            adset_id: '23849863034030131',
            impressions: '173',
            ad_id: '23849863034080131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '2.890173',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '15.93',
            adset_id: '23849863034050131',
            impressions: '1186',
            ad_id: '23849863034020131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 33.33333333333333,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.337268',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 4.391086,
        },
        {
            spend: '2.25',
            adset_id: '23849863034050131',
            impressions: '233',
            ad_id: '23849863034060131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.287554',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '16.18',
            adset_id: '23849863034090131',
            impressions: '1259',
            ad_id: '23849863034110131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 100.0,
            'action_values.omni_purchase': 349.75,
            'outbound_clicks_ctr.outbound_click': '0.635425',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 5.0,
            'actions.offsite_conversion.fb_pixel_purchase': '5',
            'purchase_roas.omni_purchase': 21.616193,
        },
        {
            spend: '2.1',
            adset_id: '23849863034090131',
            impressions: '199',
            ad_id: '23849863034120131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 100.0,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.502513',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 33.309524,
        },
        {
            spend: '2.2',
            adset_id: '23849863034130131',
            impressions: '139',
            ad_id: '23849863034040131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.719424',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.85',
            adset_id: '23849863034130131',
            impressions: '830',
            ad_id: '23849863034100131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.60241',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.86',
            adset_id: '23849863053510131',
            impressions: '246',
            ad_id: '23849863053520131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '12.22',
            adset_id: '23849863053510131',
            impressions: '1223',
            ad_id: '23849863053530131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.490597',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.67',
            adset_id: '23849863053550131',
            impressions: '1123',
            ad_id: '23849863053500131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.712378',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.39',
            adset_id: '23849863053550131',
            impressions: '119',
            ad_id: '23849863053600131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.840336',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '3.12',
            adset_id: '23849863053570131',
            impressions: '220',
            ad_id: '23849863053540131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.454545',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '11.97',
            adset_id: '23849863053570131',
            impressions: '843',
            ad_id: '23849863053610131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.118624',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.36',
            adset_id: '23849863053620131',
            impressions: '163',
            ad_id: '23849863053490131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '15.92',
            adset_id: '23849863053620131',
            impressions: '1187',
            ad_id: '23849863053590131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 14.285714285714285,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.84246',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 4.393844,
        },
        {
            spend: '15.48',
            adset_id: '23849863074590131',
            impressions: '1138',
            ad_id: '23849863074570131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 33.33333333333333,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.439367',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 4.518734,
        },
        {
            spend: '2.68',
            adset_id: '23849863074590131',
            impressions: '227',
            ad_id: '23849863074600131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.8',
            adset_id: '23849863074610131',
            impressions: '117',
            ad_id: '23849863074640131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.854701',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '16.35',
            adset_id: '23849863074610131',
            impressions: '1212',
            ad_id: '23849863074680131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 16.666666666666664,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.49505',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 4.278287,
        },
        {
            spend: '4.1',
            adset_id: '23849863074650131',
            impressions: '391',
            ad_id: '23849863074620131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.767263',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '10.92',
            adset_id: '23849863074650131',
            impressions: '1042',
            ad_id: '23849863074660131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.383877',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.58',
            adset_id: '23849863074690131',
            impressions: '57',
            ad_id: '23849863074630131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.53',
            adset_id: '23849863074690131',
            impressions: '1207',
            ad_id: '23849863074670131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.3314',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '14.27',
            adset_id: '23849863090420131',
            impressions: '1616',
            ad_id: '23849863090410131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 28.57142857142857,
            'action_values.omni_purchase': 124.9,
            'outbound_clicks_ctr.outbound_click': '0.618812',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 8.752628,
        },
        {
            spend: '3.82',
            adset_id: '23849863090420131',
            impressions: '321',
            ad_id: '23849863090450131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.934579',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.08',
            adset_id: '23849863090460131',
            impressions: '150',
            ad_id: '23849863090490131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.96',
            adset_id: '23849863090460131',
            impressions: '1098',
            ad_id: '23849863090500131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.182149',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.59',
            adset_id: '23849863090510131',
            impressions: '166',
            ad_id: '23849863090400131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.60241',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '13.5',
            adset_id: '23849863090510131',
            impressions: '1072',
            ad_id: '23849863090440131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.279851',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '11.08',
            adset_id: '23849863090520131',
            impressions: '733',
            ad_id: '23849863090430131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 20.0,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.818554',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 6.313177,
        },
        {
            spend: '4.28',
            adset_id: '23849863090520131',
            impressions: '275',
            ad_id: '23849863090470131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.363636',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '52.73',
            adset_id: '23849870838800131',
            impressions: '2542',
            ad_id: '23849870838840131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 5.555555555555555,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.944138',
            'actions.landing_page_view': '18',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 1.326569,
        },
        {
            spend: '21.46',
            adset_id: '23849870838820131',
            impressions: '772',
            ad_id: '23849870838900131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.777202',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '40.37',
            adset_id: '23849870838880131',
            impressions: '1431',
            ad_id: '23849870838830131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 11.11111111111111,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.908456',
            'actions.landing_page_view': '9',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 1.732722,
        },
        {
            spend: '96.14',
            adset_id: '23849870838890131',
            impressions: '4126',
            ad_id: '23849870838770131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 5.0,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.70286',
            'actions.landing_page_view': '20',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.727585,
        },
        {
            spend: '38.29',
            adset_id: '23849870838910131',
            impressions: '2059',
            ad_id: '23849870838870131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 10.0,
            'action_values.omni_purchase': 59.95,
            'outbound_clicks_ctr.outbound_click': '0.582807',
            'actions.landing_page_view': '10',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 1.565683,
        },
        {
            spend: '111.19',
            adset_id: '23849870838920131',
            impressions: '3936',
            ad_id: '23849870838780131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 6.666666666666667,
            'action_values.omni_purchase': 54.57,
            'outbound_clicks_ctr.outbound_click': '0.533537',
            'actions.landing_page_view': '15',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.490782,
        },
        {
            spend: '145.96',
            adset_id: '23849870884160131',
            impressions: '5321',
            ad_id: '23849870884230131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 11.11111111111111,
            'action_values.omni_purchase': 255.81,
            'outbound_clicks_ctr.outbound_click': '0.770532',
            'actions.landing_page_view': '36',
            'actions.omni_purchase': 4.0,
            'actions.offsite_conversion.fb_pixel_purchase': '4',
            'purchase_roas.omni_purchase': 1.752603,
        },
        {
            spend: '25.34',
            adset_id: '23849870884240131',
            impressions: '956',
            ad_id: '23849870884190131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.313808',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '61.2',
            adset_id: '23849870884250131',
            impressions: '2616',
            ad_id: '23849870884130131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 7.142857142857142,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.7263',
            'actions.landing_page_view': '14',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 1.142974,
        },
        {
            spend: '13.14',
            adset_id: '23849870884270131',
            impressions: '556',
            ad_id: '23849870884150131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 33.33333333333333,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.539568',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 5.32344,
        },
        {
            spend: '74.42',
            adset_id: '23849870884280131',
            impressions: '2819',
            ad_id: '23849870884210131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 5.88235294117647,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.851366',
            'actions.landing_page_view': '17',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.939936,
        },
        {
            spend: '40.34',
            adset_id: '23849870884290131',
            impressions: '2289',
            ad_id: '23849870884220131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.655308',
            'actions.landing_page_view': '9',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '11.32',
            adset_id: '23849872860060131',
            impressions: '1591',
            ad_id: '23849872860110131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '2.702703',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '7.77',
            adset_id: '23849886442040131',
            impressions: '739',
            ad_id: '23849886442300131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.67659',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '26.17',
            adset_id: '23849886442040131',
            impressions: '1630',
            ad_id: '23849886505680131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.042945',
            'actions.landing_page_view': '11',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '25.3',
            adset_id: '23849886442040131',
            impressions: '2421',
            ad_id: '23849893848700131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.330442',
            'actions.landing_page_view': '6',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '29.22',
            adset_id: '23849886610790131',
            impressions: '1739',
            ad_id: '23849886610780131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.035078',
            'actions.landing_page_view': '9',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '10.58',
            adset_id: '23849886610790131',
            impressions: '963',
            ad_id: '23849886610800131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.623053',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '19.13',
            adset_id: '23849886610790131',
            impressions: '1480',
            ad_id: '23849893849830131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.405405',
            'actions.landing_page_view': '4',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.98',
            adset_id: '23849892848480131',
            impressions: '180',
            ad_id: '23849892848500131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.666667',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '3.37',
            adset_id: '23849893546210131',
            impressions: '181',
            ad_id: '23849893546330131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '2.03',
            adset_id: '23849893546210131',
            impressions: '176',
            ad_id: '23849893546340131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.568182',
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.01',
            adset_id: '23849893546210131',
            impressions: '5',
            ad_id: '23849893834250131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '4.52',
            adset_id: '23849893546210131',
            impressions: '388',
            ad_id: '23849893873860131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.257732',
            'actions.landing_page_view': '1',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '155.33',
            adset_id: '23849893546220131',
            impressions: '16828',
            ad_id: '23849893546350131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 1.3513513513513513,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.52888',
            'actions.landing_page_view': '74',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.450332,
        },
        {
            spend: '43.66',
            adset_id: '23849893546220131',
            impressions: '3049',
            ad_id: '23849893546360131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.11512',
            'actions.landing_page_view': '18',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '17.64',
            adset_id: '23849893546220131',
            impressions: '2228',
            ad_id: '23849893841960131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.448833',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '271.02',
            adset_id: '23849893546220131',
            impressions: '27856',
            ad_id: '23849893869000131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.34104',
            'actions.landing_page_view': '65',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '190.99',
            adset_id: '23849893546230131',
            impressions: '13922',
            ad_id: '23849893546380131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 2.857142857142857,
            'action_values.omni_purchase': 114.52,
            'outbound_clicks_ctr.outbound_click': '0.660825',
            'actions.landing_page_view': '70',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 0.599613,
        },
        {
            spend: '34.53',
            adset_id: '23849893546230131',
            impressions: '2260',
            ad_id: '23849893546430131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.79646',
            'actions.landing_page_view': '11',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '10.34',
            adset_id: '23849893546230131',
            impressions: '1415',
            ad_id: '23849893829080131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.141343',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '147.75',
            adset_id: '23849893546230131',
            impressions: '13023',
            ad_id: '23849893867630131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 6.666666666666667,
            'action_values.omni_purchase': 124.9,
            'outbound_clicks_ctr.outbound_click': '0.284113',
            'actions.landing_page_view': '30',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 0.845347,
        },
        {
            spend: '89.49',
            adset_id: '23849893546240131',
            impressions: '8194',
            ad_id: '23849893546390131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 5.263157894736842,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.402734',
            'actions.landing_page_view': '19',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.781652,
        },
        {
            spend: '251.09',
            adset_id: '23849893546240131',
            impressions: '19934',
            ad_id: '23849893546420131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 3.3333333333333335,
            'action_values.omni_purchase': 129.41,
            'outbound_clicks_ctr.outbound_click': '0.391291',
            'actions.landing_page_view': '60',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 0.515393,
        },
        {
            spend: '8.23',
            adset_id: '23849893546240131',
            impressions: '1199',
            ad_id: '23849893827330131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.250209',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '138.24',
            adset_id: '23849893546240131',
            impressions: '11459',
            ad_id: '23849893866750131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.357797',
            'actions.landing_page_view': '29',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '79.14',
            adset_id: '23849893546250131',
            impressions: '5886',
            ad_id: '23849893546370131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.560652',
            'actions.landing_page_view': '27',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '268.65',
            adset_id: '23849893546250131',
            impressions: '19826',
            ad_id: '23849893546410131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 1.2048192771084338,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.504388',
            'actions.landing_page_view': '83',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.260376,
        },
        {
            spend: '42.17',
            adset_id: '23849893546250131',
            impressions: '5254',
            ad_id: '23849893826530131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.266464',
            'actions.landing_page_view': '10',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '180.98',
            adset_id: '23849893546250131',
            impressions: '15533',
            ad_id: '23849893866300131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 3.0303030303030303,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.315457',
            'actions.landing_page_view': '33',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.386507,
        },
        {
            spend: '59.36',
            adset_id: '23849893546260131',
            impressions: '4768',
            ad_id: '23849893546490131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 5.555555555555555,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.713087',
            'actions.landing_page_view': '18',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 1.178403,
        },
        {
            spend: '146.44',
            adset_id: '23849893546260131',
            impressions: '12217',
            ad_id: '23849893546530131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 1.9607843137254901,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.622084',
            'actions.landing_page_view': '51',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.47767,
        },
        {
            spend: '14.89',
            adset_id: '23849893546260131',
            impressions: '1725',
            ad_id: '23849893825210131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.115942',
            'actions.landing_page_view': '3',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '266.97',
            adset_id: '23849893546260131',
            impressions: '28645',
            ad_id: '23849893860790131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.272299',
            'actions.landing_page_view': '54',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '244.87',
            adset_id: '23849893546270131',
            impressions: '13403',
            ad_id: '23849893546480131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 2.7777777777777777,
            'action_values.omni_purchase': 139.9,
            'outbound_clicks_ctr.outbound_click': '0.925166',
            'actions.landing_page_view': '72',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 0.571324,
        },
        {
            spend: '153.17',
            adset_id: '23849893546270131',
            impressions: '9830',
            ad_id: '23849893546500131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.437436',
            'actions.landing_page_view': '29',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '5.82',
            adset_id: '23849893546270131',
            impressions: '687',
            ad_id: '23849893824680131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '83.71',
            adset_id: '23849893546270131',
            impressions: '6623',
            ad_id: '23849893864650131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 7.6923076923076925,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.332176',
            'actions.landing_page_view': '13',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.835623,
        },
        {
            spend: '69.77',
            adset_id: '23849893546280131',
            impressions: '5049',
            ad_id: '23849893546460131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 8.333333333333332,
            'action_values.omni_purchase': 131.51,
            'outbound_clicks_ctr.outbound_click': '0.752624',
            'actions.landing_page_view': '24',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 1.884908,
        },
        {
            spend: '264.43',
            adset_id: '23849893546280131',
            impressions: '20299',
            ad_id: '23849893546470131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 2.8301886792452833,
            'action_values.omni_purchase': 195.86,
            'outbound_clicks_ctr.outbound_click': '0.551751',
            'actions.landing_page_view': '106',
            'actions.omni_purchase': 3.0,
            'actions.offsite_conversion.fb_pixel_purchase': '3',
            'purchase_roas.omni_purchase': 0.740688,
        },
        {
            spend: '31.67',
            adset_id: '23849893546280131',
            impressions: '3802',
            ad_id: '23849893824070131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 12.5,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.263019',
            'actions.landing_page_view': '8',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 2.208715,
        },
        {
            spend: '205.13',
            adset_id: '23849893546280131',
            impressions: '17805',
            ad_id: '23849893863630131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 2.272727272727273,
            'action_values.omni_purchase': 55.96,
            'outbound_clicks_ctr.outbound_click': '0.376299',
            'actions.landing_page_view': '44',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.272803,
        },
        {
            spend: '49.33',
            adset_id: '23849893546290131',
            impressions: '3794',
            ad_id: '23849893546400131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.448076',
            'actions.landing_page_view': '12',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '282.67',
            adset_id: '23849893546290131',
            impressions: '22379',
            ad_id: '23849893546520131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 4.0,
            'action_values.omni_purchase': 129.9,
            'outbound_clicks_ctr.outbound_click': '0.32173',
            'actions.landing_page_view': '50',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 0.459546,
        },
        {
            spend: '17.79',
            adset_id: '23849893546290131',
            impressions: '2475',
            ad_id: '23849893823930131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.242424',
            'actions.landing_page_view': '5',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '137.84',
            adset_id: '23849893546290131',
            impressions: '12099',
            ad_id: '23849893862760131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.239689',
            'actions.landing_page_view': '18',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '76.46',
            adset_id: '23849893546310131',
            impressions: '6791',
            ad_id: '23849893546440131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 8.333333333333332,
            'action_values.omni_purchase': 139.9,
            'outbound_clicks_ctr.outbound_click': '0.647916',
            'actions.landing_page_view': '24',
            'actions.omni_purchase': 2.0,
            'actions.offsite_conversion.fb_pixel_purchase': '2',
            'purchase_roas.omni_purchase': 1.829715,
        },
        {
            spend: '214.87',
            adset_id: '23849893546310131',
            impressions: '15488',
            ad_id: '23849893546450131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 1.9230769230769231,
            'action_values.omni_purchase': 69.95,
            'outbound_clicks_ctr.outbound_click': '0.464876',
            'actions.landing_page_view': '52',
            'actions.omni_purchase': 1.0,
            'actions.offsite_conversion.fb_pixel_purchase': '1',
            'purchase_roas.omni_purchase': 0.325546,
        },
        {
            spend: '24.88',
            adset_id: '23849893546310131',
            impressions: '3352',
            ad_id: '23849893823520131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.298329',
            'actions.landing_page_view': '7',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '171.69',
            adset_id: '23849893546310131',
            impressions: '14982',
            ad_id: '23849893862290131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.41383',
            'actions.landing_page_view': '42',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '6.86',
            adset_id: '23849893546320131',
            impressions: '450',
            ad_id: '23849893546300131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '0.666667',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.76',
            adset_id: '23849893546320131',
            impressions: '61',
            ad_id: '23849893546510131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '0.32',
            adset_id: '23849893546320131',
            impressions: '30',
            ad_id: '23849893822820131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': 0,
            'actions.landing_page_view': 0,
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
        {
            spend: '1.88',
            adset_id: '23849893546320131',
            impressions: '175',
            ad_id: '23849893861960131',
            date_start: '2022-02-22',
            date_stop: '2022-02-28',
            'madgicx_custom.conversion_rate_ecommerce': 0.0,
            'action_values.omni_purchase': 0.0,
            'outbound_clicks_ctr.outbound_click': '1.142857',
            'actions.landing_page_view': '2',
            'actions.omni_purchase': 0.0,
            'actions.offsite_conversion.fb_pixel_purchase': 0,
            'purchase_roas.omni_purchase': 0.0,
        },
    ],
};
