import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
    password: yup
        .string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Password is required'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});
