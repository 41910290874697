const LandingPage = () => {
    return (
        <div>
            <title>Madgicx AI Copywriter</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
            <meta name="description" content="Madgicx AI Copywriter sketch" />
            <meta property="og:site_name" content="Madgicx AI Copywriter" />
            <meta property="og:title" content="Madgicx AI Copywriter" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Madgicx AI Copywriter sketch" />
            <link
                href="https://fonts.googleapis.com/css?family=Montserrat:500,500italic,400,400italic,700,700italic,600,600italic"
                rel="stylesheet"
                type="text/css"
            />
            <link rel="stylesheet" href="assets/main.css" />
            <div id="wrapper">
                <div id="main">
                    <div className="inner">
                        <div id="container02" className="container default">
                            <div className="wrapper">
                                <div className="inner" style={{ transform: 'scale(1.2, 1.2)' }}>
                                    <h2 id="text03">built by</h2>
                                    <div id="image01" className="image">
                                        <a
                                            href="https://madgicx.com?utm_source=ai-copywriter_landing"
                                            className="frame"
                                        >
                                            <img src="assets/images/image01.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container03" className="container default full screen">
                            <div className="wrapper">
                                <div className="inner" style={{ transform: 'scale(1.2, 1.2)' }}>
                                    <h1 id="text04">
                                        <span>Generate</span>
                                        <br />
                                        <span>high-converting</span>
                                        <br />
                                        <span>ad copies with AI</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <ul id="buttons01" className="buttons" style={{ transform: 'scale(1.2, 1.2)' }}>
                            <li>
                                <a
                                    href={
                                        '/signup' + '?utm_source=ai-copywriter_landing&utm_medium=ai-copywriter_landing'
                                    }
                                    className="button n01"
                                >
                                    Try for free
                                </a>
                            </li>
                        </ul>
                        <div id="container05" className="container default full screen">
                            <div className="wrapper">
                                <div className="inner"></div>
                            </div>
                        </div>
                        <div id="container04" className="container default full screen">
                            <div className="wrapper">
                                <div className="inner">
                                    <p id="text01">© 2021 All rights reserved madgicx.com</p>
                                </div>
                            </div>
                        </div>
                        <div id="container01" className="container columns full screen">
                            <div className="wrapper">
                                <div className="inner">
                                    <div>
                                        <p id="text05">
                                            <a href="https://madgicx.com/terms-conditions/">Terms of service</a>
                                        </p>
                                    </div>
                                    <div>
                                        <p id="text06">
                                            <a href="https://madgicx.com/privacy-policy/">Privacy policy</a>
                                        </p>
                                    </div>
                                    <div>
                                        <p id="text02">
                                            <a href="https://madgicx.com/cookie-policy/">Cookie policy</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
