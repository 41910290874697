import { styled, SxProps } from '@mui/material';
import { DRAWER_WIDTH } from '@constants/constants';

const Content = styled('div', {
    shouldForwardProp: prop => prop !== 'open',
})<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    backgroundColor: '#F6F6F8',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: DRAWER_WIDTH,
    }),
}));

const AppContent: React.FC<any> = (props: { open: boolean; children?: React.ReactNode; sx?: SxProps }) => {
    const { open, children, sx } = props;

    return (
        <Content open={open} sx={{ mt: '110px', ...sx }}>
            {children}
        </Content>
    );
};

export default AppContent;
