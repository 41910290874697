import { Button, SxProps } from '@mui/material';

interface ICustomButtonProps {
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * custom style
     */
    sx?: SxProps;
}

const InvisibleButton: React.FC<ICustomButtonProps> = ({ onClick = () => {}, sx = {} }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: 0.01,

                ...sx,
            }}
        />
    );
};

export default InvisibleButton;
