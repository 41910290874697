import { IAction } from '@interfaces/api/IAction';
import { FilterActions } from '@store/actions/actions';

const filterOptionReducer = (state: string[] = [], action: IAction) => {
    switch (action.type) {
        case FilterActions.SET_FILTER: {
            return [...state, ...action.payload];
        }
        case FilterActions.CLEAR_FILTER: {
            return [];
        }
        default:
            return state;
    }
};
export default filterOptionReducer;
