import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader, { Center, Left, Right } from '@components/layout/app-header/AppHeader';
import { Box } from '@mui/material';
import CustomButton from '@components/common/custom-button/CustomButton';
import { useNavigate } from 'react-router-dom';
import IconCollection from '@components/icon-collection/IconCollection';
import SearchInput from '@components/common/search-input/SearchInput';
import AppToolbar from '@components/layout/app-toolbar/AppToolbar';
import { ICategory } from '@interfaces/api/ICategory';
import { apiGetAllCategories } from '@store/api/apiCategories';
import AppContent from '@components/layout/app-content/AppContent';
import { H1, P2 } from '@components/common/typography/Typography';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import RequestModal from '@components/custom-modals/request-modal/RequestModal';
import { ProjectActions, PromptActions, TextCardActions } from '@store/actions/actions';
import AppTabs from '@components/layout/app-tabs/AppTabs';
import { apiSendRequest } from '@store/api/apiRequest';
import CustomToast from '@components/common/custom-toast/CustomToast';
import TemplateCardContainer from './templatecard-container/TemplateCardContainer';
import { getUser } from '@store/selectors/userSelector';

const TextPurpose: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [categoryId, setCategoryId] = useState<number>(1);

    const [showRequestModal, setShowRequestModal] = useState<boolean>(false);

    const [searchText, setSearchText] = useState<string>('');

    let cancelFetching = false;

    useEffect(() => {
        dispatch({
            type: ProjectActions.DESELECT_PROJECT,
            payload: {},
        });

        dispatch({
            type: TextCardActions.CLEAR_SELECTED,
            payload: {},
        });

        dispatch({
            type: TextCardActions.CLEAR_CARDS,
            payload: {},
        });

        dispatch({
            type: PromptActions.CLEAR_PROMPT,
            payload: {},
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCategoryList = async () => {
        const response = await apiGetAllCategories();
        if (!cancelFetching) {
            if (response.data) {
                setCategories(response.data);
            }
        }
    };

    useEffect(() => {
        fetchCategoryList();
        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, []);

    return (
        <>
            <AppHeader>
                <Left>
                    <CustomButton
                        label={
                            <>
                                <P2 color={'fc-black'}>Go to Library</P2>
                            </>
                        }
                        variant={'borderless'}
                        onClick={() => {
                            navigate('/library/');
                        }}
                    />
                </Left>
                <Center>
                    <H1 sx={{ caretColor: 'transparent' }}>What type of content do you need?</H1>
                </Center>
                <Right />
            </AppHeader>

            <AppToolbar>
                <SearchInput
                    label={'Search'}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.preventDefault();

                        setSearchText(e.target.value);
                    }}
                    width={320}
                />
            </AppToolbar>

            <AppToolbar sx={{ top: 110 }}>
                <Box>
                    <AppTabs
                        selections={categories}
                        onClick={selectionId => {
                            setCategoryId(selectionId);
                        }}
                    />
                </Box>
                <CustomTooltip title={'Tell us what we are missing'} placement={'top'}>
                    <CustomButton
                        label={
                            <>
                                <IconCollection icon="LIGHT_BULB" size="xl" sx={{ mr: { xs: 0, md: 2 } }} />
                                <P2 color={'hl-blue'} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    Request a purpose
                                </P2>
                            </>
                        }
                        variant={'borderless'}
                        onClick={() => {
                            setShowRequestModal(true);
                        }}
                    />
                </CustomTooltip>
            </AppToolbar>

            <AppContent sx={{ mt: '165px' }}>
                <Box p={4} width={'100%'} display={'flex'} flexDirection={'column'}>
                    {categoryId === 1 ? (
                        categories.map(category => {
                            return (
                                <Box p={6} key={category.id}>
                                    <TemplateCardContainer
                                        currentTab="popular"
                                        category={category}
                                        categoryId={category?.id}
                                        searchText={searchText}
                                    />
                                </Box>
                            );
                        })
                    ) : (
                        <Box p={6}>
                            <TemplateCardContainer currentTab="other" categoryId={categoryId} searchText={searchText} />
                        </Box>
                    )}
                </Box>
            </AppContent>

            {showRequestModal && (
                <RequestModal
                    header={'Request a purpose'}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowRequestModal(false),
                    }}
                    confirmButton={{
                        label: 'Submit',

                        onConfirm: async (name: string, description: string) => {
                            const data = JSON.stringify({
                                platform_name: name,
                                description: description,
                                user_name: user.email,
                            });

                            const response = await apiSendRequest(data);

                            if (response.data) {
                                CustomToast({
                                    message: 'Request Sent!',
                                    variant: 'ALERT_INFO',
                                    width: '300px',
                                });
                            }
                            setShowRequestModal(false);
                        },
                    }}
                />
            )}
        </>
    );
};

export default TextPurpose;
