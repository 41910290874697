import ShareRightSidePanel from './ShareRightSidePanel';
// import ShareLeftSidePanel from "./ShareLeftSidePanel";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IProject } from '@interfaces/api/IProject';

const TextSharing = () => {
    const { id } = useParams('id');
    const [projects, setNewProjectResult] = useState<IProject[]>([]);
    // const [resultCount, setResultCount] = useState<number>(0);

    useEffect(() => {
        getRequestForSharedText();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getRequestForSharedText = () => {
        fetch(process.env.REACT_APP_API_URL + `/api/v1/sharing/results/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + process.env.REACT_APP_API_KEY,
            },
        })
            .then(response => response.json())
            .then(response => {
                // setResultCount(response[0].results.length);
                const projectList = response.map(project => ({
                    results: project.results,
                }));
                setNewProjectResult(projectList);
            })
            .catch(err => {
                console.log(err.message);
            });
    };

    return (
        <div>
            <div className="shared-text-header">Untitled</div>
            <div className="shared-text-block">
                <div className="shared-text-block-content">
                    <div className="shared-text-block-content-2">
                        <div className="shared-text-block-content-3">
                            {projects.map(project =>
                                project.results.map((d, index) => <ShareRightSidePanel id={index} results={d} />),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextSharing;
