import { useState } from 'react';
import plus from '@icons/plus.svg';
import { apiCreateNewCategory, apiCreateNewTemplate } from '@store/api/apiSettings';

const AddItem = props => {
    const [value, setValue] = useState('');
    const { addItem, dataId, data, type } = props;

    const handleSubmit = async e => {
        e.preventDefault();
        value && addItem(value);

        // Check if the selected tab is Category or Template
        if (type === 'template') {
            const new_data = JSON.stringify({
                template_name: value,
                category_id: dataId,
                category_name: data,
                template_short_name: value,
            });

            const response = await apiCreateNewTemplate(new_data);

            if (response.error) {
                console.log(response.error);
            } else {
                setValue('');
            }
        } else {
            const new_data = JSON.stringify({
                category_name: value,
            });

            const response = await apiCreateNewCategory(new_data);

            if (response.error) {
                console.log(response.error);
            } else {
                setValue('');
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="settings" style={{ marginTop: '10px' }}>
                <input
                    type="text"
                    style={{
                        width: '320px',
                        outline: 'none',
                        border: 'none',
                        fontSize: '14px',
                        lineHeight: '14px',
                    }}
                    placeholder={type === 'template' ? 'Enter new template for this category...' : 'Enter new category'}
                    onChange={e => setValue(e.target.value)}
                    value={value}
                />
                <button type="submit" className="font-add-icon supplementary button-icon">
                    <img src={plus} alt="add" />
                </button>
            </form>
        </>
    );
};

export default AddItem;
