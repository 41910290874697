import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import TextLibrary from '@pages/library/TextLibrary';
import TextSharing from '@pages/share/TextSharing';
import Login from '@pages/login/Login';
import Settings from '@pages/settings/Settings';
import SignUp from '@pages/signup/SignUp';
import PrivateRoute from '@pages/login/PrivateRoute';
import AdminRoute from '@pages/login/AdminRoute';
import TextPurpose from '@pages/tool/TextPurpose';
import UserData from '@pages/users/UserData';
import LandingPage from '@pages/landing-page/LandingPage';
import TextGenerator from '@pages/generator/TextGenerator';
import { ThemeProvider } from '@mui/material';
import { theme } from '@theme/theme';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Implement this in production only
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://9c38cee0729a4635b70747fcd71fe1fb@o879332.ingest.sentry.io/5833358',
        integrations: [new Integrations.BrowserTracing()],

        // Suggest to implement to adjust this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route index path="/" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/d/:id" element={<TextSharing />} />
                    <Route
                        path="library"
                        element={
                            <PrivateRoute>
                                <TextLibrary />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="tools"
                        element={
                            <PrivateRoute>
                                <TextPurpose />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="generator"
                        element={
                            <PrivateRoute>
                                <TextGenerator />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="settings"
                        element={
                            <AdminRoute>
                                <Settings />
                            </AdminRoute>
                        }
                    />
                    <Route
                        path="users"
                        element={
                            <AdminRoute>
                                <UserData />
                            </AdminRoute>
                        }
                    />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
