import { ITextCard } from '@interfaces/api/ITextCard';
import { externalFetch, IRequestState } from './apiUtils';

export const apiGetTextCardFromFolder = async (folderId: number | null): Promise<IRequestState<ITextCard[]>> => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/folder/${folderId}/results`,
    );

    return response;
};

export const apiUpdateTextCard = async (textCardId: number | number[] | null, data: string) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/texts/${textCardId}/`,
    );

    return response;
};

export const apiMoveTextCardToFolder = async (textCardId: number | number[] | null, folderId: string | null) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/texts/?id=${textCardId}&folder_id=${folderId}`,
    );

    return response;
};

export const apiShareTextCard = async (data: string) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/sharing/results/`,
    );

    return response;
};

export const apiDeleteTextCard = async (selected: ITextCard[], i: number) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        { method: 'DELETE' },
        process.env.REACT_APP_API_URL + `/api/v1/project/results/${selected[i].id}/`,
    );

    return response;
};

export const apiFavoriteTextCard = async (textCardId: number | number[] | null, data: string) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/project/results/${textCardId}/`,
    );

    return response;
};

export const apiReportTextCard = async (textCardId: number | number[] | null) => {
    const response: IRequestState<ITextCard[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/reported/texts/${textCardId}`,
    );

    return response;
};
