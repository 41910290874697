import { useState, useEffect, useRef } from 'react';
import { Form, Button, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import { IAlgorithm } from '@interfaces/api/IAlgorithm';
import gear from '@icons/gear.svg';
import SettingsModal from './settings-modal/SettingsModal';
import 'react-toastify/dist/ReactToastify.css';
import {
    apiAdminGenerateIdeas,
    apiCreateNewTemplatePreset,
    apiGetSettingsTemplates,
    apiGetTemplateDefaultPreset,
    apiUpdateTemplatePreset,
} from '@store/api/apiSettings';
import CustomToast from '@components/common/custom-toast/CustomToast';
import { ITemplate } from '@interfaces/api/ITemplate';
import { getUser } from '@store/selectors/userSelector';
import { useSelector } from 'react-redux';

const textAreaHeight = {
    height: '564px',
    border: 'none',
    outline: 'none',
    overFlow: 'hidden',
    width: '100%',
};

const displayFlex = {
    display: 'flex',
};

const RowMargins = {
    marginRight: '0px',
    marginLeft: '0px',
};

function Settings() {
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [selected, setSelected] = useState<number>(0);
    const user = useSelector(getUser);
    const defaultColor: string = '#0D182F';
    const warningColor: string = '#D74100';
    const textareaRef = useRef<any>(null);
    const [isPopular, selectIsPopular] = useState<boolean>(false);
    const [data, setData] = useState({ prompt: '' });
    const [textLength, setTextLength] = useState(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const [maxLength, setMaxLength] = useState(0);
    const [noResult, setNoResult] = useState('');
    const [stops, setStop] = useState<string[]>(['#']);
    const [isVisible, setToVisible] = useState<boolean>(false)
    const [algorithm, setAlgorithm] = useState<IAlgorithm>({
        template_id: 0,
        template_name: '',
        engine: 'text-davinci-001',
        temperature: 1,
        max_tokens: 15,
        top_p: 1,
        presence_penalty: 0,
        frequency_penalty: 0,
        n: 1,
        stop_sequence: stops,
        max_allowed_characters: 50,
        is_popular: isPopular,
        template_information: '',
        is_visible: isVisible
    });
    const [generatedtext, setGeneratedText] = useState([]);
    const [selectedTemplate, setSelectedtemplate] = useState('--Select a template--');
    const [color, setColor] = useState(defaultColor);

    useEffect(() => {
        if (textLength > maxLength) {
            setColor(warningColor);
        } else {
            setColor(defaultColor);
        }
    }, [textLength, maxLength]);

    const handleChange = async e => {
        e.preventDefault();
        setSelected(e.target.value);
        setSelectedtemplate(e.target.options[e.target.selectedIndex].text);

        let id = e.target.value;

        const response = await apiGetTemplateDefaultPreset(id);

        if (response.error) {
            console.log(response.error);
        } else {
            const [settings] = response.data.map((gpt3, index) => {
                return {
                    template_id: gpt3.template_id,
                    template_name: gpt3.template_name,
                    engine: gpt3.engine,
                    temperature: gpt3.temperature,
                    max_tokens: gpt3.max_tokens,
                    top_p: gpt3.top_p,
                    presence_penalty: gpt3.presence_penalty,
                    frequency_penalty: gpt3.frequency_penalty,
                    n: gpt3.n,
                    stop_sequence: gpt3.stops,
                    max_allowed_characters: gpt3.max_allowed_characters,
                    is_popular: gpt3.is_popular,
                    template_information: gpt3.template_information === null ? '' : gpt3.template_information,
                };
            });

            const [data] = response.data.map((promptData, index) => {
                return {
                    prompt: promptData.prompt_fields.prompt,
                };
            });

            selectIsPopular(settings.is_popular);
            setStop(settings.stop_sequence);
            setTextLength(data.prompt.length);
            setMaxLength(settings.max_allowed_characters);

            if (response.data.length !== 0) {
                setData({ ...data });
                setAlgorithm({ ...settings });
            } else {
                setData({ prompt: '' });
                setAlgorithm({
                    template_id: selected,
                    template_name: selectedTemplate,
                    engine: 'text-davinci-001',
                    temperature: 1,
                    max_tokens: 15,
                    top_p: 1,
                    presence_penalty: 0,
                    frequency_penalty: 0,
                    n: 1,
                    stop_sequence: stops,
                    max_allowed_characters: 50,
                    is_popular: isPopular,
                    template_information: '',
                    is_visible: isPopular
                });
            }
        }
    };

    useEffect(() => {
        let isCancelled = false;

        const getTemplateDetails = async () => {
            const response = await apiGetSettingsTemplates();

            if (!isCancelled) {
                if (response.error) {
                    console.log(response.error);
                } else {
                    const templateList = response.data.map(template => {
                        return {
                            id: template.id,
                            template_name: template.template_name,
                        };
                    });
                    setTemplates(templateList);
                }
            }
        };

        getTemplateDetails();

        return () => {
            isCancelled = true;
        };
    }, [showAddModal]);

    useEffect(() => {}, [selected, algorithm]);
    const handleSave = async () => {
        const new_data = JSON.stringify({
            template_id: selected,
            template_name: selectedTemplate,
            engine: algorithm.engine,
            temperature: algorithm.temperature,
            max_tokens: algorithm.max_tokens,
            top_p: algorithm.top_p,
            frequency_penalty: algorithm.frequency_penalty,
            presence_penalty: algorithm.presence_penalty,
            n: algorithm.n,
            stops: algorithm.stop_sequence,
            inject_start_text: algorithm.inject_start_text,
            inject_restart_text: algorithm.inject_restart_text,
            prompt_fields: data.prompt,
            max_allowed_characters: algorithm.max_allowed_characters,
            is_popular: isPopular,
            template_information: algorithm.template_information,
            is_visible: algorithm.is_visible
        });

        const response = await apiCreateNewTemplatePreset(new_data);

        if (response.error) {
            console.log(response.error);
        } else {
            setGeneratedText([]);
            CustomToast({
                message: 'Preset saved successfully',
                variant: 'ALERT_INFO',
                width: '300px',
            });
        }
    };

    function handle(e) {
        e.preventDefault();
        const newData = { ...algorithm };
        newData[e.target.id] = e.target.value;
        setAlgorithm(newData);
    }

    function handlePrompt(e) {
        e.preventDefault();
        // setNewValue(e.target.value);
        setTextLength(e.target.value.length);
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const newData = { ...data };
        newData[e.target.id] = e.target.value;
        setData(newData);
        e.target.setSelectionRange(caretStart, caretEnd);
    }

    const renderTooltip = props => <Tooltip {...props}>No. of generatet text</Tooltip>;

    const handleSubmit = async () => {
        const details = JSON.stringify({
            template_id: selected,
            template_name: selectedTemplate,
            engine: algorithm.engine,
            completion: algorithm.n,
            max_token: algorithm.max_tokens,
            frequency_penalty: algorithm.frequency_penalty,
            presence_penalty: algorithm.presence_penalty,
            temperature: algorithm.temperature,
            stop: stops,
            prompt: data.prompt,
            created_by: user.email,
            top_p: algorithm.top_p,
        });

        const response = await apiAdminGenerateIdeas(details);

        if (response.error) {
            console.log('IDEAS ERROR!');
        } else {
            if (response.data.length === 0) {
                setNoResult('No Results');
            } else {
                setGeneratedText(response.data);
            }
        }
    };

    const renderTemplateList = templates.map(template => {
        return (
            <option key={template.id} value={template.id}>
                {template.template_name}{' '}
            </option>
        );
    });

    const handleUpdateAlgorithm = async () => {
        const new_data = JSON.stringify({
            template_id: selected,
            template_name: selectedTemplate,
            engine: algorithm.engine,
            completion: algorithm.n,
            max_tokens: algorithm.max_tokens,
            frequency_penalty: algorithm.frequency_penalty,
            presence_penalty: algorithm.presence_penalty,
            temperature: algorithm.temperature,
            stops: stops,
            max_allowed_characters: algorithm.max_allowed_characters,
            n: algorithm.n,
            top_p: algorithm.top_p,
            created_at: 0,
            is_popular: isPopular,
            template_information: algorithm.template_information,
            is_visible: isVisible,
        });

        const response = await apiUpdateTemplatePreset(new_data);

        if (response.error) {
            console.log(response.error);
        } else {
            CustomToast({
                message: 'Algorithm Updated!',
                variant: 'ALERT_INFO',
                width: '300px',
            });
        }
    };

    const handleSaveStop = event => {
        var code = event.keyCode || event.charCode;

        if (code === 13) {
            event.preventDefault();
            setStop([...stops, event.target.value]);
            event.target.value = '';
        }
    };

    const removeStop = (stop: string) => {
        setStop(stops.filter(k => k !== stop));
    };

    const renderStop = () => {
        return (
            <div className="keyword-container">
                {stops.map(stop => {
                    return (
                        <div className="keyword">
                            {stop}
                            <div
                                onClick={() => removeStop(stop)}
                                className="button-icon close-small-black no-hover"
                            ></div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            {showAddModal && (
                <SettingsModal
                    show={showAddModal}
                    onSave={() => setShowAddModal(false)}
                    onCancel={() => setShowAddModal(false)}
                    setShowAddModal={setShowAddModal}
                />
            )}

            <div>
                <div className="form-settings">
                    <div className="form-settings-content">
                        <Form>
                            <Form.Label className="label-settings-header">PROMPT</Form.Label>
                            <Form.Group className="mb-3" style={{ ...displayFlex }}>
                                <label
                                    style={{
                                        width: '120px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    Load Template
                                </label>
                                <Form.Control as="select" size="sm" id="template" onChange={e => handleChange(e)}>
                                    {/* <option value="none" disabled hidden>
                                        -- Select a template --
                                    </option> */}
                                      <option key={0} value={0}>
                                       --Select a template--
                                    </option>
                                    {renderTemplateList}
                                </Form.Control>
                                <Form.Group className="mb-3">
                                    <div
                                        className="font-gear-icon supplementary button-icon"
                                        style={{ height: '24px', width: '0px' }}
                                        onClick={() => setShowAddModal(true)}
                                    >
                                        <img src={gear} alt="add" className="icon-button" />
                                    </div>
                                </Form.Group>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                style={{
                                    border: ' 1px solid #e3e5e9',
                                    margin: '10px',
                                    padding: '10px',
                                    borderRadius: '0.25rem',
                                }}
                            >
                                <textarea
                                    value={data?.prompt}
                                    ref={textareaRef}
                                    onChange={e => handlePrompt(e)}
                                    id="prompt"
                                    style={{ ...textAreaHeight, resize: 'none' }}
                                >
                                    {data.prompt}
                                </textarea>
                                <p className="textLength" style={{ color: color }}>
                                    {textLength}/{maxLength}
                                </p>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Button
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => handleSubmit()}
                                    disabled={selected === 0}
                                >
                                    Submit
                                </Button>{' '}
                            </Form.Group>

                            {generatedtext.length !== 0 ? (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Generated Texts</Form.Label>
                                        {generatedtext.map((result, index) => (
                                            <Form.Control
                                                as="textarea"
                                                key={index}
                                                placeholder=""
                                                style={{ height: '50px', marginBottom: '5px' }}
                                                value={result}
                                                disabled
                                            />
                                        ))}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Button
                                            className="btn btn-primary"
                                            variant="primary"
                                            onClick={() => handleSave()}
                                        >
                                            Save as Default Preset
                                        </Button>{' '}
                                    </Form.Group>
                                </>
                            ) : (
                                <Form.Group className="mb-3">
                                    <Form.Label>{noResult}</Form.Label>
                                </Form.Group>
                            )}
                        </Form>
                    </div>

                    {/* GPT3 Settings - Algorithm and default settings */}

                    <Form className="form-settings-right-panel">
                        <Form.Label className="label-settings-header">GPT3 SETTINGS</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Label>Engine</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                onChange={e => handle(e)}
                                id="engine"
                                value={algorithm.engine}
                            />
                        </Form.Group>

                        <Row className="mb-3" style={{ ...RowMargins }}>
                            <Form.Group as={Col}>
                                <Form.Label>Completion</Form.Label>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        onChange={e => handle(e)}
                                        id="n"
                                        value={algorithm.n}
                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                    ></Form.Control>
                                </OverlayTrigger>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Max Token</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    onChange={e => handle(e)}
                                    id="max_tokens"
                                    value={algorithm.max_tokens}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3" style={{ ...RowMargins }}>
                            <Form.Group as={Col}>
                                <Form.Label>Temperature</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    onChange={e => handle(e)}
                                    id="temperature"
                                    pattern="(\d*\.)?\d+$"
                                    max={1}
                                    min={0}
                                    value={algorithm.temperature}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Top_p</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    onChange={e => handle(e)}
                                    id="top_p"
                                    pattern="(\d*\.)?\d+$"
                                    max={1}
                                    min={0}
                                    value={algorithm.top_p}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3" style={{ ...RowMargins }}>
                            <Form.Group as={Col}>
                                <Form.Label>Frequency Penalty</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    onChange={e => handle(e)}
                                    id="frequency_penalty"
                                    pattern="(\d*\.)?\d+$"
                                    max={2}
                                    min={0}
                                    value={algorithm.frequency_penalty}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Presence Penalty</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    onChange={e => handle(e)}
                                    id="presence_penalty"
                                    pattern="(\d*\.)?\d+$"
                                    max={2}
                                    min={0}
                                    value={algorithm.presence_penalty}
                                />
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Stop</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='Input text and press "Enter"'
                                onChange={e => handle(e)}
                                onKeyPress={event => handleSaveStop(event)}
                                id="stop_sequence"
                            />
                            {stops && renderStop()}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Max Allowed Characters</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder=""
                                onChange={e => handle(e)}
                                pattern="(\d*\.)?\d+$"
                                max={5000}
                                min={0}
                                id="max_allowed_characters"
                                value={algorithm.max_allowed_characters}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Text Purpose Information</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Input template information"
                                onChange={handle}
                                id="template_information"
                                value={algorithm.template_information}
                            />
                        </Form.Group>
                        <Form.Group className="preset-form-check">
                            <Form.Check
                                type="checkbox"
                                label="Popular"
                                id="is_popular"
                                checked={isPopular}
                                onChange={() => selectIsPopular(!isPopular)}
                            />
                        </Form.Group>
                        <Form.Group className="preset-form-check">
                            <Form.Check
                                type="checkbox"
                                label="Show to user"
                                id="is_visible"
                                checked={isVisible}
                                onChange={() => setToVisible(!isVisible)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Button onClick={() => handleUpdateAlgorithm()} disabled={selected !== 0 ? false : true}>
                                Update
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Settings;
