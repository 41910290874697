import { IAction } from '@interfaces/api/IAction';
import { IProject } from '@interfaces/api/IProject';
import { ProjectActions } from '@store/actions/actions';

export const projectReducer = (state: IProject[] = [], action: IAction) => {
    switch (action.type) {
        case ProjectActions.SET_PROJECT: {
            return [...action.payload.data];
        }
        case ProjectActions.SELECT_PROJECT: {
            const { id } = action.payload.data;

            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, selected: true } : { ...s, selected: (s.selected = false) };
                }),
            ];
        }
        case ProjectActions.DESELECT_PROJECT: {
            return [
                ...state.map(s => {
                    return { ...s, selected: false };
                }),
            ];
        }
        case ProjectActions.ADD_PROJECT: {
            const updatedProjectList = [...state];

            updatedProjectList.push(action.payload.data);

            return updatedProjectList;
        }
        case ProjectActions.UPDATE_PROJECT: {
            const { id, results } = action.payload;
            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, results } : { ...s };
                }),
            ];
        }
        case ProjectActions.DELETE_PROJECT: {
            const { id } = action.payload;

            const projects = state.filter(s => s.id !== id);

            return projects || [];
        }
        default:
            return state;
    }
};
export default projectReducer;
