import { apiUpdateToken } from '@store/api/apiSignUp';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isLogin, isAdmin } from './utils';

const AdminRoute = ({ children }) => {
    useEffect(() => {
        let thirtyMinutes: number = 1000 * 60 * 30;
        let interval = setInterval(() => {
            apiUpdateToken();
        }, thirtyMinutes);
        return () => clearInterval(interval);
    }, []);

    return isLogin() && isAdmin() ? children : <Navigate to="/login" />;
};

export default AdminRoute;
