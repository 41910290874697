import { ILogin, IUser } from '@interfaces/api/ILogin';
import { externalFetch, IRequestState } from './apiUtils';

export const apiAccountSubscriptionUpdate = async (id: number | null, data: string): Promise<IRequestState<IUser>> => {
    const response: IRequestState<IUser> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/account/${id}/subscription`,
    );

    return response;
};

export const apiSubscription = async (id: number | undefined, data: string): Promise<IRequestState<ILogin>> => {
    const response: IRequestState<ILogin> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/user/${id}/subscription`,
    );

    return response;
};

export const apiGetUserTotalTokens = async (user: string | null): Promise<IRequestState<IUser>> => {
    const response: IRequestState<IUser> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/user/email/${user}`,
    );

    return response;
};

// export const apiUpdateToken = async (
//   id: number | null,
//   data: string
// ): Promise<IRequestState<ILogin>> => {
//   const response: IRequestState<ILogin> = await externalFetch(
//     { method: "PUT", data },
//     process.env.REACT_APP_API_URL + `/api/v1/account/${id}/subscription`
//   );

//   return response;
// };

export const apiGetUsers = async (page: number): Promise<IRequestState<IUser>> => {
    const response: IRequestState<IUser> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/user?page=${page}`,
    );

    return response;
};
