import { IAction } from '@interfaces/api/IAction';
import { ILoader } from '@interfaces/api/ILoader';
import { LoaderActions } from '@store/actions/actions';

const loaderReducer = (state: ILoader = { is_loading: false }, action: IAction) => {
    switch (action.type) {
        case LoaderActions.SET_LOADER: {
            return {
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
export default loaderReducer;
