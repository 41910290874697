import { externalFetch, IRequestState } from './apiUtils';

export const apiSendRequest = async (data: string): Promise<IRequestState<any>> => {
    const response: IRequestState<any> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/request/function/`,
    );

    return response;
};
