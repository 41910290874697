import { Box, Card } from '@mui/material';
import IconCollection from '@components/icon-collection/IconCollection';
import { P2 } from '@components/common/typography/Typography';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import { setColor } from '@utils/colorUtils';

interface ITemplateCardProps {
    label?: string;
    info?: string;
    isSelected?: boolean | undefined;
    onClick: () => void;
}

const TemplateCard: React.FC<ITemplateCardProps> = ({
    label = '',
    info = '',
    isSelected = false,
    onClick = () => {},
}) => {
    return (
        <Box sx={{ display: 'inline-block' }}>
            <CustomTooltip title={`${info}`} placement={'top'}>
                <Card
                    sx={{
                        position: 'relative',
                        width: 140,
                        height: 140,
                        p: 4,
                        m: 1,
                        cursor: 'pointer',
                        boxSizing: 'border-box',
                        border: isSelected ? `1px solid ${setColor('hl-blue')}` : '1px solid transparent',

                        '&:hover': {
                            border: isSelected
                                ? `1px solid ${setColor('hl-blue')}`
                                : `1px solid ${setColor('hl-light-purple')}`,
                        },
                    }}
                    onClick={onClick}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <IconCollection icon={'LIGHT_BULB'} size={'xl'} sx={{ mb: 4 }} />
                        <P2 sx={{ fontWeight: 700, textAlign: 'center' }}>{label}</P2>
                    </Box>
                </Card>
            </CustomTooltip>
        </Box>
    );
};

export default TemplateCard;
