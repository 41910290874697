import { initialUser } from '@constants/initial';
import { IUser } from '@interfaces/api/ILogin';
import { IAction } from '@interfaces/api/IAction';
import { LoginActions } from '@store/actions/actions';

const userReducer = (state: IUser = initialUser, action: IAction) => {
    switch (action.type) {
        case LoginActions.SET_USER: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
export default userReducer;
