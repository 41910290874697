import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import './login.module.scss';
import './assets/login.css';
import madgicxIcon from './assets/images/image01.svg';
import { LoginActions, ProjectActions, PromptActions, TextCardActions } from '@store/actions/actions';
import { userSchema } from '@validations/userValidation';
import jwt_decode from 'jwt-decode';
import { apiGetUserInfo, apiLoginUser } from '@store/api/apiSignUp';
import { IAuthJWT } from '@interfaces/api/IAuthJWT';
import { persistor } from '@store/root';

const Login = () => {
    const [authenticationMessage, setAuthenticationMessage] = useState('');
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let cancelFetchingSettings = false;
        setTimeout(() => persistor.purge(), 200)
        return () => {
            cancelFetchingSettings = true; // eslint-disable-line react-hooks/exhaustive-deps
        }
    }, []);
    
    const loginUser = async event => {
        event.preventDefault();

        localStorage.clear();
        await persistor.purge();

        let formData = {
            email: event.target[0].value,
            password: event.target[1].value,
        };

        const data = JSON.stringify({
            email: event.target[0].value,
            password: event.target[1].value,
        });

        const isValid = await userSchema.isValid(formData);
        if (isValid) {
            const response = await apiLoginUser(data);

            if (response.error) {
                console.log(response.error);
            } else {
                if (!response.data.detail) {       

                    // Save jwt access token in locaStorage
                    localStorage.setItem('authTokens', JSON.stringify(response.data));

                    // Descrypt jwt access token
                    const user = jwt_decode<IAuthJWT>(response.data.access);

                    // Use decoded user_id to get user info from API
                    const response_info = await apiGetUserInfo(user.user_id);

                    if (response.error) {
                        console.log(response.error);
                    } else {
                        if (!response_info.data.detail) {
                            if (response_info.data.is_banned) {
                                setErrorMessage("You're account has been suspended. Please contact administrator");
                            } else {
                                setAuthenticationMessage(response_info.data.detail);
                                localStorage.setItem('isAdmin', JSON.stringify(response_info.data.is_superuser));

                                dispatch({ type: LoginActions.SET_USER, payload: response_info.data });
                                dispatch({
                                    type: ProjectActions.DESELECT_PROJECT,
                                    payload: {},
                                });

                                dispatch({
                                    type: TextCardActions.CLEAR_SELECTED,
                                    payload: {},
                                });

                                dispatch({
                                    type: TextCardActions.CLEAR_CARDS,
                                    payload: {},
                                });

                                dispatch({
                                    type: PromptActions.CLEAR_PROMPT,
                                    payload: {},
                                });
                                navigate('/generator/');
                            }
                        } else {
                            setAuthenticationMessage(response_info.data.detail);
                        }
                    }
                } else {
                    setAuthenticationMessage(response.data.detail);
                }
            }
        }
    };

    return (
        <div>
            <title>Sign In: Madgicx AI Copywriter (copy)</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
            <meta name="description" content="Sign In: Madgicx AI Copywriter sketch" />
            <meta property="og:site_name" content="Sign In: Madgicx AI Copywriter (copy)" />
            <meta property="og:title" content="Sign In: Madgicx AI Copywriter (copy)" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Sign In: Madgicx AI Copywriter sketch" />
            <link
                href="https://fonts.googleapis.com/css?family=Montserrat:400,400italic,500,500italic,600,600italic"
                rel="stylesheet"
                type="text/css"
            />
            <link rel="stylesheet" href="assets/main.css" />
            <div id="wrapper">
                <div id="main">
                    <div className="inner">
                        <div id="container06" className="container default" style={{ height: '80vh' }}>
                            <div className="wrapper">
                                <div className="inner" style={{ transform: 'scale(1.2, 1.2)' }}>
                                    <h3 id="text07">built by</h3>
                                    <div id="image01" className="image">
                                        <a
                                            href="https://madgicx.com?utm_source=ai-copywriter_landing"
                                            className="frame"
                                        >
                                            <img src={madgicxIcon} alt="" />
                                        </a>
                                    </div>
                                    <hr id="divider02" />
                                    <h1 id="text03">Great to see you again!</h1>
                                    <form encType="multipart/form-data" id="form01" method="post" onSubmit={loginUser}>
                                        <div className="inner">
                                            <div className="field">
                                                <input
                                                    type="email"
                                                    name="enter-your-email"
                                                    id="form01-enter-your-email"
                                                    placeholder="Enter your email"
                                                    maxLength={128}
                                                    required
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="field">
                                                <input
                                                    type="password"
                                                    name="enter-your-password"
                                                    id="form01-enter-your-password"
                                                    placeholder="Enter your password"
                                                    minLength={8}
                                                    required
                                                />
                                            </div>
                                            {authenticationMessage !== '' && (
                                                <div style={{ color: 'red' }}>{authenticationMessage}</div>
                                            )}
                                            {errorMessage !== '' && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                            <div className="actions">
                                                <button type="submit">Sign in</button>
                                            </div>
                                        </div>
                                    </form>
                                    <h2 id="text03">
                                        No account yet? <a href={'/signup'}>Sign Up</a>
                                    </h2>
                                    <hr id="divider01" />
                                </div>
                            </div>
                        </div>
                        <div id="container04" className="container default full screen" style={{ height: '2.5vh' }}>
                            <div className="wrapper">
                                <div className="inner">
                                    <p id="text01">© 2021 All rights reserved madgicx.com</p>
                                </div>
                            </div>
                        </div>
                        <div id="container01" className="container columns full screen" style={{ height: '2.5vh' }}>
                            <div className="wrapper">
                                <div className="inner">
                                    <div>
                                        <p id="text05">
                                            <a href="https://madgicx.com/terms-conditions/">Terms of service</a>
                                        </p>
                                    </div>
                                    <div>
                                        <p id="text06">
                                            <a href="https://madgicx.com/privacy-policy/">Privacy policy</a>
                                        </p>
                                    </div>
                                    <div>
                                        <p id="text02">
                                            <a href="https://madgicx.com/cookie-policy/">Cookie policy</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
