import { ITemplate } from '@interfaces/api/ITemplate';
import { externalFetch, IRequestState } from './apiUtils';

export const apiGetAllTemplates = async (): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/templates/`,
    );

    return response;
};

export const apiGetPopularTemplates = async (): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/templates/popular/`,
    );

    return response;
};

export const apiGetOtherTemplates = async (): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/templates/others/`,
    );

    return response;
};

export const apiGetFilteredTemplates = async (categoriId: number | undefined): Promise<IRequestState<ITemplate[]>> => {
    const response: IRequestState<ITemplate[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/templates/detail/${categoriId}`,
    );

    return response;
};
