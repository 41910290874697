import { SxProps, Typography } from '@mui/material';
import { colorOptions, setColor } from '@utils/colorUtils';

interface ITypographyProps {
    children?: React.ReactNode;
    color?: colorOptions;
    sx?: SxProps;
}

export const H1: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="h1"
            noWrap
            sx={{
                fontSize: { xs: 22, md: 22, lg: 24 },
                lineHeight: 1.5,
                color: setColor(color),
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const H2: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="h2"
            sx={{
                fontSize: { xs: 20, md: 20, lg: 22 },
                lineHeight: 1.5,
                color: setColor(color),
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const H3: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="h3"
            sx={{
                fontSize: { xs: 18, md: 18, lg: 20 },
                lineHeight: 1.5,
                color: setColor(color),
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const H4: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="h4"
            sx={{
                fontSize: { xs: 16, md: 16, lg: 16 },
                lineHeight: 1.5,
                color: setColor(color),
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const P1: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="body1"
            sx={{
                fontSize: { xs: 14, md: 14, lg: 16 },
                lineHeight: 1.5,
                color: setColor(color),
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const P2: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="body2"
            sx={{
                color: setColor(color),
                fontSize: { xs: 14, md: 14, lg: 16 },
                // fontSize: { xs: 12, md: 12, lg: 14 },
                lineHeight: 1.5,
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const P3: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="body2"
            sx={{
                color: setColor(color),
                // fontSize: { xs: 10, md: 10, lg: 12 },
                fontSize: { xs: 10, md: 10, lg: 12 },
                lineHeight: 1.5,
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export const Caption: React.FC<ITypographyProps> = ({ children, color, sx }) => {
    return (
        <Typography
            variant="caption"
            sx={{
                color: setColor(color),
                fontSize: { xs: 10, md: 10, lg: 10 },
                lineHeight: 1.5,
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};
