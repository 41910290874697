import IconCollection from '@components/icon-collection/IconCollection';
import { Button, SxProps } from '@mui/material';

type ButtonType = 'contained' | 'outlined' | 'borderless' | 'pill';

interface ICustomButtonProps {
    /**
     * optional label of the button
     */
    label?: React.ReactNode | string;
    /**
     * optional type of button, default is contained
     */
    variant?: ButtonType;
    /**
     * optional is the button disabled
     */
    disabled?: boolean;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * custom style
     */
    sx?: SxProps;
}

/**
 * custom style of button depending on the variant
 */
const buttonStyle: SxProps<any> = (variant: ButtonType) => {
    switch (variant) {
        case 'outlined':
            return { color: '#006DF0', borderColor: '#006DF0' };
        case 'borderless':
            return {
                border: 0,
                color: '#0D182F',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 3px 4px rgba(0,0,0,0.1)',
                '&:hover': {
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 3px 4px rgba(0,0,0,0.3)',
                },
            };
        case 'pill':
            return {
                position: 'relative',
                width: 120,
                borderRadius: 30,
                padding: '4px 12px',
                color: '#FFFFFF',
                backgroundColor: '#A0AABE',
                '& .icon': {
                    position: 'absolute',
                    right: 12,
                    opacity: 0,
                },
                '&:hover': {
                    backgroundColor: '#50586A',
                    '& .icon': {
                        opacity: 1,
                    },
                },
            };
        case 'contained':
        default:
            return {
                backgroundColor: '#006DF0',
                '&:hover': { backgroundColor: '#0065DE' },
                '&.Mui-disabled': {
                    backgroundColor: '#DDDDDD',
                },
            };
    }
};

const CustomButton: React.FC<ICustomButtonProps> = ({
    label = 'MadgicxIQ Custom Button',
    variant = 'contained',
    onClick = () => {},
    disabled = false,
    sx = {},
}) => {
    const defaultVariant =
        variant === 'contained' || variant === 'borderless' || variant === 'pill' ? 'contained' : variant;

    return (
        <Button
            variant={defaultVariant}
            onClick={onClick}
            disabled={disabled}
            sx={{
                /*
          default style for all variants
        */
                minWidth: 0,
                whiteSpace: 'nowrap',
                backgroundColor: 'transparent',
                /*
          variant custom styling
        */
                ...buttonStyle(variant),
                ...sx,
            }}
        >
            {label}

            {variant === 'pill' && <IconCollection icon="CLOSE_SMALL" size="xs" />}
        </Button>
    );
};

export default CustomButton;
