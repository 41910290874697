import { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    SxProps,
    TextField,
} from '@mui/material';
import { Caption, H4, P2 } from '@components/common/typography/Typography';
import { IModalProps } from '@components/custom-modals/IModalProps';
import CustomInput from '@components/common/custom-input/CustomInput';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { AlignCenter, FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';
import { IFolder } from '@interfaces/api/IFolder';
import { WARNING_COLOR } from '@constants/constants';

const modalStyle: SxProps = {
    '& .MuiPaper-root': {
        width: '500px',
        minWidth: '280px',
        maxWidth: '500px',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};

const SaveTextCardModal: React.FC<IModalProps> = ({
    folderName = 'Default Folder Name',
    header = 'Add Modal Component',
    selections = [],
    loading = false,
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = { label: 'Confirm', onConfirm: () => {} },
}) => {
    const [name, setName] = useState<string>(folderName);

    const folderExist = selections.find(selection => selection.folder_name.toLowerCase() === name.toLowerCase());

    const [folder, setFolder] = useState<string>(!!folderExist ? folderExist.id : 'none');

    const [focus, setFocus] = useState<boolean>(false);

    const [saveAsOption, setSaveAsOption] = useState<string>(!!folderExist ? 'existing' : 'new');

    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>

            <DialogContent>
                <FormControl sx={{ width: '100%' }}>
                    <RadioGroup
                        defaultValue={saveAsOption}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSaveAsOption((e.target as HTMLInputElement).value);
                        }}
                    >
                        <AlignCenter sx={{ mb: 4 }}>
                            <FormControlLabel
                                value="existing"
                                control={<Radio />}
                                label={<P2>Select Folder</P2>}
                                sx={{ width: '50%', m: 0 }}
                            />
                            <TextField
                                value={folder}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFolder(e.target.value);
                                }}
                                onFocus={() => {
                                    setFocus(true);
                                }}
                                onBlur={() => {
                                    setFocus(false);
                                }}
                                select
                                label=""
                                InputLabelProps={{ shrink: false }}
                                inputProps={{ padding: 0 }}
                                sx={{
                                    textAlign: 'left',
                                    width: '100%',
                                    border: '1px solid #E3E5E9',
                                    borderRadius: 1,
                                    borderColor: focus ? '#006DF0' : '#E3E5E9',

                                    '& .MuiSelect-select': {
                                        padding: 1,
                                        border: 0,
                                    },

                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: '0 !important',
                                    },
                                }}
                                disabled={saveAsOption === 'new'}
                            >
                                <MenuItem value={'none'} disabled>
                                    <P2>Select a folder</P2>
                                </MenuItem>
                                {selections
                                    ?.filter(
                                        selection =>
                                            selection.folder_name !== 'Favorites' &&
                                            selection.folder_name !== 'History',
                                    )
                                    .map((selection: IFolder, idx) => (
                                        <MenuItem key={idx} value={selection.id}>
                                            <P2>{selection.folder_name}</P2>
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </AlignCenter>

                        <AlignCenter>
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label={<P2>Create New</P2>}
                                sx={{ width: '50%', m: 0 }}
                            />
                            <CustomInput
                                value={name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    e.preventDefault();

                                    setName(e.target.value);
                                }}
                                disabled={saveAsOption === 'existing'}
                                onFocus={saveAsOption === 'new'}
                            />
                        </AlignCenter>
                        {!!folderExist &&
                        folderExist.folder_name.toLowerCase() === name.toLowerCase() &&
                        saveAsOption === 'new' ? (
                            <Caption sx={{ color: WARNING_COLOR, p: 3 }}>
                                Choose a different name. Folder name already exists!
                            </Caption>
                        ) : null}
                    </RadioGroup>
                </FormControl>
            </DialogContent>

            <DialogActions sx={{ padding: 2 }}>
                <BorderlessButton
                    label={<P2 color={'fc-black'}>{cancelButton?.label}</P2>}
                    onClick={() => cancelButton?.onCancel()}
                />
                <DefaultButton
                    label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                    onClick={() => {
                        confirmButton?.onConfirm(folder, name, saveAsOption);
                    }}
                    loading={loading}
                    disabled={
                        (!!folderExist && saveAsOption === 'existing' && folder === 'none') ||
                        (!folderExist && saveAsOption === 'new' && name.trim() === '') ||
                        (!!folderExist && saveAsOption === 'new' && name === folderName)
                            ? true
                            : false
                    }
                />
            </DialogActions>
        </Dialog>
    );
};

export default SaveTextCardModal;
