import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { ICategory } from '@interfaces/api/ICategory';
import { apiGetCategories } from '@store/api/apiSettings';

function MoveTemplate(props) {
    const [categoryList, newCategoryList] = useState<ICategory[]>([]);
    // const [selected, setSelected] = useState<number>(0);
    // const [selectedCategory, setSelectedCategory] = useState("");

    const getCategories = async () => {
        const response = await apiGetCategories();
        if (response.error) {
            console.log(response.error);
        } else {
            response.data.map(category => {
                return {
                    id: category.id,
                    category_name: category.category_name,
                };
            });
            newCategoryList(response.data);
        }
    };

    useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            getCategories();
        }

        return () => {
            isCancelled = true;
        };
    }, []);

    const renderCategoryList = categoryList.map(category => {
        return (
            <option key={category.id} value={category.id}>
                {category.category_name}{' '}
            </option>
        );
    });

    const handleChange = e => {
        // setSelected(e.target.value);
        // setSelectedCategory(e.target.options[e.target.selectedIndex].text);
        props.setNewCategory({
            new_category_id: e.target.value,
            new_category_name: e.target.options[e.target.selectedIndex].text,
        });
    };

    return (
        <Modal show={props.show} animation={false} centered className="generic dialog">
            <Modal.Header>
                <Modal.Title>Move template to another category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Control size="sm" as="select" onChange={e => handleChange(e)} defaultValue="none">
                        <option value="none" disabled>
                            --Select a category--
                        </option>
                        {renderCategoryList}
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" className="box-shadow" size="sm" onClick={props.onCancelData}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={() => props.onSave()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default MoveTemplate;

// function dispatch(arg0: { type: any; payload: { data: any } }) {
//   throw new Error("Function not implemented.");
// }
