// import CustomTextArea from "../../components/CustomTextArea";

function ShareRightSidePanel(props) {
    return (
        <div>
            <p className="headline-output">{props.results.template_name}</p>
            <div className="shared-card-text">
                {/* <CustomTextArea
          value={props.results.results}
          maxString={props.results.max_allowed_characters || 100} 
          disabled={true}
          valueChanges={(props: string) => {}}
        /> */}
            </div>
        </div>
    );
}
export default ShareRightSidePanel;
