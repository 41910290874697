import { P2 } from '@components/common/typography/Typography';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';
import { Box, SxProps } from '@mui/material';
import thinkingPerson from '@icons/thinking-person.svg';

const blank: SxProps<any> = {
    width: 400,
    height: 150,
};

const imageSize = {
    width: '300px',
    height: '300px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    caretColor: 'transparent',
};

const MakeMadgicx: React.FC<any> = (props: {
    body?: string;
    onClick?: () => void;
    loading: false;
    disabled: false;
}) => {
    const { body } = props;
    return (
        <FlexCenter sx={{ flexDirection: 'column' }}>
            <FlexCenter sx={{ ...blank }}>
                <img src={thinkingPerson} alt="thinking-person" style={{ ...imageSize }} />
            </FlexCenter>

            <Box textAlign={'center'} marginTop={'110px'}>
                <P2 sx={{ mb: 4 }}>{body}</P2>
            </Box>
        </FlexCenter>
    );
};

export default MakeMadgicx;
