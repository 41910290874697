import { useEffect, useState } from 'react';
// import { ITemplates } from "@interfaces/ITemplates";
import trash from '@icons/trash.svg';
import move from '@icons/move.svg';
import editSmall from '@icons/edit-small.svg';
import AddItem from './AddItem';
import ClickAwayListener from 'react-click-away-listener';
import { apiGetTemplateDetail, apiUpdateTextPurposeCategory } from '@store/api/apiSettings';
import { ITemplate } from '@interfaces/api/ITemplate';

const divStyle = {
    marginRight: '5px',
};

const textStyle = {
    width: '300px',
    height: '20px',
    overflow: 'hidden',
    outline: 'none',
};

const TemplateList = props => {
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const { categoryId, categoryName } = props;
    const [editValue, setEditValue] = useState<boolean>(false);
    const [editableId, setEditableId] = useState<number>(0);
    const [newValue, setNewValue] = useState('');

    const addTemplate = template_name =>
        setTemplates([...templates, { template_name: template_name, selected: false }]);

    useEffect(() => {
        let isCancelled = false;

        const getTemplateDetails = async () => {
            const response = await apiGetTemplateDetail(categoryId);

            if (!isCancelled) {
                if (response.error) {
                    console.log(response.error);
                } else {
                    const templateList = response.data.map(template => {
                        return {
                            id: template.id,
                            template_name: template.template_name,
                        };
                    });
                    setTemplates(templateList);
                }
            }
        };

        getTemplateDetails();

        return () => {
            isCancelled = true;
        };
    }, [categoryId, editValue]);

    const toggleTask = index => {
        const newTemplates = [...templates];
        setTemplates(newTemplates);
    };

    const removeTemplate = async (index, id, name) => {
        const newTemplates = [...templates];
        newTemplates.splice(index, 1);
        setTemplates(newTemplates);

        const data = JSON.stringify({
            is_deleted: true,
            template_name: name,
            category_id: categoryId,
            category_name: categoryName,
        });

        const response = await apiUpdateTextPurposeCategory(id, data);

        if (response.error) {
            console.log(response.error);
        } else {
            //  do nothing
        }
    };

    const editText = (index, id, name) => {
        setEditValue(true);
        setEditableId(id);
        setNewValue(name);
    };

    const handleOnKeyPress = async e => {
        var code = e.keyCode || e.charCode;
        if (code === 13) {
            e.preventDefault();

            const data = JSON.stringify({
                template_name: newValue,
                category_id: categoryId,
                category_name: categoryName,
                template_short_name: newValue,
            });

            const response = await apiUpdateTextPurposeCategory(editableId, data);

            if (response.error) {
                console.log(response.error);
            } else {
                setEditValue(false);
            }
        }
    };

    const handleOnChange = e => {
        e.preventDefault();
        setNewValue(e.target.value);
    };

    const moveToCategory = (template_id, name) => {
        props.setMoveData({ template_id: template_id, template_name: name });
        props.setMoveCategory(true);
    };

    return (
        <>
            <div>
                {templates.map((template, index) => (
                    <div className="settings" key={index}>
                        {editValue && editableId === template.id ? (
                            <ClickAwayListener onClickAway={() => setEditValue(false)}>
                                <textarea
                                    style={{ ...textStyle, resize: 'none' }}
                                    onChange={event => handleOnChange(event)}
                                    onKeyPress={event => handleOnKeyPress(event)}
                                    value={newValue}
                                >
                                    {newValue}
                                </textarea>
                            </ClickAwayListener>
                        ) : (
                            <>
                                <span onClick={() => toggleTask(index)} key={index}>
                                    {template.template_name}
                                </span>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <div
                                        style={{ ...divStyle }}
                                        onClick={() => editText(index, template.id, template.template_name)}
                                    >
                                        <img src={editSmall} alt="img-edit-small" />
                                    </div>
                                    <div
                                        style={{ ...divStyle }}
                                        onClick={() => moveToCategory(template.id, template.template_name)}
                                    >
                                        <img src={move} alt="img-move" />
                                    </div>
                                    <div onClick={() => removeTemplate(index, template.id, template.template_name)}>
                                        <img src={trash} alt="img-trash" />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}

                <AddItem addItem={addTemplate} dataId={categoryId} data={categoryName} type="template" />
            </div>
        </>
    );
};

export default TemplateList;
