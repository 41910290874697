import { Box, Card } from '@mui/material';
import CustomTextArea from '@components/common/custom-textarea/CustomTextArea';
import { iconCollections } from '@components/icon-collection/IconCollection';
import InputOverlay from '@components/common/input-overlay/InputOverlay';
import { setColor } from '@utils/colorUtils';
import RoundButton from '@components/common/custom-button/round-button/RoundButton';
import { SpaceBetween } from '@components/layout/app-flexbox/AppFlexBox';
import { ITextCard } from '@interfaces/api/ITextCard';
import InvisibleButton from '@components/common/custom-button/invisible-button/InvisibleButton';
import { useState } from 'react';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
interface IPresetCardProps {
    textCard?: ITextCard;
    isSelected?: boolean;
    onClick: () => void;
    onReport?: () => void;
    onSave?: () => void;
    onToggleFavorite?: () => void;
    disabled?: boolean;
}

const PresetCard: React.FC<IPresetCardProps> = ({
    textCard,
    isSelected,
    onClick = () => {},
    onReport = () => {},
    onSave = () => {},
    onToggleFavorite = () => {},
    disabled = false,
}) => {
    const [showInputOverlay, setShowInputOverlay] = useState<boolean>(false);

    const [value, setValue] = useState<string | undefined>(undefined);

    return (
        <Card
            sx={{
                display: 'inline-block',
                position: 'relative',
                width: '100%',
                height: '100%',
                p: 4,
                mb: 2,
                boxSizing: 'border-box',
                border: isSelected ? `1px solid ${setColor('hl-blue')}` : '1px solid transparent',

                '&:hover': {
                    border: isSelected
                        ? `1px solid ${setColor('hl-blue')}`
                        : `1px solid ${setColor('hl-light-purple')}`,
                },
            }}
        >
            <SpaceBetween sx={{ mb: 4 }}>
                <Box />
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <CustomTooltip title={textCard?.is_reported ? 'Cancel Report' : 'Report'} placement={'top'}>
                        <RoundButton
                            label={
                                <img
                                    src={iconCollections.WARNING.src}
                                    alt={'icon-warning'}
                                    style={{ width: 20, height: 20 }}
                                />
                            }
                            sx={{ zIndex: 2 }}
                            onClick={onReport}
                        />
                    </CustomTooltip>

                    <CustomTooltip title={'Save Result'} placement={'top'}>
                        <RoundButton
                            label={
                                <img
                                    src={iconCollections.SAVE.src}
                                    alt={'icon-save'}
                                    style={{ width: 12, height: 12 }}
                                />
                            }
                            sx={{ zIndex: 2 }}
                            onClick={onSave}
                        />
                    </CustomTooltip>

                    <CustomTooltip title={textCard?.is_starred ? 'Unfavorite' : 'Favorite'} placement={'top'}>
                        <RoundButton
                            label={
                                <img
                                    src={
                                        textCard?.is_starred
                                            ? iconCollections.FAVOURITES.src
                                            : iconCollections.STAR_OUTLINED.src
                                    }
                                    alt={'icon-toggle-favorite'}
                                    style={{ width: 14, height: 14 }}
                                />
                            }
                            sx={{ zIndex: 2 }}
                            onClick={onToggleFavorite}
                        />
                    </CustomTooltip>
                </Box>
            </SpaceBetween>

            <InputOverlay showOverlay={showInputOverlay} setShowOverlay={setShowInputOverlay}>
                <CustomTextArea
                    value={value ?? textCard?.results}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.preventDefault();

                        setValue(e.target.value);
                    }}
                    maxLength={300}
                    disabled={disabled}
                    sx={{ zIndex: 2 }}
                    onCopy={() => {
                        navigator.clipboard.writeText(textCard?.results);
                        setShowInputOverlay(true);
                    }}
                />
            </InputOverlay>

            <InvisibleButton onClick={onClick} sx={{ bgcolor: '#000' }} />
        </Card>
    );
};

export default PresetCard;
