import IconCollection from '@components/icon-collection/IconCollection';
import { Button, SxProps } from '@mui/material';

interface ICustomButtonProps {
    /**
     * optional label of the button
     */
    label?: React.ReactNode | string;
    /**
     * optional is the button disabled
     */
    disabled?: boolean;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * custom style
     */
    sx?: SxProps;
}

const PillButton: React.FC<ICustomButtonProps> = ({
    label = 'MadgicxIQ Pill Button',
    onClick = () => {},
    disabled = false,
    sx = {},
}) => {
    return (
        <Button
            variant={'contained'}
            onClick={onClick}
            disabled={disabled}
            sx={{
                position: 'relative',
                width: 120,
                minWidth: 0,
                border: 0,
                borderRadius: 30,
                padding: '4px 12px',
                color: '#FFFFFF',
                backgroundColor: '#A0AABE',
                '& .icon': {
                    position: 'absolute',
                    right: 12,
                    opacity: 0,
                },
                '&:hover': {
                    backgroundColor: '#50586A',
                    '& .icon': {
                        opacity: 1,
                    },
                },

                ...sx,
            }}
        >
            {label}
            <IconCollection icon="CLOSE_SMALL" size="xs" />
        </Button>
    );
};

export default PillButton;
