import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectAllTextCard, selectTextCard } from '@store/selectors/textCardSelector';
import { selectAllFolder } from '@store/selectors/folderSelector';
import { apiMoveTextCardToFolder } from '@store/api/apiTextCards';
import CustomToast from '@components/common/custom-toast/CustomToast';
import { ITEMS_PER_PAGE, SKELECTON_COUNT } from '@constants/constants';
import { TextCardActions, FolderActions } from '@store/actions/actions';
import PresetCard from '@components/custom-cards/preset-card/PresetCard';
import PresetCardSkeleton from '@components/common/skeleton/preset-card-skeleton/PresetCardSkeleton';
import { apiFavoritePresetCard, apiReportPresetCard } from '@store/api/apiPresetCards';
import { getUser } from '@store/selectors/userSelector';
import SaveTextCardModal from '@components/custom-modals/save-textcard-modal/SaveTextCardModal';
import { apiAddFolder } from '@store/api/apiFolders';
import LinkButton from '@components/common/custom-button/link-button/LinkButton';
import { setColor } from '@utils/colorUtils';

function PresetCardContainer(props: { page: number; loading: boolean; folderName: string }) {
    const { page, loading } = props;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const user = useSelector(getUser);

    const allFolders = useSelector(selectAllFolder);

    const allTextCard = useSelector(selectAllTextCard);
    const selectedTextCards = useSelector(selectTextCard);

    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);

    return (
        <>
            <Box sx={{ p: 5, columnCount: { xs: 1, md: 2, lg: 3 } }}>
                {loading && [...Array(SKELECTON_COUNT)].map((e, i) => <PresetCardSkeleton key={i} />)}
                {allTextCard.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE).map((textCard, i) => {
                    return (
                        <PresetCard
                            key={`text-card-wrapper-${i}`}
                            textCard={textCard}
                            isSelected={!!textCard.id && selectedTextCards.some(s => s.id === textCard?.id)}
                            onClick={() => {
                                dispatch({
                                    type: TextCardActions.SELECT_CARD,
                                    payload: { data: textCard },
                                });
                            }}
                            onSave={() => {
                                // Clears all of the selected.
                                dispatch({
                                    type: TextCardActions.CLEAR_SELECTED,
                                });
                                // Selects the last selected card.
                                dispatch({
                                    type: TextCardActions.SELECT_CARD,
                                    payload: { data: textCard },
                                });

                                setShowSaveModal(true);
                            }}
                            onReport={async () => {
                                const data = JSON.stringify({
                                    is_reported: !textCard.is_reported,
                                });

                                const response = await apiReportPresetCard(textCard.id, data);

                                if (response.error) {
                                    console.log(response.error);
                                } else {
                                    dispatch({
                                        type: TextCardActions.REPORTED,
                                        payload: { data: response.data },
                                    });

                                    let msg = '';
                                    if (response.data.is_reported) {
                                        msg = 'Report sent!';
                                    } else {
                                        msg = 'Report cancelled!';
                                    }

                                    CustomToast({
                                        message: msg,
                                        variant: response.data.is_reported ? 'ALERT_WARNING' : 'ALERT_INFO',
                                        width: '300px',
                                    });
                                }
                            }}
                            onToggleFavorite={async () => {
                                const data = JSON.stringify({
                                    is_starred: !textCard.is_starred,
                                    user: user.email,
                                });

                                const response = await apiFavoritePresetCard(textCard.id, data);

                                if (response.error) {
                                    console.log(response.error);
                                } else {
                                    dispatch({
                                        type: TextCardActions.ADD_TO_FAVOURITES,
                                        payload: { data: response.data },
                                    });

                                    let msg = '';
                                    if (response.data.is_starred) {
                                        msg = 'Text Card added to Favorites';
                                    } else {
                                        msg = 'Text Card removed from Favorites';
                                    }

                                    CustomToast({
                                        message: msg,
                                        variant: 'ALERT_INFO',
                                        width: '300px',
                                    });
                                }
                            }}
                            disabled={false}
                        />
                    );
                })}
            </Box>

            {showSaveModal && (
                <SaveTextCardModal
                    folderName={props.folderName}
                    header={'Where to save?'}
                    selections={allFolders}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowSaveModal(false),
                    }}
                    confirmButton={{
                        label: 'Save',

                        onConfirm: async (folder, name, option) => {
                            const selectedTextCardId: number[] = selectedTextCards.map(d => d.id);
                            /*
                             ** Saves on an existing folder
                             */
                            if (option === 'existing') {
                                const moveToFolder = await apiMoveTextCardToFolder(selectedTextCardId, folder);

                                if (moveToFolder.error) {
                                    console.log(moveToFolder.error);
                                } else {
                                    CustomToast({
                                        message: 'Saved!',
                                        ctaLabel: 'Visit the library',
                                        redirectButton: (
                                            <LinkButton
                                                label="Visit the library"
                                                onClick={() => {
                                                    navigate('/library/');
                                                }}
                                                sx={{
                                                    color: setColor('hl-blue'),
                                                    textDecoration: 'underline',
                                                }}
                                            />
                                        ),
                                        timer: 3000,
                                        width: '300px',
                                    });
                                }
                            }
                            /*
                             ** creats a new folder
                             */
                            if (option === 'new') {
                                const data = JSON.stringify({
                                    folder_name: name,
                                    data: {},
                                    user: user.email,
                                });

                                const addFolder = await apiAddFolder(data);

                                if (addFolder.error) {
                                    console.log(addFolder.error);
                                } else {
                                    const moveToFolder = await apiMoveTextCardToFolder(
                                        selectedTextCardId,
                                        addFolder.data.id,
                                    );

                                    if (moveToFolder.error) {
                                        console.log(moveToFolder.error);
                                    } else {
                                        const updatedFolderList = [...allFolders];

                                        updatedFolderList.push(addFolder.data);

                                        dispatch({
                                            type: FolderActions.SET_FOLDER,
                                            payload: { data: updatedFolderList },
                                        });

                                        CustomToast({
                                            message: 'Saved!',
                                            ctaLabel: 'Visit the library',
                                            redirectButton: (
                                                <LinkButton
                                                    label="Visit the library"
                                                    onClick={() => {
                                                        navigate('/library/');
                                                    }}
                                                    sx={{
                                                        color: setColor('hl-blue'),
                                                        textDecoration: 'underline',
                                                    }}
                                                />
                                            ),
                                            timer: 3000,
                                            width: '300px',
                                        });
                                    }
                                }
                            }

                            setShowSaveModal(false);
                        },
                    }}
                />
            )}
        </>
    );
}

export default PresetCardContainer;
