import { BREAKPOINTS, SPACING } from '@constants/constants';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        button: {
            whiteSpace: 'nowrap',
            textTransform: 'none',
        },
    },
    breakpoints: {
        values: BREAKPOINTS,
    },
    spacing: SPACING,
});
