import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import TemplateList from './TemplateList';
import { Link } from 'react-router-dom';
import CategoryList from './CategoryList';
import MoveTemplate from './MoveTemplate';
import { apiGetCategories, apiUpdateTextPurposeCategory } from '@store/api/apiSettings';
import { ICategory } from '@interfaces/api/ICategory';

function SettingsModal(props) {
    const [categoryList, setCategoryList] = useState<ICategory[]>([]);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [categoryName, setCategoryName] = useState('');
    // const [selected, isSelected] = useState<boolean>(true);

    const selected = true;

    const [selectedTab, setSelectebTab] = useState('Category');
    const [moveCategory, setMoveCategory] = useState<boolean>(false);
    const [moveData, setMoveData] = useState<any>({
        template_id: 0,
        template_name: '',
    });
    const [newCategory, setNewCategory] = useState<any>({
        new_category_id: 0,
        new_category_name: '',
    });

    const getCategories = async () => {
        const response = await apiGetCategories();

        if (response.error) {
            console.log(response.error);
        } else {
            response.data.map(category => {
                return {
                    id: category.id,
                    category_name: category.category_name,
                };
            });
            setCategoryList(response.data);
        }
    };

    useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            getCategories();
        }

        return () => {
            isCancelled = true;
        };
    }, [selectedTab, moveCategory]);

    const handleOption = e => {
        e.preventDefault();
        setCategoryId(e.target.value);
        setCategoryName(e.target.options[e.target.selectedIndex].text);
    };

    const renderCategoriesList = categoryList.map(category => {
        return (
            <option key={category.id} value={category.id}>
                {category.category_name}{' '}
            </option>
        );
    });

    const moveToCategory = async () => {
        const template_id = moveData.template_id;
        const data = JSON.stringify({
            category_id: newCategory.new_category_id,
            category_name: newCategory.new_category_name,
            template_name: moveData.template_name,
        });

        const response = await apiUpdateTextPurposeCategory(template_id, data);

        if (response.error) {
            console.log(response.error);
        } else {
            setMoveCategory(false);
        }
    };

    return (
        <>
            <Modal show={props.show} centered className="generic dialog fade">
                <Modal.Header>
                    <Modal.Title>Tool Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Link className="link-style" to="#" onClick={() => setSelectebTab('Category')}>
                        Category
                    </Link>
                    |
                    <Link className="link-style" to="#" onClick={() => setSelectebTab('Template')}>
                        Template
                    </Link>
                    {selectedTab === 'Template' ? (
                        <>
                            <Form>
                                <div className="save-folder">
                                    <Form.Group className="mb-0 arrow-down">
                                        <Form.Control
                                            as="select"
                                            size="sm"
                                            id="templates"
                                            disabled={!selected ? true : false}
                                            onChange={e => handleOption(e)}
                                            defaultValue="none"
                                        >
                                            <option value="none" selected disabled>
                                                -- Select a category --
                                            </option>

                                            {renderCategoriesList}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </Form>

                            <TemplateList
                                categoryId={categoryId}
                                categoryName={categoryName}
                                setMoveCategory={setMoveCategory}
                                moveCategory={moveCategory}
                                setMoveData={setMoveData}
                            />
                        </>
                    ) : (
                        <CategoryList />
                    )}
                </Modal.Body>

                <Modal.Footer className="modal-footer-save">
                    <Button variant="default" className="box-shadow" size="sm" onClick={props.onCancel}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {moveCategory && (
                <MoveTemplate
                    show={moveCategory}
                    onSave={moveToCategory}
                    setNewCategory={setNewCategory}
                    onCancelData={() => setMoveCategory(false)}
                />
            )}
        </>
    );
}
export default SettingsModal;

// function dispatch(arg0: (dispatch: any) => Promise<void>) {
//   throw new Error("Function not implemented.");
// }
