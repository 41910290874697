import { IAction } from '@interfaces/api/IAction';
import { ITextCardCollection } from '@interfaces/api/ITextCard';
import { TextCardCollectionActions } from '@store/actions/actions';

export const textCardCollectionReducer = (
    state: ITextCardCollection = { page: 1, count: 0, results: [] },
    action: IAction,
) => {
    switch (action.type) {
        case TextCardCollectionActions.SET_CARD_COLLECTION: {
            return { ...action.payload.data };
        }
        case TextCardCollectionActions.SET_PAGE: {
            return { ...state, page: action.payload.data };
        }
        default:
            return state;
    }
};
export default textCardCollectionReducer;
