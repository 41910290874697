import { Button, SxProps } from '@mui/material';

type HTMLAttributeAnchorTarget = '_self' | '_blank' | '_parent' | '_top' | (string & {});

export interface ICustomButtonProps {
    /**
     * optional label of the button
     */
    label?: React.ReactNode | string;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * on click event
     */
    href?: string | undefined;
    /**
     * on click event
     */
    target?: HTMLAttributeAnchorTarget | undefined;
    /**
     * on click event
     */
    rel?: string | undefined;
    /**
     * custom style
     */
    sx?: SxProps;
}

const LinkButton: React.FC<ICustomButtonProps> = ({
    label = 'MadgicxIQ Link Button',
    onClick = () => {},
    href,
    target = '_blank',
    rel,
    sx = {},
}) => {
    return (
        <a href={href} target={target} rel={rel} style={{ textDecoration: 'none' }}>
            <Button
                onClick={onClick}
                sx={{
                    m: 0,
                    textTransform: 'none',
                    textDecoration: 'none',

                    ...sx,
                }}
            >
                {label}
            </Button>
        </a>
    );
};

export default LinkButton;
