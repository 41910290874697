import { IUser } from '@interfaces/api/ILogin';
import { externalFetch, IRequestState } from './apiUtils';

export const apiCreateNewUser = async (data: string): Promise<IRequestState<IUser>> => {
    const response: IRequestState<IUser> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/auth/register/`,
    );

    return response;
};

export const apiGetUserInfo = async (id: number): Promise<IRequestState<IUser[]>> => {
    const response: IRequestState<IUser[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/user/id/${id}`,
    );

    return response;
};

export const apiLoginUser = async (data: string): Promise<IRequestState<IUser>> => {
    const response: IRequestState<IUser> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/auth/login/`,
    );

    return response;
};

export const apiUpdateToken = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + `/api/auth/refresh/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: JSON.parse(localStorage.getItem('authTokens') || '{}').refresh }),
    });

    let data = await response.json();

    if (response.status === 200) {
        localStorage.setItem('authTokens', JSON.stringify(data));
    }
};
