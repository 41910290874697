import { Tooltip, Box } from '@mui/material';

export interface ITooltipProps {
    title: NonNullable<React.ReactNode>;
    children?: React.ReactElement;
    placement:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    disabled?: boolean;
}

const CustomTooltip: React.FC<ITooltipProps> = ({
    title = 'Tooltip Title',
    placement = 'top',
    children = <></>,
    disabled = false,
}) => {
    return (
        <>
            {disabled ? (
                <Box>{children}</Box>
            ) : (
                <Tooltip
                    title={title}
                    placement={placement}
                    arrow
                    /*
                     **  removes animation
                     */
                    TransitionProps={{ timeout: 0 }}
                    /*
                     **  changes the background color
                     */
                    componentsProps={{
                        tooltip: { sx: { bgcolor: '#6F7B92' } },
                        arrow: {
                            sx: {
                                '&:before': {
                                    bgcolor: '#6F7B92',
                                },
                            },
                        },
                    }}
                >
                    <Box>{children}</Box>
                </Tooltip>
            )}
        </>
    );
};

export default CustomTooltip;
