import { Box, Card } from '@mui/material';
import PopOverMenu, { PopOverMenuOptions } from '@components/common/popover-menu/PopOverMenu';
import CustomTextArea from '@components/common/custom-textarea/CustomTextArea';
import IconCollection, { iconCollections } from '@components/icon-collection/IconCollection';
import Moment from 'react-moment';
import { ITextCard } from '@interfaces/api/ITextCard';
import InputOverlay from '@components/common/input-overlay/InputOverlay';
import { useState } from 'react';
import { Caption, P1 } from '@components/common/typography/Typography';
import { setColor } from '@utils/colorUtils';
import RoundButton from '@components/common/custom-button/round-button/RoundButton';
import InvisibleButton from '@components/common/custom-button/invisible-button/InvisibleButton';
import { AlignCenter } from '@components/layout/app-flexbox/AppFlexBox';

interface ITextCardProps {
    textCard?: ITextCard;
    isSelected?: boolean;
    onClick: () => void;
    onEdit?: () => void;
    onMove?: () => void;
    onToggleFavorite?: () => void;
    onShare?: () => void;
    onDownload?: () => void;
    onDelete?: () => void;
    disabled?: boolean;
}

const TextCard: React.FC<ITextCardProps> = ({
    textCard,
    isSelected,
    onClick = () => {},
    onEdit = () => {},
    onMove = () => {},
    onToggleFavorite = () => {},
    onShare = () => {},
    onDownload = () => {},
    onDelete = () => {},
    disabled,
}) => {
    const [showInputOverlay, setShowInputOverlay] = useState<boolean>(false);

    return (
        <Card
            sx={{
                display: 'inline-block',
                position: 'relative',
                width: '100%',
                height: '100%',
                padding: 4,
                marginBottom: 1,
                boxSizing: 'border-box',
                border: isSelected ? `1px solid ${setColor('hl-blue')}` : '1px solid transparent',

                '&:hover': {
                    border: isSelected
                        ? `1px solid ${setColor('hl-blue')}`
                        : `1px solid ${setColor('hl-light-purple')}`,
                },
            }}
        >
            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'} py={2} mb={2}>
                <AlignCenter sx={{ gap: 1 }}>
                    <IconCollection icon="LIGHT_BULB" size={'xl'} />
                    <P1 color={'fc-grey'}>{`${textCard?.template_name ?? ''} ${
                        textCard?.template_name === undefined || textCard?.project_name === undefined ? '' : '-'
                    } ${textCard?.project_name ?? ''}`}</P1>
                </AlignCenter>

                {textCard?.folder_type === 'regular' && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <RoundButton
                            label={
                                <img
                                    src={iconCollections.EDIT.src}
                                    alt={'icon-warning'}
                                    style={{ width: 12, height: 12 }}
                                />
                            }
                            onClick={onEdit}
                            sx={{ zIndex: 2 }}
                        />

                        <PopOverMenu
                            menus={[
                                'MOVE_TO',
                                textCard?.is_starred ? 'UNFAVORITE' : 'ADD_TO_FAVORITES',
                                'SHARE',
                                'DOWNLOAD',
                                'DELETE',
                            ]}
                            tooltip={{
                                title: 'Textcard Options',
                                placement: 'top',
                            }}
                            onClick={(menu: PopOverMenuOptions) => {
                                if (menu === 'MOVE_TO') {
                                    onMove();
                                }
                                if (menu === 'ADD_TO_FAVORITES' || menu === 'UNFAVORITE') {
                                    onToggleFavorite();
                                }
                                if (menu === 'SHARE') {
                                    onShare();
                                }
                                if (menu === 'DOWNLOAD') {
                                    onDownload();
                                }
                                if (menu === 'DELETE') {
                                    onDelete();
                                }
                            }}
                            sx={{ zIndex: 2 }}
                        />
                    </Box>
                )}
            </Box>

            <InputOverlay
                showOverlay={showInputOverlay}
                setShowOverlay={setShowInputOverlay}
                onClick={() => {
                    navigator.clipboard.writeText(textCard?.results);

                    setShowInputOverlay(true);
                }}
            >
                <CustomTextArea
                    value={textCard?.results}
                    maxLength={textCard?.max_allowed_characters}
                    disabled={disabled}
                    onCopy={() => {}}
                />
            </InputOverlay>

            <Caption color={'fc-grey'}>
                Created&nbsp;
                <Moment unix format="DD/MM/YYYY hh:mm:ss A">
                    {textCard?.created_at}
                </Moment>
            </Caption>

            {/* Invisible button */}
            <InvisibleButton
                onClick={() => {
                    onClick();
                }}
            />
        </Card>
    );
};

export default TextCard;
