import { SpaceBetween } from '@components/layout/app-flexbox/AppFlexBox';
import { Box, Card, Skeleton } from '@mui/material';

const PresetCardSkeleton: React.FC<any> = () => {
    return (
        <Card
            sx={{
                display: 'inline-block',
                position: 'relative',
                width: '100%',
                height: '100%',
                p: 4,
                mb: 1,
                boxSizing: 'border-box',
                border: '1px solid transparent',
            }}
        >
            <SpaceBetween sx={{ mb: 4 }}>
                <Box />
                <Box display={'flex'} gap={2}>
                    <Skeleton variant={'circular'} width={20} height={20} />
                    <Skeleton variant={'circular'} width={20} height={20} />
                    <Skeleton variant={'circular'} width={20} height={20} />
                </Box>
            </SpaceBetween>

            <Skeleton variant={'rectangular'} width={'100%'} height={100} />
        </Card>
    );
};

export default PresetCardSkeleton;
