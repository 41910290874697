import { IAppState } from '@interfaces/api/IAppState';
import { ITemplate } from '@interfaces/api/ITemplate';
import { createSelector } from 'reselect';

export const selectAllTemplate = createSelector(
    (state: IAppState) => state.templates,
    (templates: ITemplate[]) => templates,
);

export const selectTemplate = createSelector(
    (state: IAppState) => state.templates,
    (templates: ITemplate[]) => templates.filter(f => f.selected),
);
