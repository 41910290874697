import axios from 'axios';
import * as _ from 'lodash';

/*eslint-disable-next-line*/
export interface IRequestState<T> {
    isFetching?: boolean;
    data?: any;
    warningsHeader?: string;
    status?: number;
    error?: any;
    time?: string;
}

export interface IRequestOptions {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    data?: any;
    headers?: { [header: string]: string };
    responseType?: ResponseType;
    transform?: boolean;
}

export type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';

export const createDefault = <T extends any>(): IRequestState<T> => {
    return {};
};
export const createTestComplete = <T extends any>(): IRequestState<T> => {
    return {
        data: { fetchCalled: true } as unknown as T,
    };
};
export const createRequesting = <T extends any>(): IRequestState<T> => {
    return {
        isFetching: true,
    };
};
export const createWarning = <T extends any>(warnings: string, data: T): IRequestState<T> => {
    return {
        warningsHeader: warnings,
        data,
    };
};
export const createSuccess = <T extends any>(data: T): IRequestState<T> => {
    return {
        data,
    };
};
export const createError = <T extends any>(error: any): IRequestState<T> => {
    return {
        error,
    };
};

export async function externalFetch<T>(options: IRequestOptions, url: string): Promise<IRequestState<T>> {
    _.defaults(options, {
        method: 'GET',
        data: null,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authTokens') || '{}').access,
        },
    });

    const processedData = _.cloneDeep(options.data || {});

    // Don't send some null data
    if (processedData != null && !processedData.id) {
        delete processedData.id;
    }

    let rs: IRequestState<T> = {};

    axios.interceptors.response.use(
        response => response,
        error => error.response,
    );

    const response = await axios({
        url,
        method: options.method,
        data: processedData,
        headers: options.headers,
        responseType: options.responseType,
        transformResponse: response => {
            try {
                return JSON.parse(response);
            } catch (ex) {
                return response;
            }
        },
    });

    if (response?.status === 200 || 201) {
        rs = createSuccess(response.data);
    } else {
        rs = createError(response.status + ': ' + response.statusText);
    }

    return rs;
}
