import { InputBase, SxProps } from '@mui/material';

interface ICustomInputProps {
    /**
     * optional placeholder of the input field
     */
    placeholder?: string;
    /**
     * optional is the input field multiline
     */
    multiline?: boolean;
    /**
     * text value of the input field
     */
    value: string;
    /**
     * on change event of the input field
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * on keypress event of the input field
     */
    onKeypress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    /**
     * on blur event of the input field
     */
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    /**
     * optional is the input field disabled
     */
    disabled?: boolean;
    /**
     * optional is the input field focused
     */
    onFocus?: boolean;

    sx?: SxProps;
}

const CustomInput: React.FC<ICustomInputProps> = ({
    placeholder,
    multiline = false,
    value,
    onChange,
    onKeypress,
    onBlur,
    disabled = false,
    onFocus = false,
    sx,
}) => {
    return (
        <InputBase
            placeholder={placeholder}
            multiline={multiline}
            autoFocus={onFocus}
            inputRef={
                onFocus === true
                    ? input => {
                          input && input.focus();
                      }
                    : null
            }
            value={value}
            onChange={onChange}
            onKeyPress={onKeypress}
            onBlur={onBlur}
            disabled={disabled}
            /* 
        input custom styling
      */
            sx={{
                width: '100%',
                color: '#0D182F',
                typography: 'body1',
                '& input': {
                    fontSize: { xs: 10, sm: 12, md: 14 },
                    width: '100%',
                    padding: 1,
                    borderRadius: '4px',
                    border: '1px solid #e3e5e9',

                    '&:focus': {
                        border: '1px solid #006DF0',
                    },
                },
                ...sx,
            }}
        />
    );
};

export default CustomInput;
