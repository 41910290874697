import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '@components/common/custom-button/CustomButton';
import { Box, Divider } from '@mui/material';
import AppHeader, { Center, Left, Right } from '@components/layout/app-header/AppHeader';
import ConfirmModal from '@components/custom-modals/confirm-modal/ConfirmModal';
import MoveModal from '@components/custom-modals/move-modal/MoveModal';
import { apiDeleteTextCard, apiMoveTextCardToFolder, apiShareTextCard } from '@store/api/apiTextCards';
import { IAppState } from '@interfaces/api/IAppState';
import { IFolder } from '@interfaces/api/IFolder';
import { selectAllFolder } from '@store/selectors/folderSelector';
import { selectAllTextCard, selectTextCard } from '@store/selectors/textCardSelector';
import ShareLinkModal from '@components/custom-modals/share-link-modal/ShareLinkModal';
import { ITextCard, ITextCardShare } from '@interfaces/api/ITextCard';
import AppToolbar from '@components/layout/app-toolbar/AppToolbar';
import AppSidebar from '@components/layout/app-sidebar/AppSidebar';
import SearchInput from '@components/common/search-input/SearchInput';
import CustomToast from '@components/common/custom-toast/CustomToast';
import ToolbarButtonGroup from '@components/toolbar-button-group/ToolbarButtonGroup';
import FolderList from './folder-list/FolderList';
import ProjectList from './project-list/ProjectList';
import TextCardContainer from './textcard-container/TextCardContainer';
import AppContent from '@components/layout/app-content/AppContent';
import { H1, P2, P3 } from '@components/common/typography/Typography';
import AppPagination from '@components/layout/app-pagination/AppPagination';
import MakeMadgicx from '@components/make-madgicx/MakeMadgicx';
import {
    LoginActions,
    ProjectActions,
    PromptActions,
    TextCardActions,
    TextCardCollectionActions,
} from '@store/actions/actions';
import IconCollection, { iconCollections } from '@components/icon-collection/IconCollection';
import { AlignCenter, FlexColumn } from '@components/layout/app-flexbox/AppFlexBox';
import useMediaQuery from '@utils/mediaQueryUtils';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import { setColor } from '@utils/colorUtils';
import { getUser } from '@store/selectors/userSelector';
import { apiSubscription } from '@store/api/apiUsers';
import LinkButton from '@components/common/custom-button/link-button/LinkButton';
import { selectTextCardCollection } from '@store/selectors/textCardCollectionSelector';

const TextLibrary: React.FC = () => {
    const user = useSelector(getUser);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const allFolders = useSelector<IAppState, IFolder[]>(selectAllFolder);
    const allTextCard = useSelector<IAppState, ITextCard[]>(selectAllTextCard);
    const selectedTextCards = useSelector(selectTextCard);
    const textCardCollection = useSelector(selectTextCardCollection);

    /*
     * If need to add loader
     */
    // const loader = useSelector(getLoader);

    const [searchText, setSearchText] = useState<string>('');
    const [isFavourite, setIsFavourite] = useState<boolean>(false);

    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const [page, setPage] = useState<number>(textCardCollection?.page);

    const [openSideBar, setOpenSideBar] = useState<boolean>(true);

    const isMobile = useMediaQuery('(max-width: 900px)');

    useEffect(() => {
        if (isMobile === true) setOpenSideBar(false);
        if (isMobile === false) setOpenSideBar(true);

        return () => {
            setOpenSideBar(false);
        };
    }, [isMobile]);

    useEffect(() => {
        dispatch({
            type: TextCardActions.CLEAR_SELECTED,
            payload: {},
        });

        dispatch({
            type: TextCardActions.CLEAR_CARDS,
            payload: {},
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: to add upload modal
    // const [uploadModal, setUploadModal] = useState<boolean>(false);

    const updateSubscription = async () => {
        const userId = user.id;
        const data = JSON.stringify({
            is_subscribed: true,
        });

        const response = await apiSubscription(userId, data);

        if (response.error) {
            console.log(response.error);
        } else {
            dispatch({ type: LoginActions.SET_USER, payload: response.data });
        }
    };

    return (
        <>
            <AppHeader>
                <Left>
                    <P3>
                        {' '}
                        Welcome, {user.email}!
                        <P3>
                            Plan:{' '}
                            {user.is_subscribed ? (
                                'FULL'
                            ) : (
                                <>
                                    LIMITED{' '}
                                    <LinkButton
                                        label="(upgrade!)"
                                        href="https://buy.stripe.com/fZebMcffP7nTdHidQY"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            color: setColor('hl-blue'),
                                            textDecoration: 'underline',
                                            margin: '-5px',
                                        }}
                                        onClick={updateSubscription}
                                    />
                                </>
                            )}
                        </P3>
                    </P3>
                </Left>
                <Center>
                    <H1>Text Library</H1>
                </Center>
                <Right>
                    <CustomButton
                        label={
                            <>
                                <IconCollection icon="LIGHT_BULB" size="xl" sx={{ mr: { xs: 0, md: 2 } }} />
                                <P2 color={'hl-blue'} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    Generate new Idea
                                </P2>
                            </>
                        }
                        variant={'borderless'}
                        onClick={() => 
                            {
                            dispatch({
                                type: TextCardCollectionActions.SET_PAGE,
                                payload: { data: 1 },
                            });

                            dispatch({
                                type: ProjectActions.DESELECT_PROJECT,
                                payload: {},
                            });

                            dispatch({
                                type: TextCardActions.CLEAR_SELECTED,
                                payload: {},
                            });

                            dispatch({
                                type: TextCardActions.CLEAR_CARDS,
                                payload: {},
                            });

                            dispatch({
                                type: PromptActions.CLEAR_PROMPT,
                                payload: {},
                            });

                            navigate('/generator/');
                        }}
                    />
                </Right>
            </AppHeader>

            <AppToolbar>
                <Box>
                    <CustomTooltip title={'Open Sidebar'} placement={'bottom-end'}>
                        <BorderlessButton
                            label={
                                <FlexColumn sx={{ gap: 1 }}>
                                    <Box width={16} height={2} bgcolor={setColor('fc-grey')} />
                                    <Box width={16} height={2} bgcolor={setColor('fc-grey')} />
                                    <Box width={16} height={2} bgcolor={setColor('fc-grey')} />
                                </FlexColumn>
                            }
                            onClick={() => setOpenSideBar(true)}
                        />
                    </CustomTooltip>
                </Box>
                <ToolbarButtonGroup
                    selections={selectedTextCards}
                    menus={
                        allTextCard[0]?.folder_type === 'regular' || allTextCard[0]?.folder_type === undefined
                            ? ['DOWNLOAD', 'SHARE', 'MOVE', 'DELETE']
                            : ['DOWNLOAD', 'SHARE']
                    }
                    onClear={() => dispatch({ type: TextCardActions.CLEAR_SELECTED })}
                    onClick={menu => {
                        if (menu === 'DOWNLOAD') {
                            const csvData = new Blob(
                                [selectedTextCards.map(d => d.results.replace(/(\r\n|\n|\r)/gm, '')) + '\n'],
                                { type: 'text/csv;charset=utf-8;' },
                            );
                            FileSaver.saveAs(csvData, 'data.csv');
                        }

                        if (menu === 'SHARE') {
                            setShowShareModal(true);
                        }

                        if (menu === 'MOVE') {
                            setShowMoveModal(true);
                        }

                        if (menu === 'DELETE') {
                            setShowDeleteModal(true);
                        }
                    }}
                />
            </AppToolbar>

            <AppSidebar open={openSideBar}>
                <AlignCenter sx={{ height: 54, gap: 4, px: 4 }}>
                    <CustomTooltip title={'Close Sidebar'} placement={'bottom-end'}>
                        <BorderlessButton
                            label={
                                <img
                                    src={iconCollections.PLUS.src}
                                    alt={'icon-warning'}
                                    style={{ width: 16, height: 16, transform: 'rotate(45deg)' }}
                                />
                            }
                            onClick={() => setOpenSideBar(false)}
                        />
                    </CustomTooltip>

                    <SearchInput
                        label={'Search'}
                        value={searchText}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.preventDefault();

                            setSearchText(e.target.value);
                        }}
                    />
                </AlignCenter>
                <Divider />
                <FolderList setIsFavourite={setIsFavourite} searchText={searchText} />
                <Divider />
                <ProjectList />
            </AppSidebar>

            <AppContent open={openSideBar}>
                {
                    allTextCard?.length === 0 ? (
                        <MakeMadgicx
                            body={"Let's generate some content for your business with AI!"}
                            onClick={() => navigate('/tools/')}
                        />
                    ) : (
                        <>
                            {allTextCard.length ? (
                                <AppPagination
                                    page={page}
                                    setPage={setPage}
                                    itemCount={textCardCollection?.count}
                                    onChange={(e, val) => {
                                        setPage(val);

                                        dispatch({
                                            type: TextCardCollectionActions.SET_PAGE,
                                            payload: { data: val },
                                        });
                                    }}
                                />
                            ) : null}
                            <TextCardContainer user={user.email} searchText={searchText} isFavourite={isFavourite} />
                        </>
                    )

                    /*
                     * If need to add loader
                     */
                    // : loader.is_loading === true ? (
                    //   <Box
                    //     display={"flex"}
                    //     alignItems={"center"}
                    //     justifyContent={"center"}
                    //     width={"100%"}
                    //     height={"100%"}
                    //   >
                    //     <CircularProgress />
                    //   </Box>
                    // ) : (
                    //   <TextCardContainer
                    //     user={user}
                    //     searchText={searchText}
                    //     isFavourite={isFavourite}
                    //   />
                    // )
                }
            </AppContent>

            {/* TODO: to add upload modal */}
            {/* <UploadModal show={uploadModal} onCancel={() => setUploadModal(false)} /> */}

            {/*
             * Modals
             */}
            {showDeleteModal && (
                <ConfirmModal
                    header={'Are you sure?'}
                    body={`Are you sure you want to delete the selected text(s)?`}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowDeleteModal(false),
                    }}
                    confirmButton={{
                        label: 'Delete',
                        onConfirm: async () => {
                            for (let i = 0; i < selectedTextCards.length; i++) {
                                const response = await apiDeleteTextCard(selectedTextCards, i);

                                if (response.error) {
                                    console.log(response.error);
                                } else {
                                    dispatch({
                                        type: TextCardActions.DELETE_CARDS,
                                        payload: { data: selectedTextCards[i].id },
                                    });

                                    CustomToast({
                                        message: 'Text Card(s) Deleted',
                                        variant: 'ALERT_INFO',
                                        width: '300px',
                                    });

                                    setShowDeleteModal(false);
                                }
                            }
                        },
                    }}
                />
            )}

            {showMoveModal && (
                <MoveModal
                    header={'Move text to folder'}
                    selections={allFolders}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowMoveModal(false),
                    }}
                    confirmButton={{
                        label: 'Save Changes',

                        onConfirm: async (folderId: string) => {
                            const selectedTextCardId: number[] = selectedTextCards.map(d => d.id);

                            const response = await apiMoveTextCardToFolder(selectedTextCardId, folderId);

                            if (response.error) {
                                console.log(response.error);
                            } else {
                                dispatch({
                                    type: TextCardActions.MOVE_CARDS,
                                    payload: { data: response.data.results },
                                });

                                CustomToast({
                                    message: 'Text Card(s) Moved',
                                    variant: 'ALERT_INFO',
                                    width: '300px',
                                });
                            }

                            setShowMoveModal(false);
                        },
                    }}
                />
            )}

            {showShareModal && (
                <ShareLinkModal
                    header={'Share options'}
                    selections={selectedTextCards}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowShareModal(false),
                    }}
                    confirmButton={{
                        label: 'Copy',

                        onConfirm: async (textCardList: ITextCardShare[], uid: string, link: string) => {
                            const data = JSON.stringify({
                                results: textCardList,
                                uid: uid,
                                created_by: user.email,
                            });

                            const response = await apiShareTextCard(data);

                            if (response.error) {
                                console.log(response.error);
                            } else {
                                navigator.clipboard.writeText(link);
                            }
                        },
                    }}
                />
            )}
        </>
    );
};

export default TextLibrary;
