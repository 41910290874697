import alertInfo from '@icons/alert-info.svg';
import alertSuccess from '@icons/alert-success.svg';
import alertWarning from '@icons/alert-warn.svg';
import arrowDown from '@icons/arrow-down.svg';
import arrowDownBlack from '@icons/arrow-down-black.svg';
import arrowDownSlim from '@icons/arrow-down-slim.svg';
import arrowUp from '@icons/arrow-up.svg';
import arrowUpSlim from '@icons/arrow-up-slim.svg';
import brainStormingSmall from '@icons/brainstorming-small.svg';
import cartSmall from '@icons/cart-small.svg';
import closeSmall from '@icons/close-small.svg';
import closeSmallBlack from '@icons/close-small-black.svg';
import deleteLarge from '@icons/delete-large.svg';
import deleteSmall from '@icons/delete-small.svg';
import deleteDisabled from '@icons/delete-disabled.svg';
import download from '@icons/download.svg';
import downloadSmall from '@icons/download-small.svg';
import downloadDisabled from '@icons/download-disabled.svg';
import duplicate from '@icons/duplicate.svg';
import duplicateDisabled from '@icons/duplicate-disabled.svg';
import edit from '@icons/edit.svg';
import editSmall from '@icons/edit-small.svg';
import editDisabled from '@icons/edit-disabled.svg';
import emailSmall from '@icons/email-small.svg';
import facebook from '@icons/facebook.svg';
import facebookSmall from '@icons/facebook-small.svg';
import favourites from '@icons/favourites.svg';
import folderClose from '@icons/folder-close.svg';
import folderOpen from '@icons/folder-open.svg';
import gear from '@icons/gear.svg';
import lightBulb from '@icons/light-bulb.svg';
import link from '@icons/link.svg';
import linkSmall from '@icons/link-small.svg';
import linkDisabled from '@icons/link-disabled.svg';
import move from '@icons/move.svg';
import moveSmall from '@icons/move-small.svg';
import moveDisabled from '@icons/move-disabled.svg';
import newTab from '@icons/new-tab.svg';
import next from '@icons/next-white.svg';
import plus from '@icons/plus.svg';
import plusDisabled from '@icons/plus-disabled.svg';
import prev from '@icons/prev-black.svg';
import roundCheck from '@icons/round-check.svg';
import save from '@icons/save.svg';
import saveOutline from '@icons/save-outline.svg';
import saveDisabled from '@icons/save-disabled.svg';
import search from '@icons/search.svg';
import seoSmall from '@icons/seo-small.svg';
import showPassword from '@icons/show-password.svg';
import socialMediaSmall from '@icons/social-media-small.svg';
import star from '@icons/star-default.svg';
import starOutlined from '@icons/star-outline.svg';
import starDisabled from '@icons/star-disabled.svg';
import startNewGeneration from '@icons/start-new-generation.svg';
import threeDotsHorizontal from '@icons/three-dots-horizontal.svg';
import trash from '@icons/trash.svg';
import videoCamSmall from '@icons/video-cam-small.svg';
import warning from '@icons/warning.svg';
import warningDisabled from '@icons/warning-disabled.svg';
import { Box, SxProps } from '@mui/material';

export type IconCollections =
    | 'ALERT_INFO'
    | 'ALERT_SUCCESS'
    | 'ALERT_WARNING'
    | 'ARROW_DOWN'
    | 'ARROW_DOWN_BLACK'
    | 'ARROW_DOWN_SLIM'
    | 'ARROW_UP'
    | 'ARROW_UP_SLIM'
    | 'BRAINSTORMING_SMALL'
    | 'CART_SMALL'
    | 'CLOSE_SMALL'
    | 'CLOSE_SMALL_BLACK'
    | 'DELETE'
    | 'DELETE_SMALL'
    | 'DELETE_DISABLED'
    | 'DOWNLOAD'
    | 'DOWNLOAD_SMALL'
    | 'DOWNLOAD_DISABLED'
    | 'DUPLICATE'
    | 'DUPLICATE_DISABLED'
    | 'EDIT'
    | 'EDIT_SMALL'
    | 'EDIT_DISABLED'
    | 'EMAIL_SMALL'
    | 'FACEBOOK'
    | 'FACEBOOK_SMALL'
    | 'FAVOURITES'
    | 'FOLDER_CLOSE'
    | 'FOLDER_OPEN'
    | 'GEAR'
    | 'LIGHT_BULB'
    | 'LINK'
    | 'LINK_SMALL'
    | 'LINK_DISABLED'
    | 'MOVE'
    | 'MOVE_SMALL'
    | 'MOVE_DISABLED'
    | 'NEW_TAB'
    | 'NEXT'
    | 'PLUS'
    | 'PLUS_DISABLED'
    | 'PREV'
    | 'ROUND_CHECK'
    | 'SAVE'
    | 'SAVE_OUTLINE'
    | 'SAVE_DISABLED'
    | 'SEARCH'
    | 'SEO_SMALL'
    | 'SHOW_PASSWORD'
    | 'SOCIAL_MEDIA_SMALL'
    | 'STAR'
    | 'STAR_OUTLINED'
    | 'STAR_DISABLED'
    | 'START_NEW_GENERATION'
    | 'THREE_DOTS_HORIZONTAL'
    | 'TRASH'
    | 'VIDEO_SMALL'
    | 'WARNING'
    | 'WARNING_DISABLED';

export interface IconImageProps {
    icon?: IconCollections;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    className?: string;
    sx?: SxProps;
}

export const iconCollections = {
    ALERT_INFO: { src: alertInfo },
    ALERT_SUCCESS: { src: alertSuccess },
    ALERT_WARNING: { src: alertWarning },
    ARROW_DOWN: { src: arrowDown },
    ARROW_DOWN_BLACK: { src: arrowDownBlack },
    ARROW_DOWN_SLIM: { src: arrowDownSlim },
    ARROW_UP: { src: arrowUp },
    ARROW_UP_SLIM: { src: arrowUpSlim },
    BRAINSTORMING_SMALL: { src: brainStormingSmall },
    CART_SMALL: { src: cartSmall },
    CLOSE_SMALL: { src: closeSmall },
    CLOSE_SMALL_BLACK: { src: closeSmallBlack },
    DELETE: { src: deleteLarge },
    DELETE_SMALL: { src: deleteSmall },
    DELETE_DISABLED: { src: deleteDisabled },
    DOWNLOAD: { src: download },
    DOWNLOAD_SMALL: { src: downloadSmall },
    DOWNLOAD_DISABLED: { src: downloadDisabled },
    DUPLICATE: { src: duplicate },
    DUPLICATE_DISABLED: { src: duplicateDisabled },
    EDIT: { src: edit },
    EDIT_SMALL: { src: editSmall },
    EDIT_DISABLED: { src: editDisabled },
    EMAIL_SMALL: { src: emailSmall },
    FACEBOOK: { src: facebook },
    FACEBOOK_SMALL: { src: facebookSmall },
    FAVOURITES: { src: favourites },
    FOLDER_CLOSE: { src: folderClose },
    FOLDER_OPEN: { src: folderOpen },
    GEAR: { src: gear },
    LIGHT_BULB: { src: lightBulb },
    LINK: { src: link },
    LINK_SMALL: { src: linkSmall },
    LINK_DISABLED: { src: linkDisabled },
    MOVE: { src: move },
    MOVE_SMALL: { src: moveSmall },
    MOVE_DISABLED: { src: moveDisabled },
    NEW_TAB: { src: newTab },
    NEXT: { src: next },
    PLUS: { src: plus },
    PLUS_DISABLED: { src: plusDisabled },
    PREV: { src: prev },
    ROUND_CHECK: { src: roundCheck },
    SAVE: { src: save },
    SAVE_OUTLINE: { src: saveOutline },
    SAVE_DISABLED: { src: saveDisabled },
    SEARCH: { src: search },
    SEO_SMALL: { src: seoSmall },
    SHOW_PASSWORD: { src: showPassword },
    SOCIAL_MEDIA_SMALL: { src: socialMediaSmall },
    STAR: { src: star },
    STAR_OUTLINED: { src: starOutlined },
    STAR_DISABLED: { src: starDisabled },
    START_NEW_GENERATION: { src: startNewGeneration },
    THREE_DOTS_HORIZONTAL: { src: threeDotsHorizontal },
    TRASH: { src: trash },
    VIDEO_SMALL: { src: videoCamSmall },
    WARNING: { src: warning },
    WARNING_DISABLED: { src: warningDisabled },
};

const iconStyle: SxProps<any> = (variant: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
    switch (variant) {
        case 'xs':
            return { width: 10, height: 'auto' };
        case 'sm':
            return { width: 12, height: 'auto' };
        case 'lg':
            return { width: 16, height: 'auto' };
        case 'xl':
            return { width: 18, height: 'auto' };
        case 'md':
        default:
            return { width: 14, height: 'auto' };
    }
};
const IconCollection: React.FC<IconImageProps> = ({ icon = 'THREE_DOTS_HORIZONTAL', size, className, sx }) => {
    return (
        <Box
            component="img"
            alt="icon"
            className={`icon ${className}`}
            src={iconCollections[icon].src}
            sx={{ ...iconStyle(size), ...sx }}
        />
    );
};

export default IconCollection;
