import { IFolder } from '@interfaces/api/IFolder';
import { externalFetch, IRequestState } from './apiUtils';

export const apiGetAllFolders = async (user: string | null): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/folders/user/${user}`,
    );

    return response;
};

export const apiGetHistoryFolder = async (
    user: string | null,
    pageSize?: number,
    pageNum = 1,
): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/folder/user/${user}/history?page=${pageNum}&page_size=${pageSize}`,
    );

    return response;
};

export const apiAddFolder = async (data: string): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/folders/`,
    );

    return response;
};

export const apiGetFavoriteFolder = async (user: string | null): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/folder/user/${user}/starred`,
    );

    return response;
};

export const apiGetOtherFolders = async (folderId: number | null): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/folder/${folderId}/results`,
    );

    return response;
};

export const apiDeleteFolder = async (folderId: number | null): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        { method: 'DELETE' },
        process.env.REACT_APP_API_URL + `/api/v1/folders/${folderId}`,
    );

    return response;
};

export const apiDuplicateFolder = async (folderId: number | null): Promise<IRequestState<IFolder[]>> => {
    const response: IRequestState<IFolder[]> = await externalFetch(
        { method: 'POST' },
        process.env.REACT_APP_API_URL + `/api/v1/folders/${folderId}`,
    );

    return response;
};
