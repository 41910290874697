import { Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { TONE_OF_VOICE_DATA } from '../../../constants/constants';

interface IToneSelectorProps {
    tone: string;
    setTone: React.Dispatch<React.SetStateAction<string>>;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ToneSelector(props: IToneSelectorProps) {
    const [focus, setFocus] = useState<boolean>(false);
    const handleChange = e => {
        props.setTone(e.target.value);
        // props.onChange(e);
    };

    return (
        <Select
            sx={{
                textAlign: 'left',
                width: '100%',
                border: '1px solid #E3E5E9',
                borderRadius: 1,
                boxShadow: '0px 3px 4px rgba(0,0,0,0.2)',
                margin: '5px',
                borderColor: focus ? '#006DF0' : '#E3E5E9',
                '& .MuiSelect-select': {
                    padding: 1,
                    border: 0,
                },

                '& .MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                },
            }}
            value={props.tone}
            onChange={handleChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
        >
            {TONE_OF_VOICE_DATA.map(tone => {
                return (
                    <MenuItem key={tone.id} value={tone.tone_of_voice}>
                        {tone.tone_of_voice}
                    </MenuItem>
                );
            })}
        </Select>
    );
}
