import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import AppHeader, { Center, Left, Right } from '@components/layout/app-header/AppHeader';
import { apiMoveTextCardToFolder, apiShareTextCard } from '@store/api/apiTextCards';
import { selectAllFolder } from '@store/selectors/folderSelector';
import { selectAllTextCard, selectTextCard } from '@store/selectors/textCardSelector';
import ShareLinkModal from '@components/custom-modals/share-link-modal/ShareLinkModal';
import { ITextCard, ITextCardShare } from '@interfaces/api/ITextCard';
import AppToolbar from '@components/layout/app-toolbar/AppToolbar';
import AppSidebar from '@components/layout/app-sidebar/AppSidebar';
import CustomToast from '@components/common/custom-toast/CustomToast';
import ToolbarButtonGroup from '@components/toolbar-button-group/ToolbarButtonGroup';
import AppContent from '@components/layout/app-content/AppContent';
import { H1, P2, P3 } from '@components/common/typography/Typography';
import { iconCollections } from '@components/icon-collection/IconCollection';
import { selectTemplate } from '@store/selectors/templateSelector';
import { AlignCenter, FlexCenter, FlexColumn, JustifyCenter } from '@components/layout/app-flexbox/AppFlexBox';
import CustomInput from '@components/common/custom-input/CustomInput';
import CustomTextArea from '@components/common/custom-textarea/CustomTextArea';
import MakeMadgicx from '@components/make-madgicx/MakeMadgicx';
import PresetCardContainer from './presetcard-container/PresetCardContainer';
import AppPagination from '@components/layout/app-pagination/AppPagination';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { apiGenerateIdeas } from '@store/api/apiIdeas';
import { apiGetUserTotalTokens, apiSubscription } from '@store/api/apiUsers';
import { apiUpdateProject } from '@store/api/apiProjects';
import { selectProject } from '@store/selectors/projectSelector';
import {
    LoginActions,
    ProjectActions,
    PromptActions,
    TemplateActions,
    TextCardActions,
    FolderActions,
    TextCardCollectionActions,
} from '@store/actions/actions';
import { IProject } from '@interfaces/api/IProject';
import { selectedPrompt } from '@store/selectors/promptSelector';
import TextPurposeModal from '@components/custom-modals/text-purpose-modal/TextPurposeModal';
import { ITemplate } from '@interfaces/api/ITemplate';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import SaveTextCardModal from '@components/custom-modals/save-textcard-modal/SaveTextCardModal';
import { getUser } from '@store/selectors/userSelector';
import { apiAddFolder } from '@store/api/apiFolders';
import useMediaQuery from '@utils/mediaQueryUtils';
import { setColor } from '@utils/colorUtils';
import LinkButton from '@components/common/custom-button/link-button/LinkButton';
import { apiGetTemplateDefaultPreset } from '@store/api/apiSettings';
import RoundButton from '@components/common/custom-button/round-button/RoundButton';
import LanguageSelector from '@components/custom-selectors/language-selector/LanguageSelector';
import ToneSelector from '@components/custom-selectors/tone-selector/ToneSelector';
import LengthSelector from '@components/custom-selectors/length-selector/LengthSelector';
import TemplateSelector from '@components/custom-selectors/template-selector/TemplateSelector';

const TextGenerator = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [tone, setTone] = useState<string>('Casual');
    const [textLength, setTextLength] = useState<string>('38');
    const allFolders = useSelector(selectAllFolder);
    const allTextCard = useSelector(selectAllTextCard);
    const selectedTextCards = useSelector(selectTextCard);
    const selectedProject = useSelector(selectProject);
    const [currentProject, setCurrentProject] = useState<IProject | undefined>(selectedProject[0]);
    const currentPrompt = useSelector(selectedPrompt);
    const [product, setProduct] = useState<{
        name: string;
        description: string;
    }>({
        name: currentPrompt?.productName || selectedProject[0]?.prompt?.productName,
        description: currentPrompt?.productDescription || selectedProject[0]?.prompt?.productDescription,
    });
    const [projectName, setProjectName] = useState<string>(selectedProject[0]?.project_name || product?.name);
    const [language, setLanguage] = useState<string>(currentPrompt?.language);
    const [editProjectName, setEditProjectName] = useState<boolean>(false);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const selectedTemplate = useSelector(selectTemplate);
    const template = {
        id: selectedTemplate[0]?.id || selectedProject[0]?.template_id || currentPrompt?.templateId,
        name: currentPrompt?.templateName || "",
    };
    const [sliderValue, setSliderValue] = useState<number | string | Array<number | string>>(0);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showTextPurposeModal, setShowTextPurposeModal] = useState<boolean>(false);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [checked, setChecked] = useState<boolean>(false);
    const [openSideBar, setOpenSideBar] = useState<boolean>(true);
    const initialGenerate = allTextCard.length === 0;
    const isMobile = useMediaQuery('(max-width: 900px)');
    const [message, setMessage] = useState('');
    const [focus, setFocus] = useState<boolean>(false)

    useEffect(() => {
        if (isMobile === true) setOpenSideBar(false);
        if (isMobile === false) setOpenSideBar(true);

        return () => {
            setOpenSideBar(false);
        };
    }, [isMobile]);

    useEffect(() => {
        if (selectedTextCards.length === allTextCard.length) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [selectedTextCards]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let cancelFetchingSettings = false;
        if (template?.id) fetchSettings();

        return () => {
            cancelFetchingSettings = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, []);

    useEffect(() => {
        let cancelFetchingSettings = false;
        return () => {
            // generateNewIdea()
            cancelFetchingSettings = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, [language]);

    useEffect(() => {
        let cancelFetchingSettings = false;

        return () => {
            // generateNewIdea()
            cancelFetchingSettings = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, [tone]);

    useEffect(() => {
        let cancelFetchingSettings = false;
        return () => {
            cancelFetchingSettings = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, [textLength]);

    useEffect(() => {
      
        if (currentPrompt.projectId == 0){
            // dispatch({
            //     type: TextCardActions.CLEAR_CARDS,
            //     payload: {},
            // });
        }
    }, [])

    const fetchSettings = async () => {
        let cancelFetchingSettings = false;
        const getSettings = await apiGetTemplateDefaultPreset(template?.id as number);

        if (!cancelFetchingSettings) {
            if (getSettings.error) {
                console.log('GET SETTINGS ERROR!');
            } else {
                setSliderValue(getSettings.data[0]?.temperature);
            }
        }
    };

    const generateNewIdea = async () => {
        if (template.id !== 0) {
            if (currentPrompt.productName !== '') {
                setMessage('');
                setLoading(true);

                const getUserTokens = await apiGetUserTotalTokens(user.email);
                if (getUserTokens.error) {
                    console.log(getUserTokens.error);
                } else {
                    const tokens = getUserTokens.data?.total_tokens;
                    if (tokens > 0) {
                        await createIdeas();
                    } else {
                        if (user.is_subscribed === false) {
                            CustomToast({
                                message: "Good job! You've reached your free version's limit!",
                                ctaLabel: 'Upgrade now',
                                ctaLink: 'https://buy.stripe.com/fZebMcffP7nTdHidQY',
                                onClick: async () => {
                                    const data = JSON.stringify({
                                        is_subscribed: true,
                                    });
                                    const response = await apiSubscription(user.id, data);
                                    if (response.error) {
                                        console.log(response.error);
                                    } else {
                                        dispatch({
                                            type: LoginActions.SET_USER,
                                            payload: response.data,
                                        });
                                    }
                                },
                                timer: 5000,
                                width: '600px',
                            });
                        } else {
                            CustomToast({
                                message:
                                    'Nice job! You generated some significant amount of texts today! Please, contact us - so we can improve your limits! Thank you!',
                                ctaLabel: 'Contact us',
                                ctaLink: 'mailto:maksymreva@madgicx.com',
                                timer: 5000,
                                width: '600px',
                            });
                        }
                    }
                }
            }
            setLoading(false);
        } else {
            setMessage('Please select a template.');
        }
    };

    const createIdeas = async () => {
        let updatedTextCardList: ITextCard[] = [];

        const data = JSON.stringify({
            template_id: template.id,
            template_name: template.name,
            product_name: product?.name,
            product_description: product?.description,
            tone: currentPrompt?.tone,
            keywords: '',
            created_by: user.email,
            temperature: sliderValue,
            language: currentPrompt?.language,
            textLength: currentPrompt?.textLength,
            project_id: currentPrompt?.projectId,
            project_name: product?.name,
            project_content_title: product?.name,
            prompt: currentPrompt,
        });

        const ideas = await apiGenerateIdeas(data);
        if (ideas.error) {
            console.log('IDEAS ERROR!');
        } else {
            updatedTextCardList.push(ideas.data.data);
            const project_id = ideas.data.data[0].project_id;

            dispatch({
                type: PromptActions.EDIT_PROMPT,
                payload: {
                    ...currentPrompt,
                    projectId: project_id,
                    projectName: product?.name,
                },
            });

            dispatch({
                type: TextCardActions.SET_CARDS,
                payload: {
                    data: [
                        ...ideas.data.data.map((s: ITextCard) => {
                            return { ...s, folder_type: 'regular' };
                        }),
                    ],
                },
            });

            dispatch({
                type: TextCardActions.SELECT_CARD,
                payload: { data: ideas.data.data[0] },
            });

            CustomToast({
                message: 'Madgicx Success!',
                variant: 'ALERT_INFO',
                width: '300px',
            });

      
        }
        
    };

    return (
        <>
            <AppHeader>
                <Left>
                    <CustomTooltip
                        title={openSideBar === false ? 'Open Sidebar' : 'Close Sidebar'}
                        placement={'bottom-end'}
                    >
                        {openSideBar === false ? (
                            <BorderlessButton
                                label={
                                    <FlexColumn sx={{ gap: 1 }}>
                                        <Box width={16} height={2} bgcolor={setColor('fc-grey')} />
                                        <Box width={16} height={2} bgcolor={setColor('fc-grey')} />
                                        <Box width={16} height={2} bgcolor={setColor('fc-grey')} />
                                    </FlexColumn>
                                }
                                onClick={() => setOpenSideBar(true)}
                            />
                        ) : (
                            <BorderlessButton
                                label={
                                    <img
                                        src={iconCollections.PLUS.src}
                                        alt={'icon-warning'}
                                        style={{ width: 16, height: 16, transform: 'rotate(45deg)' }}
                                    />
                                }
                                onClick={() => setOpenSideBar(false)}
                            />
                        )}
                    </CustomTooltip>
                </Left>
                <Center>
                    <FlexCenter>
                        {editProjectName ? (
                            <CustomInput
                                value={projectName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setProjectName(e.target.value);
                                }}
                                sx={{
                                    '& input': {
                                        fontSize: { xs: 22, md: 22, lg: 24 },
                                        textAlign: 'center',
                                        width: '100%',
                                        padding: 1,
                                        borderRadius: '4px',
                                        border: '1px solid #e3e5e9',

                                        '&:focus': {
                                            border: '1px solid #006DF0',
                                        },
                                    },
                                    mr: 4,
                                }}
                                onKeypress={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === 'Enter') {
                                        if (projectName.trim() !== '') {
                                            const data = JSON.stringify({
                                                project_name: projectName,
                                            });

                                            const response = await apiUpdateProject(
                                                selectedProject[0]?.id ?? currentProject?.id,
                                                data,
                                            );

                                            if (response.data) {
                                                CustomToast({
                                                    message: 'Project renamed',
                                                    variant: 'ALERT_SUCCESS',
                                                    width: '300px',
                                                });
                                            }
                                        }

                                        setEditProjectName(false);
                                    }
                                }}
                                onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
                                    setEditProjectName(false);
                                }}
                                onFocus={true}
                            />
                        ) : (
                            <H1 sx={{ mr: 4 }}>{projectName}</H1>
                        )}

                        {enableEdit === true && editProjectName === false && (
                            <RoundButton
                                label={
                                    <img
                                        src={iconCollections.EDIT.src}
                                        alt={'icon-edit'}
                                        style={{ width: 14, height: 14 }}
                                    />
                                }
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    setEditProjectName(true);
                                }}
                            />
                        )}
                    </FlexCenter>
                </Center>
                <Right>
                    <BorderlessButton
                        label={<P2 color={'fc-black'}>Go to Library</P2>}
                        onClick={() => {
                            dispatch({
                                type: TextCardCollectionActions.SET_PAGE,
                                payload: { data: 1 },
                            });

                            dispatch({
                                type: ProjectActions.DESELECT_PROJECT,
                                payload: {},
                            });

                            dispatch({
                                type: TextCardActions.CLEAR_SELECTED,
                                payload: {},
                            });

                            dispatch({
                                type: TextCardActions.CLEAR_CARDS,
                                payload: {},
                            });

                            dispatch({
                                type: PromptActions.CLEAR_PROMPT,
                                payload: {},
                            });

                            navigate('/library/');
                        }}
                    />
                </Right>
            </AppHeader>

            {/*
             * Toolbar
             */}

            <AppToolbar>
                <Box
                    sx={{
                        display: 'flex',
                        pl: openSideBar ? '400px' : '0px',
                        justifyContent: 'space-between',
                        flexGrow: 0.5,
                    }}
                >
                    <LanguageSelector
                        sx="50%"
                        label=""
                        language={language}
                        setLanguage={setLanguage}
                    />
                    {/* <LengthSelector
                        category={textLength}
                        setCategory={setTextLength}
                    /> */}
                    <ToneSelector
                        tone={tone}
                        setTone={setTone}

                    />
                </Box>

                <Box
                    sx={{
                        visibility: selectedTextCards?.length ? 'visible' : 'hidden',
                    }}
                >
                    <ToolbarButtonGroup
                        selections={selectedTextCards}
                        menus={['DOWNLOAD', 'SHARE', 'SAVE']}
                        onClear={() => dispatch({ type: TextCardActions.CLEAR_SELECTED })}
                        onClick={menu => {
                            if (menu === 'DOWNLOAD') {
                                const csvData = new Blob(
                                    [selectedTextCards.map(d => d.results.replace(/(\r\n|\n|\r)/gm, '')) + '\n'],
                                    { type: 'text/csv;charset=utf-8;' },
                                );
                                FileSaver.saveAs(csvData, 'data.csv');
                            }

                            if (menu === 'SHARE') {
                                setShowShareModal(true);
                            }

                            if (menu === 'SAVE') {
                                setShowSaveModal(true);
                            }
                        }}
                    />
                </Box>
            </AppToolbar>

            {/*
             * Drawer
             */}

            <AppSidebar open={openSideBar}>
                <AlignCenter sx={{ height: 54, gap: 4, p: 4, flexDirection: 'row-reverse' }}>
                    {/* TODO: Create new component for this */}
                    <TemplateSelector label="Select a template" message={message} setMessage={setMessage} 
                    template_name={template.name}/>
                </AlignCenter>

                <FlexColumn sx={{ p: 4, paddingTop: '30px' }}>
                    <CustomTextArea
                        placeholder="What's your Product name?"
                        value={product?.name}
                        onChange={e => {
                            e.preventDefault();
                            dispatch({
                                type: PromptActions.EDIT_PROMPT,
                                payload: {
                                    ...currentPrompt,
                                    productName: e.target.value,
                                    projectName: e.target.value,
                                },
                            });
                            setProjectName(e.target.value);
                            setProduct({ ...product, name: e.target.value });
                        }}
                        onFocus={() => setFocus(true)}
                        maxLength={100}
                        height={120}
                        sx={{ borderRadius: '4px', border: '1px solid black', boxSizing: 'border-box', 
                        }}
                        row={5}
                    />
                </FlexColumn>
                <FlexColumn sx={{ px: 4, paddingTop: '0' }}>
                    <CustomTextArea
                        placeholder="What's your Product description?"
                        value={product?.description}
                        onChange={e => {
                            e.preventDefault();

                            dispatch({
                                type: PromptActions.EDIT_PROMPT,
                                payload: {
                                    ...currentPrompt,
                                    productDescription: e.target.value,
                                },
                            });

                            setProduct({ ...product, description: e.target.value });
                        }}
                        maxLength={300}
                        height={300}
                        row={14}
                        sx={{ borderRadius: '4px', border: '1px solid black', boxSizing: 'border-box',
                    }}
                    />
                </FlexColumn>

                <JustifyCenter sx={{ p: 4 }}>
                    <DefaultButton
                        sx={{ width: '300px', height: '40px', borderRadius: '8px' }}
                        label={<P2 color={'fc-white'}>Make Madgicx</P2>}
                        loading={loading}
                        disabled={product?.name?.trim() === '' || loading}
                        onClick={generateNewIdea}
                    />
                </JustifyCenter>
            </AppSidebar>

            {/*
             * Content
             */}

            <AppContent open={openSideBar}>
                {allTextCard?.length === 0 && loading === false ? (
                    <MakeMadgicx
                        body={
                            loading
                                ? 'Take the deep breath, can take up to 15 sec...'
                                : 'Please, provide name and description for your idea in left side of the screen.'
                        }
                        onClick={async () => {
                            setLoading(true);

                            await createIdeas();

                            setLoading(false);
                        }}
                        loading={loading}
                        disabled={product?.name?.trim() === '' || loading}
                    />
                ) : (
                    <>
                        {allTextCard.length ? (
                            <Box display="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={checked}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setChecked(e.target.checked);

                                                if (e.target.checked === true) {
                                                    dispatch({
                                                        type: TextCardActions.SELECT_ALL_CARD,
                                                        payload: {},
                                                    });
                                                } else {
                                                    dispatch({
                                                        type: TextCardActions.CLEAR_SELECTED,
                                                        payload: {},
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    label={<P3>{`Select all ( ${selectedTextCards.length} results )`}</P3>}
                                    sx={{ px: 3, m: 0, width: 300 }}
                                />
                                <AppPagination
                                    page={page}
                                    setPage={setPage}
                                    itemCount={allTextCard.length}
                                    onChange={(e, val) => {
                                        setPage(val);
                                    }}
                                />
                            </Box>
                        ) : null}

                        <PresetCardContainer page={page} loading={loading} folderName={product?.name} />
                    </>
                )}
            </AppContent>

            {showShareModal && (
                <ShareLinkModal
                    header={'Share options'}
                    selections={selectedTextCards}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowShareModal(false),
                    }}
                    confirmButton={{
                        label: 'Copy',

                        onConfirm: async (textCardList: ITextCardShare[], uid: string, link: string) => {
                            const data = JSON.stringify({
                                results: textCardList,
                                uid: uid,
                                created_by: user.email,
                            });

                            const response = await apiShareTextCard(data);

                            if (response.error) {
                                console.log(response.error);
                            } else {
                                navigator.clipboard.writeText(link);
                            }
                        },
                    }}
                />
            )}

            {showTextPurposeModal && (
                <TextPurposeModal
                    header={'What type of content do you need?'}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowTextPurposeModal(false),
                    }}
                    confirmButton={{
                        label: 'Next',

                        onConfirm: async (template: ITemplate) => {
                            dispatch({
                                type: TextCardActions.CLEAR_CARDS,
                                payload: {},
                            });
                            /*
                             ** clears all selected template
                             */
                            dispatch({
                                type: TemplateActions.CLEAR_SELECTED,
                                payload: {},
                            });
                            /*
                             ** selects the template
                             */
                            dispatch({
                                type: TemplateActions.SELECT_TEMPLATE,
                                payload: { data: template },
                            });

                            /*
                             ** selects the template
                             */
                            dispatch({
                                type: TemplateActions.SELECT_TEMPLATE,
                                payload: { data: template },
                            });

                            dispatch({
                                type: PromptActions.EDIT_PROMPT,
                                payload: {
                                    ...currentPrompt,
                                    templateId: template.id,
                                    templateName: template.template_name,
                                },
                            });

                            setShowTextPurposeModal(false);

                            CustomToast({
                                message: 'Tool Changed!',
                                variant: 'ALERT_INFO',
                                width: '300px',
                            });
                        },
                    }}
                />
            )}

            {showSaveModal && (
                <SaveTextCardModal
                    folderName={product?.name}
                    header={'Where to save?'}
                    selections={allFolders}
                    loading={saving}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowSaveModal(false),
                    }}
                    confirmButton={{
                        label: 'Save',

                        onConfirm: async (folder, name, option) => {
                            setSaving(true);

                            const selectedTextCardId: number[] = selectedTextCards.map(d => d.id);

                            /*
                             ** Saves on an existing folder
                             */
                            if (option === 'existing') {
                                const moveToFolder = await apiMoveTextCardToFolder(selectedTextCardId, folder);

                                if (moveToFolder.error) {
                                    console.log(moveToFolder.error);
                                } else {
                                    CustomToast({
                                        message: 'Saved!',
                                        ctaLabel: 'Visit the library',
                                        redirectButton: (
                                            <LinkButton
                                                label="Visit the library"
                                                onClick={() => {
                                                    navigate('/library/');
                                                }}
                                                sx={{
                                                    color: setColor('hl-blue'),
                                                    textDecoration: 'underline',
                                                }}
                                            />
                                        ),
                                        timer: 3000,
                                        width: '300px',
                                    });
                                }
                            }
                            /*
                             ** creats a new folder
                             */
                            if (option === 'new') {
                                const data = JSON.stringify({
                                    folder_name: name,
                                    data: {},
                                    user: user.email,
                                });

                                const addFolder = await apiAddFolder(data);

                                if (addFolder.error) {
                                    console.log(addFolder.error);
                                } else {
                                    const moveToFolder = await apiMoveTextCardToFolder(
                                        selectedTextCardId,
                                        addFolder.data.id,
                                    );

                                    if (moveToFolder.error) {
                                        console.log(moveToFolder.error);
                                    } else {
                                        const updatedFolderList = [...allFolders];

                                        updatedFolderList.push(addFolder.data);

                                        dispatch({
                                            type: FolderActions.SET_FOLDER,
                                            payload: { data: updatedFolderList },
                                        });

                                        CustomToast({
                                            message: 'Saved!',
                                            ctaLabel: 'Visit library',
                                            redirectButton: (
                                                <LinkButton
                                                    label="Visit the library"
                                                    onClick={() => {
                                                        navigate('/library/');
                                                    }}
                                                    sx={{
                                                        color: setColor('hl-blue'),
                                                        textDecoration: 'underline',
                                                    }}
                                                />
                                            ),
                                            timer: 3000,
                                            width: '300px',
                                        });
                                    }
                                }
                            }

                            setSaving(false);

                            setShowSaveModal(false);
                        },
                    }}
                />
            )}
        </>
    );
};

export default TextGenerator;
