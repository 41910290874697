import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, MenuItem, SxProps, TextField } from '@mui/material';
import { IFolder } from '@interfaces/api/IFolder';
import { IModalProps } from '@components/custom-modals/IModalProps';
import { H4, P2 } from '@components/common/typography/Typography';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';

const modalStyle: SxProps = {
    '& .MuiPaper-root': {
        width: '500px',
        minWidth: '280px',
        maxWidth: '500px',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};

const MoveModal: React.FC<IModalProps> = ({
    header = 'Add Modal Component',
    selections = [],
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = { label: 'Confirm', onConfirm: (value: string) => {} },
}) => {
    const [value, setValue] = useState<string>('none');
    const [focus, setFocus] = useState<boolean>(false);

    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>

            <DialogContent>
                <TextField
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(e.target.value);
                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                    onBlur={() => {
                        setFocus(false);
                    }}
                    select
                    label=""
                    InputLabelProps={{ shrink: false }}
                    inputProps={{ padding: 0 }}
                    sx={{
                        textAlign: 'left',
                        width: '100%',
                        border: '1px solid #E3E5E9',
                        borderRadius: 1,
                        borderColor: focus ? '#006DF0' : '#E3E5E9',

                        '& .MuiSelect-select': {
                            padding: 1,
                            border: 0,
                        },

                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '0 !important',
                        },
                    }}
                >
                    <MenuItem value={'none'} disabled>
                        <P2>Select a folder</P2>
                    </MenuItem>
                    {selections
                        ?.filter(selection => selection.folder_name !== 'Favorites')
                        .map((selection: IFolder, idx) => (
                            <MenuItem key={idx} value={selection.id}>
                                <P2>{selection.folder_name}</P2>
                            </MenuItem>
                        ))}
                </TextField>
            </DialogContent>

            <DialogActions sx={{ padding: 2 }}>
                <BorderlessButton
                    label={<P2>{cancelButton?.label}</P2>}
                    onClick={() => {
                        cancelButton?.onCancel();
                    }}
                />
                <DefaultButton
                    label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                    disabled={value === 'none'}
                    onClick={() => confirmButton?.onConfirm(value)}
                />
            </DialogActions>
        </Dialog>
    );
};

export default MoveModal;
