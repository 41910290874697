import { Button, SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';

export interface ICustomButtonProps {
    /**
     * optional label of the button
     */
    label?: React.ReactNode | string;
    /**
     * optional is the button disabled
     */
    disabled?: boolean;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * custom style
     */
    sx?: SxProps;
}

const BorderlessButton: React.FC<ICustomButtonProps> = ({
    label = 'MadgicxIQ Borderless Button',
    onClick = () => {},
    disabled = false,
    sx = {},
}) => {
    return (
        <Button
            variant={'contained'}
            onClick={onClick}
            disabled={disabled}
            sx={{
                border: 0,
                minWidth: 0,
                color: setColor('fc-black'),
                backgroundColor: 'transparent !important',
                boxShadow: '0px 3px 4px rgba(0,0,0,0.2)',
                '&:hover': {
                    boxShadow: '0px 3px 4px rgba(0,0,0,0.4)',
                },

                ...sx,
            }}
        >
            {label}
        </Button>
    );
};

export default BorderlessButton;
