import { ICategory } from '@interfaces/api/ICategory';
import { externalFetch, IRequestState } from './apiUtils';

export const apiGetAllCategories = async (): Promise<IRequestState<ICategory[]>> => {
    const response: IRequestState<ICategory[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/categories/`,
    );

    return response;
};
