/*
 **  empty value for a user
 */
export const initialUser = {
    id: 0,
    email: '',
    is_active: false,
    is_banned: false,
    is_superuser: false,
    is_subscribed: false,
    date_joined: '',
    last_login: '',
    phone_number: '',
    total_tokens: 0,
};

/*
 **  empty value for a text card
 */
export const initialTextCard = {
    template_id: 0,
    template_name: '',
    product_name: '',
    product_description: '',
    tone: '',
    keywords: '',
    project_id: 0,
    project_name: '',
};
/*
 **  empty value for a prompt
 */
export const initialPrompt = {
    templateId: 0,
    templateName: '',
    templateIcon: '/assets/default.svg',
    productName: '',
    productDescription: '',
    tone: 'Neutral',
    keywords: '',
    projectId: 0,
    projectName: '',
    language: 'English',
    textLength: '38',
};
