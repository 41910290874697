import IconCollection from '@components/icon-collection/IconCollection';
import { Box, InputBase, SxProps } from '@mui/material';
import { useState } from 'react';

interface ISearchInputProps {
    label?: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    width?: string | number | undefined;
}

const searchInputStyle: SxProps<any> = isFocused => {
    return {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 6px',
        borderRadius: 1,
        border: '1px solid #E3E5E9',
        borderColor: isFocused ? '#006DF0' : '#E3E5E9',
        gap: 1,

        '& .MuiInputBase-root': {
            color: '#0D182F',
            width: '100%',
            padding: 1,

            '& input': {
                fontSize: { xs: 10, sm: 12, md: 14 },
                padding: 0,
            },
            '& input:disabled': {
                WebkitTextFillColor: '#0D182F',
            },
        },
    };
};

const SearchInput: React.FC<ISearchInputProps> = ({ label = '', value = '', onChange = () => {}, width }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
        <Box width={width ?? '100%'} height={52} display={'flex'} alignItems={'center'}>
            <Box sx={{ ...searchInputStyle(isFocused) }}>
                <IconCollection icon={'SEARCH'} size={'md'} />
                <InputBase
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                />
            </Box>
        </Box>
    );
};

export default SearchInput;
