import { initialPrompt } from '@constants/initial';
import { ITemplate } from '@interfaces/api/ITemplate';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { PromptActions } from '@store/actions/actions';
import { apiGetAllTemplates } from '@store/api/apiTemplates';
import { selectedPrompt } from '@store/selectors/promptSelector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface TemplateSelectorOption {
    label?: string;
    message?: string;
    setMessage: (newMessage) => void;
    options?: any;
    template_name?: any;
}

export default function TemplateSelector(props: TemplateSelectorOption) {
    let cancelFetching = false;
    const dispatch = useDispatch();
    const currentPrompt = useSelector(selectedPrompt);
    const [templates, setTemplates] = useState<ITemplate[]>([]);

    const fetchCategoryList = async () => {
        const response = await apiGetAllTemplates();

        if (!cancelFetching) {
            if (response.data) {
                setTemplates(response.data);
            }
        }
    };

    useEffect(() => {
        let cancelFetching = false;

        fetchCategoryList();
        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, []);

    const getSelectedTemplate = (e, value) => {
        if (value && value.id) {
            props.setMessage('');
            const prompt = {
                templateId: value.id,
                templateName: value.template_name,
                projectId: initialPrompt.projectId,
                templateIcon: value.image_path,
                projectName: initialPrompt.projectName,
            };
            dispatch({ type: PromptActions.EDIT_PROMPT, payload: prompt });
        } else {
            const prompt = {
                templateId: initialPrompt.templateId,
                templateName: initialPrompt.templateName,
                projectId: initialPrompt.projectId,
                templateIcon: initialPrompt.templateIcon,
                projectName: initialPrompt.projectName,
            };
            dispatch({ type: PromptActions.EDIT_PROMPT, payload: prompt });
        }
    };
    return (
        <Autocomplete
            onChange={getSelectedTemplate}
            disablePortal
            id="selector-id"
            options={templates}
            sx={{ width: '100%', height: 28 }}
            getOptionLabel={template => template.template_name || ""}
            renderOption={(props, templates) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img loading="lazy" width="20" src={templates?.image_path} alt="" />
                    {templates?.template_name}
                </Box>
            )}
            inputValue={props.template_name}
            value={props.template_name}
            renderInput={params => (
                <TextField
                    sx={{ height: '48px' }}
                    {...params}
                    label={props.label}
                    required
                    size="small"
                    error={props.message !== '' ? true : false}
                    helperText={props.message}
                    placeholder="Template"
                />
            )}
        />
    );
}
