import { initialPrompt } from '@constants/initial';
import { IAction } from '@interfaces/api/IAction';
import { IPrompt } from '@interfaces/api/IPrompt';
import { PromptActions } from '@store/actions/actions';

const prompt = (state: IPrompt = initialPrompt, action: IAction) => {
    switch (action.type) {
        case PromptActions.EDIT_PROMPT: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case PromptActions.CLEAR_PROMPT: {
            return initialPrompt;
        }
        default:
            return state;
    }
};
export default prompt;
