import { ContentFilterActions } from '@store/actions/actions';
import { IAction } from '@interfaces/api/IAction';
import { IContentFilter } from '@interfaces/api/IContentFilter';

const contentFilterReducer = (state: IContentFilter = { message: '' }, action: IAction) => {
    switch (action.type) {
        case ContentFilterActions.MESSAGE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
export default contentFilterReducer;
