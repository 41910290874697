import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { apiGetFilteredTemplates, apiGetPopularTemplates } from '@store/api/apiTemplates';
import { ITemplate } from '@interfaces/api/ITemplate';
import TemplateCard from '@components/custom-cards/template-card/TemplateCard';
import { SKELECTON_COUNT } from '@constants/constants';
import TemplateCardSkeleton from '@components/common/skeleton/template-card-skeleton/TemplateCardSkeleton';
import { IRequestState } from '@store/api/apiUtils';
import { apiGetUserRecentSession } from '@store/api/apiProjects';
import { PromptActions, TemplateActions } from '@store/actions/actions';
import { initialPrompt } from '@constants/initial';
import { useNavigate } from 'react-router-dom';
import { getUser } from '@store/selectors/userSelector';
import { H4 } from '@components/common/typography/Typography';

function TemplateCardContainer(props: {
    currentTab?: 'popular' | 'other';
    category?: any;
    categoryId: number | undefined;
    selectedTemplate?: ITemplate;
    setSelectedTemplate?: React.Dispatch<React.SetStateAction<ITemplate>>;
    searchText: string;
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let cancelFetching = false;

    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const user = useSelector(getUser);

    const fetchFilteredTemplates = async () => {
        setIsLoading(true);

        let response: IRequestState<any>;

        if (props.categoryId === 1) {
            response = await apiGetPopularTemplates();
        } else {
            response = await apiGetFilteredTemplates(props.categoryId);
        }

        setIsLoading(false);

        if (!cancelFetching) {
            if (response.data) {
                setTemplates(response.data);
            }
        }
    };

    useEffect(() => {
        fetchFilteredTemplates();

        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, [props.categoryId]);

    const gotoGeneratorPage = async (template: ITemplate) => {
        dispatch({
            type: TemplateActions.SELECT_TEMPLATE,
            payload: { data: template },
        });

        const response = await apiGetUserRecentSession(template.id, user.email);
        if (response.data) {
            if (response.data.length > 0) {
                const recent_session_data = response.data.map(d => {
                    return {
                        prompt: d.prompt,
                        project_id: d.project_id,
                    };
                });

                const promptData = {
                    templateId: template.id,
                    templateName: template.template_name,
                    templateIcon: template.image_path,
                    projectName: recent_session_data[0].prompt.productName,
                    productName: recent_session_data[0].prompt.productName,
                    productDescription: recent_session_data[0].prompt.productDescription,
                };
                dispatch({ type: PromptActions.EDIT_PROMPT, payload: promptData });
            } else {
                const prompt = {
                    templateId: template.id,
                    templateName: template.template_name,
                    projectId: initialPrompt.projectId,
                    templateIcon: template.image_path,
                    projectName: initialPrompt.projectName,
                    productName: initialPrompt.productName,
                    productDescription: initialPrompt.productDescription,
                };

                dispatch({ type: PromptActions.EDIT_PROMPT, payload: prompt });
            }
        }
        navigate('/generator/');
    };

    const filterTemplatesBySearch = (templates: ITemplate[]): ITemplate[] => {
        /*eslint array-callback-return: ["off"]*/
        const filteredTemplate = templates.filter((template: ITemplate) => {
            if (
                props.searchText === '' ||
                template.template_name.toLowerCase().includes(props.searchText.toLowerCase())
            ) {
                return template;
            }
        });

        return filteredTemplate;
    };

    return (
        <Box width={'100%'} height={'100%'}>
            {props.category?.category_name === 'All' ? (
                <H4 sx={{ mb: 4 }}>Most Popular</H4>
            ) : (
                templates.length !== 0 && <H4 sx={{ mb: 4 }}>{props.category?.category_name}</H4>
            )}

            {isLoading === true &&
                [...Array(SKELECTON_COUNT)].map((e, i) => <TemplateCardSkeleton key={`popular-skeleton-${i}`} />)}

            {isLoading === false && props.currentTab === 'other' && filterTemplatesBySearch(templates).length === 0}

            {isLoading === false && filterTemplatesBySearch(templates).length === 0}

            {isLoading === false &&
                templates.length !== 0 &&
                filterTemplatesBySearch(templates).map(template => {
                    return (
                        <TemplateCard
                            key={template?.id}
                            label={template?.template_name}
                            info={template?.template_information || ''}
                            isSelected={template.id === props.selectedTemplate?.id}
                            onClick={() => {
                                if (props.setSelectedTemplate) {
                                    props.setSelectedTemplate(template);
                                } else {
                                    gotoGeneratorPage(template);
                                }
                            }}
                        />
                    );
                })}
        </Box>
    );
}

export default TemplateCardContainer;
