import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import { apiAccountSubscriptionUpdate, apiGetUsers } from '@store/api/apiUsers';
import { LoginActions } from '@store/actions/actions';
import { useDispatch } from 'react-redux';
import UserDetailModal from './UserDetailModal';
import AppPagination from '@components/layout/app-pagination/AppPagination';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{' '}
            <input
                style={{ width: '500px' }}
                className="form-control"
                value={value || ''}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </span>
    );
}

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    return (
        <input
            className="form-control"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        />
    );
}

function FilterTableComponent(props) {
    const [data, setData] = useState<any>([]);
    const [banStatus, setBanStatus] = useState('');
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const columns = React.useMemo(
        () => [
            {
                Header: 'USER INFORMATION',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'id',
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phone_number',
                    },
                    {
                        Header: 'Status',
                        accessor: 'is_active',
                    },

                    {
                        Header: 'Registered',
                        accessor: 'date_joined',
                    },
                    {
                        Header: 'Last Login',
                        accessor: 'last_login',
                    },
                    {
                        Header: '',
                        accessor: 'is_banned',
                    },
                ],
            },
            {
                Header: 'TOKEN MANAGEMENT',
                columns: [
                    {
                        Header: 'Tokens left today',
                        accessor: 'total_tokens',
                    },
                    {
                        Header: 'Tokens used yerterday',
                        accessor: 'used_tokens_yesterday',
                    },
                    {
                        Header: 'Tokens used last 7 days',
                        accessor: 'used_tokens_7days',
                    },
                    {
                        Header: 'Tokens used last 30 days',
                        accessor: 'used_tokens_30days',
                    },
                    {
                        Header: 'Tokens used lifetime',
                        accessor: 'used_tokens_lifetime',
                    },
                    {
                        Header: 'Subscription',
                        accessor: 'is_subscribed',
                    },
                    {
                        Header: 'Action',
                        accessor: '',
                    },
                ],
            },
        ],
        [],
    );

    useEffect(() => {
        let isCancelled = false;

        const getUsers = async () => {
            const response = await apiGetUsers(page);
            if (!isCancelled) {
                if (response.error) {
                    console.log(response.error);
                } else {
                    const userList = response.data.results.map(user => {
                        return {
                            id: user.id,
                            email: user.email,
                            is_active: user.is_active.toString() === 'true' ? 'Active' : 'Inactive',
                            total_tokens: user.total_tokens,
                            phone_number: user.phone_number,
                            used_tokens_yesterday: user.used_tokens_yesterday,
                            used_tokens_7days: user.used_tokens_7days,
                            used_tokens_30days: user.used_tokens_30days,
                            used_tokens_lifetime: user.used_tokens_lifetime,
                            date_joined: user.date_joined,
                            last_login: user.last_login,
                            is_banned: user.is_banned,
                            is_subscribed: user.is_subscribed,
                        };
                    });
                    setData(userList);
                    setTotalCount(response.data.count);
                }
            }
        };

        getUsers();

        return () => {
            isCancelled = true;
        };
    }, [banStatus, isSubscribed, page]);

    return (
        <>
            <Table
                columns={columns}
                data={data}
                setBanStatus={setBanStatus}
                setIsSubscribed={setIsSubscribed}
                totalCount={totalCount}
            />
            <AppPagination
                page={page}
                setPage={setPage}
                itemCount={totalCount}
                onChange={(e, val) => {
                    setPage(val);
                }}
            />
        </>
    );
}

export default FilterTableComponent;

function Table({ columns, data, setBanStatus, setIsSubscribed, totalCount }) {
    const [editData, setEditData] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>(0);

    const dispatch = useDispatch();

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 2, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
    );

    const handleOnClick = (value, id) => {
        fetch(process.env.REACT_APP_API_URL + `/api/v1/user/${id}/status`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify({
                is_banned: !value,
            }),
        })
            .then(res => res.json())
            .then(res => {
                setBanStatus(res);
            })
            .catch(err => {
                console.log(err.message);
            });
    };

    const handleEdit = async (hasSubscription, id) => {
        const data = JSON.stringify({
            is_subscribed: !hasSubscription,
        });

        const response = await apiAccountSubscriptionUpdate(id, data);

        if (response.error) {
            console.log(response.error);
        } else {
            dispatch({ type: LoginActions.SET_USER, payload: response.data });
            setIsSubscribed(!hasSubscription);
        }
    };

    const handleEditUserData = (tokens, id) => {
        setSelectedId(id);
        setEditData(true);
    };

    const saveNewToken = new_token => {
        fetch(process.env.REACT_APP_API_URL + `/api/v1/account/${selectedId}/token`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + process.env.REACT_APP_API_KEY,
            },
            body: JSON.stringify({
                total_tokens: new_token,
            }),
        })
            .then(response => response.json())
            .then(response => {
                console.log('Successfully Updated');
                setEditData(false);
            })
            .catch(err => {
                console.log(err.message);
            });
        setEditData(false);
    };

    return (
        <div style={{ padding: '50px', fontSize: '14px' }}>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <br />
            <table className="table" {...getTableProps()} style={{ borderTop: '0', fontSize: '12px' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.column.Header === '' && (
                                                <>
                                                    {cell.row.values.is_banned === true ? (
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleOnClick(
                                                                    cell.row.values.is_banned,
                                                                    cell.row.values.id,
                                                                )
                                                            }
                                                        >
                                                            UnBan
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleOnClick(
                                                                    cell.row.values.is_banned,
                                                                    cell.row.values.id,
                                                                )
                                                            }
                                                        >
                                                            Ban
                                                        </Button>
                                                    )}
                                                </>
                                            )}

                                            {cell.column.Header === 'Subscription' && (
                                                <>
                                                    {cell.row.values.is_subscribed === true ? (
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleEdit(
                                                                    cell.row.values.is_subscribed,
                                                                    cell.row.values.id,
                                                                )
                                                            }
                                                        >
                                                            Full
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="secondary"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleEdit(
                                                                    cell.row.values.is_subscribed,
                                                                    cell.row.values.id,
                                                                )
                                                            }
                                                        >
                                                            Limited
                                                        </Button>
                                                    )}
                                                </>
                                            )}

                                            {cell.column.Header === 'Action' && (
                                                <>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={() =>
                                                            handleEditUserData(
                                                                cell.row.values.total_tokens,
                                                                cell.row.values.id,
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </>
                                            )}

                                            {cell.column.Header === 'Registered'
                                                ? moment(cell.row.values.date_joined).format('DD-MMM-YYYY')
                                                : cell.column.Header === 'Last Login'
                                                ? moment(cell.row.values.last_login).format('DD-MMM-YYYY')
                                                : cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {editData && <UserDetailModal show={editData} onSave={saveNewToken} onCancel={() => setEditData(false)} />}
        </div>
    );
}
