import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';

interface ICustomButtonProps {
    /**
     * optional label of the button
     */
    label?: React.ReactNode | string;
    /**
     * optional is the button disabled
     */
    disabled?: boolean;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * if loading
     */
    loading?: boolean;
    /**
     * custom style
     */
    sx?: SxProps;
}

const DefaultButton: React.FC<ICustomButtonProps> = ({
    label = 'MadgicxIQ Default Button',
    onClick = () => {},
    disabled = false,
    loading = false,
    sx = {},
}) => {
    return (
        <LoadingButton
            variant={'contained'}
            onClick={onClick}
            disabled={disabled}
            loading={loading}
            sx={{
                minWidth: 0,
                color: setColor('fc-white'),
                backgroundColor: '#006DF0',
                '&:hover': { backgroundColor: '#0065DE' },
                '&.Mui-disabled': {
                    backgroundColor: '#B3D3FB',
                },

                ...sx,
            }}
        >
            {label}
        </LoadingButton>
    );
};

export default DefaultButton;
