import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { selectAllTextCard } from '@store/selectors/textCardSelector';
import AddFolderModal from '@components/custom-modals/add-folder-modal/AddFolderModal';
import ConfirmModal from '@components/custom-modals/confirm-modal/ConfirmModal';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import { Box, Divider, MenuList } from '@mui/material';
import { iconCollections } from '@components/icon-collection/IconCollection';
import {
    apiAddFolder,
    apiDeleteFolder,
    apiDuplicateFolder,
    apiGetAllFolders,
    apiGetFavoriteFolder,
    apiGetHistoryFolder,
    apiGetOtherFolders,
} from '@store/api/apiFolders';
import { CustomToast } from '@components/common/custom-toast/CustomToast';
import { IFolder } from '@interfaces/api/IFolder';
import { H2 } from '@components/common/typography/Typography';
import RoundButton from '@components/common/custom-button/round-button/RoundButton';
import { SpaceBetween } from '@components/layout/app-flexbox/AppFlexBox';
import { FolderActions, TextCardActions, TextCardCollectionActions } from '@store/actions/actions';
import { getUser } from '@store/selectors/userSelector';
import { ITextCard } from '@interfaces/api/ITextCard';
import { ITEMS_PER_PAGE } from '@constants/constants';
import FolderItem from '@components/folder-item/FolderItem';
import { apiGetTextCardFromFolder } from '@store/api/apiTextCards';
import { selectTextCardCollection } from '@store/selectors/textCardCollectionSelector';

const FolderList = (props: { searchText: string; setIsFavourite: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { searchText, setIsFavourite } = props;

    const dispatch = useDispatch();

    const user = useSelector(getUser);

    const allTextCard = useSelector(selectAllTextCard);

    const [folders, setFolders] = useState<IFolder[]>([]);

    const [clickedHistory, setClickedHistory] = useState<boolean>(false);
    const [historyCount, setHistoryCount] = useState<number>(0);
    const [history, setHistory] = useState<any[]>([]);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const textCardCollection = useSelector(selectTextCardCollection);

    /*
     ** single click event
     */
    const [clickedFolder, setClickedFolder] = useState<IFolder | null | undefined>(null);

    const isClicked = (folder: IFolder) => clickedFolder?.id === folder.id;
    /*
     ** double click event
     */
    const [doubleClickedFolder, setDoubleClickedFolder] = useState<IFolder | null | undefined>(null);

    const isDoubleClicked = (folder: IFolder) => doubleClickedFolder?.id === folder.id;

    let cancelFetching = false;

    const fetchFolderList = async () => {
        const response = await apiGetAllFolders(user.email);

        if (!cancelFetching) {
            if (response.error) {
                console.log(response.error);
            } else {
                setFolders(response.data);

                dispatch({
                    type: FolderActions.SET_FOLDER,
                    payload: { data: response.data },
                });

                if (clickedFolder === null) {
                    if (allTextCard?.length === 0) {
                        const welcomeFolder = response.data?.find(
                            (folder: IFolder) => folder.folder_name === 'Welcome',
                        );

                        if (welcomeFolder) {
                            setClickedHistory(false);
                            setClickedFolder(welcomeFolder);
                            await fetchWelcomeFolder(welcomeFolder);
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        fetchFolderList();

        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, [allTextCard]);

    const fetchWelcomeFolder = async (folder: IFolder) => {
        setClickedHistory(false);
        setClickedFolder(folder);

        const response = await apiGetOtherFolders(folder?.id);

        if (response.data.count === 0) {
        } else {
            dispatch({
                type: TextCardActions.SET_CARDS,
                payload: {
                    data: [
                        ...response.data.results.map((s: ITextCard) => {
                            return { ...s, folder_type: 'regular' };
                        }),
                    ],
                },
            });
        }
    };

    const fetchHistoryFolder = async (pageNum: number = 1) => {
        if (clickedFolder === null) {
            const response = await apiGetHistoryFolder(user.email, ITEMS_PER_PAGE, pageNum);

            if (response.data.count === 0) {
            } else {
                setClickedHistory(true);
                setHistoryCount(response.data.count);
                setHistory(response.data.results);

                dispatch({
                    type: TextCardCollectionActions.SET_CARD_COLLECTION,
                    payload: {
                        data: {
                            ...response.data,
                            page: pageNum,
                        },
                    },
                });

                dispatch({
                    type: TextCardActions.SET_CARDS,
                    payload: {
                        data: [
                            ...response.data.results.map((s: ITextCard) => {
                                return { ...s, folder_type: 'system' };
                            }),
                        ],
                    },
                });
            }
        }
    };

    useEffect(() => {
        fetchHistoryFolder(textCardCollection?.page);

        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, [textCardCollection?.page]);

    const folderList = (): React.ReactNode => {
        return (
            <MenuList sx={{ height: '37vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Divider />
                <FolderItem
                    label={'History'}
                    folder={history}
                    type={'system'}
                    onClick={async () => {
                        setClickedHistory(true);
                        setClickedFolder(null);

                        const response = await apiGetHistoryFolder(user.email, ITEMS_PER_PAGE, 1);

                        if (response.data.count === 0) {
                        } else {
                            setHistory(response.data.results);

                            dispatch({
                                type: TextCardCollectionActions.SET_CARD_COLLECTION,
                                payload: {
                                    data: {
                                        ...response.data,
                                        page: 1,
                                    },
                                },
                            });

                            dispatch({
                                type: TextCardActions.SET_CARDS,
                                payload: {
                                    data: [
                                        ...response.data.results.map((s: ITextCard) => {
                                            return { ...s, folder_type: 'system' };
                                        }),
                                    ],
                                },
                            });
                        }
                    }}
                    count={historyCount}
                    selected={clickedHistory}
                />
                <Divider sx={{ m: '0 !important' }} />
                {folders
                    /*eslint array-callback-return: ["off"]*/
                    .filter(folder => {
                        if (searchText === '') {
                            return folder;
                        } else if (
                            folder.folder_name.toString().toLowerCase().includes(searchText.toString().toLowerCase())
                        ) {
                            return folder;
                        }
                    })
                    .map(folder => {
                        return (
                            <FolderItem
                                key={folder.id}
                                label={folder.folder_name}
                                folder={folder}
                                type={
                                    folder.folder_name === 'Favorites' || folder.folder_name === 'Default folder'
                                        ? 'system'
                                        : 'regular'
                                }
                                onClick={async () => {
                                    setClickedHistory(false);
                                    setClickedFolder(folder);

                                    let response: any;

                                    if (folder.folder_name === 'Favorites') {
                                        setIsFavourite(true);
                                        response = await apiGetFavoriteFolder(user.email);
                                    } else {
                                        setIsFavourite(false);
                                        response = await apiGetTextCardFromFolder(folder.id);
                                    }

                                    if (response.data.count === 0) {
                                        dispatch({
                                            type: TextCardActions.SET_CARDS,
                                            payload: {
                                                data: [],
                                            },
                                        });
                                    } else {
                                        dispatch({
                                            type: TextCardCollectionActions.SET_CARD_COLLECTION,
                                            payload: {
                                                data: {
                                                    ...response.data,
                                                    page: 1,
                                                },
                                            },
                                        });

                                        dispatch({
                                            type: TextCardActions.SET_CARDS,
                                            payload: {
                                                data: [
                                                    ...response.data.results.map((s: ITextCard) => {
                                                        return { ...s, folder_type: 'regular' };
                                                    }),
                                                ],
                                            },
                                        });
                                    }
                                }}
                                onDoubleClick={() => {
                                    if (folder.folder_name === 'Favorites' || folder.folder_name === 'Default folder')
                                        return;

                                    setDoubleClickedFolder(folder);
                                }}
                                onDuplicate={async () => {
                                    const response = await apiDuplicateFolder(folder.id);

                                    if (response.data) {
                                        fetchFolderList();

                                        CustomToast({
                                            message: 'Folder duplicated',
                                            variant: 'ALERT_SUCCESS',
                                            width: '300px',
                                        });
                                    }
                                }}
                                onDelete={() => {
                                    setClickedFolder(folder);
                                    setShowDeleteModal(true);
                                }}
                                onClickAway={() => {
                                    setClickedFolder(null);
                                    setDoubleClickedFolder(null);
                                }}
                                onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === 'Enter') {
                                        // TODO add saving when folder is renamed

                                        setClickedFolder(null);
                                        setDoubleClickedFolder(null);
                                    }
                                }}
                                count={folder.total_results}
                                selected={isClicked(folder) === true}
                                renaming={isDoubleClicked(folder) === true}
                            />
                        );
                    })}
            </MenuList>
        );
    };

    return (
        <>
            <Box width={'100%'} height={'47vh'} py={4}>
                <SpaceBetween sx={{ px: 4 }}>
                    <H2 color={'fc-black'}>Folders</H2>
                    <CustomTooltip title={'Create a new folder'} placement={'left'}>
                        <RoundButton
                            label={
                                <img
                                    src={iconCollections.PLUS.src}
                                    alt={'icon-warning'}
                                    style={{ width: 16, height: 16 }}
                                />
                            }
                            onClick={async () => {
                                setShowAddModal(true);
                            }}
                        />
                    </CustomTooltip>
                </SpaceBetween>

                {folderList()}
            </Box>

            {showAddModal && (
                <AddFolderModal
                    header={'Create new folder:'}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowAddModal(false),
                    }}
                    confirmButton={{
                        label: 'Save',
                        onConfirm: async (value: string) => {
                            if (value.trim() === '') {
                                CustomToast({
                                    message: 'Please input a folder name',
                                    variant: 'ALERT_WARNING',
                                    width: '500px',
                                });
                            } else {
                                const data = JSON.stringify({
                                    folder_name: value,
                                    data: {},
                                    user: user.email,
                                });

                                await apiAddFolder(data);

                                fetchFolderList();

                                CustomToast({
                                    message: 'Folder created',
                                    variant: 'ALERT_SUCCESS',
                                    width: '300px',
                                });

                                setShowAddModal(false);
                            }
                        },
                    }}
                />
            )}

            {showDeleteModal && (
                <ConfirmModal
                    header={'Are you sure?'}
                    body={`Are you sure you want to delete "${clickedFolder?.folder_name}"?`}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowDeleteModal(false),
                    }}
                    confirmButton={{
                        label: 'Delete',
                        onConfirm: async () => {
                            await apiDeleteFolder(clickedFolder?.id!);

                            fetchFolderList();

                            CustomToast({
                                message: 'Folder deleted',
                                variant: 'ALERT_INFO',
                                width: '300px',
                            });

                            setShowDeleteModal(false);
                        },
                    }}
                />
            )}
        </>
    );
};
export default FolderList;
