import { ILanguages } from '@interfaces/api/ILanguages';
import { externalFetch, IRequestState } from './apiUtils';

export const apiGetLanguages = async (): Promise<IRequestState<ILanguages[]>> => {
    const response: IRequestState<ILanguages[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/languages`,
    );

    return response;
};
