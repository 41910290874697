import { IAppState } from '@interfaces/api/IAppState';
import { ITextCard } from '@interfaces/api/ITextCard';
import { createSelector } from 'reselect';

export const selectTextCard = createSelector(
    (state: IAppState) => state.textCards,
    (textCards: ITextCard[]) => textCards.filter(f => f.selected),
);

export const selectAllTextCard = createSelector(
    (state: IAppState) => state.textCards,
    (textCards: ITextCard[]) => textCards,
);
