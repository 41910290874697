import { Dialog, DialogActions, DialogContent, DialogContentText, SxProps } from '@mui/material';
import { IModalProps } from '@components/custom-modals/IModalProps';
import { H4, P2 } from '@components/common/typography/Typography';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';

const modalStyle: SxProps = {
    '& .MuiPaper-root': {
        width: '500px',
        minWidth: '280px',
        maxWidth: '500px',
    },

    '& .MuiDialogContent-root': {
        textAlign: 'center',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};

const ConfirmModal: React.FC<IModalProps> = ({
    header = 'Confirm Modal Component',
    body = 'Confirm Modal Body',
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = { label: 'Confirm', onConfirm: () => {} },
}) => {
    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <P2>{body}</P2>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <BorderlessButton
                    label={<P2 color={'fc-black'}>{cancelButton?.label}</P2>}
                    onClick={cancelButton?.onCancel}
                />
                <DefaultButton
                    label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                    onClick={() => confirmButton?.onConfirm()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
