import IconCollection from '@components/icon-collection/IconCollection';
import { AlignCenter, FlexColumn } from '@components/layout/app-flexbox/AppFlexBox';
import { Box, SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';
import React, { ReactText } from 'react';
import { Slide, toast, ToastOptions } from 'react-toastify';
import LinkButton from '../custom-button/link-button/LinkButton';
import { P2 } from '../typography/Typography';

/*
  TODO add a call to action and link to toast options
*/

export type ToastType = 'ALERT_INFO' | 'ALERT_SUCCESS' | 'ALERT_WARNING';

interface IToastProps {
    message: string;
    variant?: ToastType;
    ctaLabel?: string;
    ctaLink?: string;
    redirectButton?: React.ReactNode | undefined;
    onClick?: () => void;
    timer?: number;
    width?: string;
}

export const toastOptions: ToastOptions = {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    style: {
        margin: '0',
    },
};

const toastStyle: SxProps<any> = (variant: ToastType) => {
    switch (variant) {
        case 'ALERT_SUCCESS':
            return { borderColor: '#236A3A' };
        case 'ALERT_WARNING':
            return { borderColor: '#FFB70A' };
        case 'ALERT_INFO':
        default:
            return { borderColor: '#064EA5' };
    }
};

toast.configure();

const Toast: React.FC<IToastProps> = ({ message, variant, ctaLabel, ctaLink, redirectButton, onClick }) => {
    return (
        <Box
            sx={{
                /*
          default style for all variants
        */
                padding: 0,
                margin: 0,
                borderLeft: '4px solid #064EA5',
                /*
          variant custom styling
        */
                ...toastStyle(variant),
            }}
        >
            <FlexColumn sx={{ p: 2 }}>
                <AlignCenter sx={{ width: 'auto', mr: 1 }}>
                    {variant && <IconCollection icon={variant} size={'xl'} sx={{ mr: 2 }} />}

                    <P2 sx={{ mr: 2 }}>
                        {ctaLabel === 'Upgrade now' ? (
                            <>
                                {`${message} Please, upgrade your account to continue using AI Copywriter or`}

                                <LinkButton
                                    label="contact us"
                                    href="mailto:maksymreva@madgicx.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        color: setColor('hl-blue'),
                                        textDecoration: 'underline',
                                    }}
                                />

                                {`if you have any questions`}
                            </>
                        ) : (
                            message
                        )}

                        {redirectButton}
                    </P2>
                </AlignCenter>
                {ctaLink && (
                    <Box sx={{ mt: 2 }}>
                        <LinkButton
                            onClick={onClick}
                            label={ctaLabel}
                            href={ctaLink}
                            sx={{
                                color: setColor('fc-white'),
                                bgcolor: '#7644c2 !important',

                                '&:hover': {
                                    boxShadow: ' 0 3px 6px rgba(0, 0, 0, 0.3)',
                                },
                            }}
                        />
                    </Box>
                )}
            </FlexColumn>
        </Box>
    );
};

export const CustomToast = (params: IToastProps): ReactText => {
    toastOptions.style = { ...toastOptions.style, width: params.width };
    toastOptions.autoClose = params.timer || 3000;

    return toast(
        <Toast
            message={params.message}
            variant={params.variant}
            ctaLabel={params?.ctaLabel}
            ctaLink={params?.ctaLink}
            redirectButton={params?.redirectButton}
            onClick={params?.onClick}
        />,
        toastOptions,
    );
};

export default CustomToast;
