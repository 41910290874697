import Moment from 'react-moment';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';
import 'react-toastify/dist/ReactToastify.css';
import PopOverMenu, { PopOverMenuOptions } from '@components/common/popover-menu/PopOverMenu';
import CustomToast from '@components/common/custom-toast/CustomToast';
import {
    apiDeleteProject,
    apiDuplicateProject,
    apiGetAllProjects,
    apiGetProject,
    apiUpdateProject,
} from '@store/api/apiProjects';
import { IProject } from '@interfaces/api/IProject';
import { Box, MenuItem, MenuList } from '@mui/material';
import CustomInput from '@components/common/custom-input/CustomInput';
import { H2, P2, Caption } from '@components/common/typography/Typography';
import { FlexBox } from '@components/layout/app-flexbox/AppFlexBox';
import { ProjectActions, PromptActions, TextCardActions, TextCardCollectionActions } from '@store/actions/actions';
import { getUser } from '@store/selectors/userSelector';
import { ITextCard } from '@interfaces/api/ITextCard';

const ProjectList = () => {
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const user = useSelector(getUser);

    const [projects, setProjects] = useState<IProject[]>([]);
    const [renameProject, setRenameProject] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>('');

    const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

    let cancelFetching = false;

    const fetchProjectList = async () => {
        const response = await apiGetAllProjects(user.email);

        if (!cancelFetching) {
            if (response.error) {
                console.log(response.error);
            } else {
                setProjects(response.data);

                dispatch({
                    type: ProjectActions.SET_PROJECT,
                    payload: { data: response.data },
                });
            }
        }
    };

    useEffect(() => {
        fetchProjectList();

        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, []);

    const handleSelectedMenu = async (menu: PopOverMenuOptions, project: IProject) => {
        if (menu === 'NEW_TAB') {
            const response = await apiGetProject(project.id);

            if (response.error) {
                console.log(response.error);
            } else {
                if (response.data.length !== 0) {
                    dispatch({
                        type: TextCardActions.CLEAR_CARDS,
                        payload: {},
                    });

                    dispatch({
                        type: ProjectActions.SELECT_PROJECT,
                        payload: { data: project },
                    });

                    // TODO re-map data on open in new tab
                    window.open('/generator/', '_blank');
                }
            }
        }

        if (menu === 'DELETE') {
            const response = await apiDeleteProject(project.id);

            if (response.error) {
                console.log(response.error);
            } else {
                fetchProjectList();

                CustomToast({
                    message: 'Project deleted',
                    variant: 'ALERT_INFO',
                    width: '300px',
                });
            }
        }

        if (menu === 'DUPLICATE') {
            const data = JSON.stringify({
                project_id: project.id,
                user: user.email,
            });

            const response = await apiDuplicateProject(project.id, data);

            if (response.error) {
                console.log(response.error);
            } else {
                fetchProjectList();

                CustomToast({
                    message: 'Project duplicated',
                    variant: 'ALERT_SUCCESS',
                    width: '300px',
                });
            }
        }

        if (menu === 'RENAME') {
            setRenameProject(true);
        }
    };

    return (
        <>
            <Box px={4} py={2}>
                <H2 color={'fc-black'}>Recent Projects</H2>
            </Box>
            <MenuList sx={{ width: '100%', overflowY: 'auto' }}>
                {projects?.map(project => {
                    return (
                        <MenuItem
                            key={project.id}
                            sx={{
                                px: 4,
                                py: 2,
                                width: '100%',
                                display: 'flex',

                                justifyContent: 'space-between',
                                '& .action-buttons': {
                                    display: 'none',
                                },

                                '&:hover': {
                                    '& .action-buttons': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        gap: 1,
                                    },
                                },
                            }}
                            disableRipple={true}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                onClick={async () => {
                                    if (renameProject) return;

                                    const response = await apiGetProject(project.id);
                                    if (response.data) {

                                        dispatch({
                                            type: TextCardActions.SET_CARDS,
                                            payload: {
                                                data: [
                                                    ...response.data.map((s: ITextCard) => {
                                                        return { ...s, folder_type: 'regular'};
                                                    }),
                                                ],
                                            },
                                        });  

                                        dispatch({
                                            type: TextCardCollectionActions.SET_PAGE,
                                            payload: { data: 1 },
                                        });

                                        dispatch({
                                            type: ProjectActions.SELECT_PROJECT,
                                            payload: { data: project },
                                        });

                                        dispatch({
                                            type: PromptActions.EDIT_PROMPT,
                                            payload: {
                                                projectId: project.id,
                                                projectName: project.project_name,
                                                productName: response.data[0]?.prompt?.productName,
                                                productDescription: response.data[0]?.prompt?.productDescription,
                                                templateId: project.template_id,
                                                templateName: project.template_name,
                                                language: response.data[0]?.prompt?.language,
                                                textLength: response.data[0]?.prompt?.textLength,
                                                tone: response.data[0]?.prompt?.tone,
                                            },
                                        });

                                        navigate('/generator/');
                                    }
                                }}
                            >
                                <FlexBox>
                                    <P2 color={'hl-blue'}>&#8288;{project.template_name}</P2>
                                    <P2>&nbsp;/&nbsp;{project.project_name}</P2>
                                </FlexBox>

                                {renameProject && selectedProject?.id === project.id && (
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            setProjectName('');
                                            setSelectedProject(null);
                                            setRenameProject(false);
                                        }}
                                    >
                                        <Box sx={{ py: 1 }}>
                                            <CustomInput
                                                placeholder={project.project_name}
                                                value={projectName}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setProjectName(e.target.value);
                                                }}
                                                onKeypress={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if (e.key === 'Enter') {
                                                        if (projectName.trim() !== '') {
                                                            const data = JSON.stringify({
                                                                project_name: projectName,
                                                            });

                                                            const response = await apiUpdateProject(project.id, data);

                                                            if (response.error) {
                                                                console.log(response.error);
                                                            } else {
                                                                fetchProjectList();

                                                                CustomToast({
                                                                    message: 'Project renamed',
                                                                    variant: 'ALERT_SUCCESS',
                                                                    width: '300px',
                                                                });
                                                            }
                                                        }

                                                        setSelectedProject(null);
                                                        setRenameProject(false);
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </ClickAwayListener>
                                )}

                                <>
                                    <Caption color={'fc-grey'}>
                                        <Moment unix format="(HH:MM - DD/MM/YYYY)">
                                            {project.created_at}
                                        </Moment>
                                    </Caption>
                                </>

                                <>
                                    <Caption>{project.total_results} results &nbsp;</Caption>
                                    <Caption color={'fc-grey'}>({project.saved_results} saved)</Caption>
                                </>
                            </Box>

                            <Box className={'action-buttons'}>
                                <PopOverMenu
                                    menus={['DELETE', 'DUPLICATE', 'NEW_TAB', 'RENAME']}
                                    tooltip={{
                                        title: 'Project options',
                                        placement: 'top',
                                    }}
                                    onClick={(menu: PopOverMenuOptions) => {
                                        setSelectedProject(project);

                                        handleSelectedMenu(menu, project);
                                    }}
                                />
                            </Box>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </>
    );
};

export default ProjectList;
