import { SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';
import { SpaceBetween } from '@components/layout/app-flexbox/AppFlexBox';

interface IToolbarProps {
    /**
     * toolbar children
     */
    children?: React.ReactNode;
    /**
     * custom styling
     */
    sx?: SxProps;
}

const toolbarStyle: SxProps<any> = {
    top: 55,
    height: 55,
    px: 4,
    position: 'fixed',
    backgroundColor: setColor('bg-grey'),
    borderBottom: '1px solid #E9E9E9',

    zIndex: theme => theme.zIndex.drawer,
};

const AppToolbar: React.FC<IToolbarProps> = ({ children, sx }) => {
    return <SpaceBetween sx={{ ...toolbarStyle, ...sx }}>{children}</SpaceBetween>;
};

export default AppToolbar;
