import { useEffect } from 'react';
import { Box } from '@mui/material';
import IconCollection, { IconCollections } from '@components/icon-collection/IconCollection';
import { P1 } from '@components/common/typography/Typography';
import { setColor } from '@utils/colorUtils';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';

export interface IInputOverLayProps {
    /*
     * label of overlay, default is "copied"
     */
    label?: string;
    /*
     * icon on the right side of the text
     */
    icon?: IconCollections;
    /*
     * optional timer as to when the overlay disappears
     */
    timeout?: number;
    /*
     * flag to show the overlay
     */
    showOverlay?: boolean;
    /*
     * controls the hide and show of the overlay
     */
    setShowOverlay?: React.Dispatch<React.SetStateAction<boolean>>;
    /*
     * on click event
     */
    onClick?: () => void;
    /*
     * children of the component
     */
    children?: React.ReactNode;
}

const InputOverlay: React.FC<IInputOverLayProps> = ({
    icon = 'ROUND_CHECK',
    label = 'Copied',
    timeout = 2000,
    showOverlay = false,
    setShowOverlay,
    children = <></>,
    onClick = () => {},
}) => {
    useEffect(() => {
        let timer = setTimeout(() => {
            if (setShowOverlay) setShowOverlay(false);
        }, timeout);

        return () => {
            clearTimeout(timer);
        };
    }, [showOverlay]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                zIndex: 3,
            }}
            onClick={() => {
                onClick();
            }}
        >
            {children}

            {/* Shows the overlay */}
            {showOverlay ? (
                <FlexCenter
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        gap: 1,
                        borderRadius: 1,
                        backgroundColor: setColor('ol-green'),
                        zIndex: 3,
                    }}
                >
                    <IconCollection icon={icon} size={'md'} />
                    <P1 color={'fc-white'}>{label}</P1>
                </FlexCenter>
            ) : null}
        </Box>
    );
};

export default InputOverlay;
