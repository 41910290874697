import { useEffect, useState } from 'react';
import AddItem from './AddItem';
import trash from '@icons/trash.svg';
import editSmall from '@icons/edit-small.svg';
import ClickAwayListener from 'react-click-away-listener';
import { apiUpdateCategory, apiGetCategories } from '@store/api/apiSettings';
import { ICategory } from '@interfaces/api/ICategory';

const textStyle = {
    width: '300px',
    height: '20px',
    overflow: 'hidden',
    outline: 'none',
};

const CategoryList = () => {
    const [category, setCategories] = useState<ICategory[]>([]);
    const [editValue, setEditValue] = useState<boolean>(false);
    const [editableId, setEditableId] = useState<number>(0);
    const [newValue, setNewValue] = useState('');

    const addCategory = category_name =>
        setCategories([...category, { category_name: category_name, isSelected: false }]);

    const getCategories = async () => {
        const response = await apiGetCategories();
        if (response.error) {
            console.log(response.error);
        } else {
            response.data.map(category => {
                return {
                    id: category.id,
                    category_name: category.category_name,
                };
            });
            setCategories(response.data);
        }
    };

    useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            getCategories();
        }

        return () => {
            isCancelled = true;
        };
    }, [editValue]);

    const toggleTask = () => {
        const newCategories = [...category];
        setCategories(newCategories);
    };

    const removeTemplate = async (index, id, category_name) => {
        const newTemplates = [...category];
        newTemplates.splice(index, 1);
        setCategories(newTemplates);

        const data = JSON.stringify({
            is_deleted: true,
            category_name: category_name,
        });

        const response = await apiUpdateCategory(id, data);
        if (response.error) {
            console.log(response.error);
        } else {
            // do nothing
        }
    };

    const editCategory = (index, id, category_name) => {
        setEditValue(true);
        setEditableId(id);
        setNewValue(category_name);
    };

    const handleOnKeyPress = async e => {
        var code = e.keyCode || e.charCode;

        if (code === 13) {
            e.preventDefault();

            const data = JSON.stringify({
                category_name: newValue,
            });

            const response = await apiUpdateCategory(editableId, data);
            if (response.error) {
                console.log(response.error);
            } else {
                setEditValue(false);
            }
        }
    };

    return (
        <>
            <div>
                {category.map((category, index) => (
                    <div className="settings" key={index}>
                        {editValue && editableId === category.id ? (
                            <ClickAwayListener onClickAway={() => setEditValue(false)}>
                                <textarea
                                    style={{ ...textStyle, resize: 'none' }}
                                    onChange={event => setNewValue(event.target.value)}
                                    onKeyPress={event => handleOnKeyPress(event)}
                                    value={newValue}
                                >
                                    {newValue}
                                </textarea>
                            </ClickAwayListener>
                        ) : (
                            <>
                                <span onClick={toggleTask} key={index}>
                                    {category.category_name}
                                </span>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <div onClick={() => editCategory(index, category.id, category.category_name)}>
                                        <img src={editSmall} alt="img-edit-small" />
                                    </div>
                                    <div onClick={() => removeTemplate(index, category.id, category.category_name)}>
                                        <img src={trash} alt="img-trash" />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
                <AddItem addItem={addCategory} data={newValue} type="category" dataId="" />
            </div>
        </>
    );
};

export default CategoryList;
