import { Button, SxProps } from '@mui/material';

interface ICustomButtonProps {
    /**
     * optional label of the button
     */
    label?: React.ReactNode | string;
    /**
     * optional is the button disabled
     */
    disabled?: boolean;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * custom style
     */
    sx?: SxProps;
}

const RoundButton: React.FC<ICustomButtonProps> = ({
    label = 'MadgicxIQ Pill Button',
    onClick = () => {},
    disabled = false,
    sx = {},
}) => {
    return (
        <Button
            variant={'contained'}
            onClick={onClick}
            disabled={disabled}
            sx={{
                padding: 2,
                width: 24,
                height: 24,
                minWidth: 0,
                border: 0,
                borderRadius: '100%',
                backgroundColor: 'transparent !important',
                boxShadow: '0px 3px 4px rgba(0,0,0,0.1)',
                '&:hover': {
                    boxShadow: '0px 3px 4px rgba(0,0,0,0.4)',
                },

                ...sx,
            }}
        >
            {label}
        </Button>
    );
};

export default RoundButton;
