import { Box, SxProps } from '@mui/material';

export const AlignStart: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box className={className} sx={{ width: '100%', display: 'flex', alignItems: 'flex-start', ...sx }}>
            {children}
        </Box>
    );
};

export const AlignCenter: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box className={className} sx={{ width: '100%', display: 'flex', alignItems: 'center', ...sx }}>
            {children}
        </Box>
    );
};

export const AlignEnd: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box className={className} sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', ...sx }}>
            {children}
        </Box>
    );
};

export const FlexBox: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const FlexCenter: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const FlexColumn: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const JustifyCenter: React.FC<any> = (props: {
    children?: React.ReactNode;
    className?: string;
    sx?: SxProps;
}) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const JustifyStart: React.FC<any> = (props: {
    children?: React.ReactNode;
    className?: string;
    sx?: SxProps;
}) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const JustifyEnd: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const SpaceBetween: React.FC<any> = (props: {
    children?: React.ReactNode;
    className?: string;
    sx?: SxProps;
}) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const SpaceAround: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export const SpaceEvenly: React.FC<any> = (props: { children?: React.ReactNode; className?: string; sx?: SxProps }) => {
    const { children, className, sx } = props;
    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};
