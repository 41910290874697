import { IProject } from '@interfaces/api/IProject';
import { externalFetch, IRequestState } from './apiUtils';

export const apiGenerateIdeas = async (data: string): Promise<IRequestState<string[]>> => {
    const response: IRequestState<string> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/gpt3/results/ai?$`,
    );

    return response;
};

export const apiSaveIdeas = async (data: string): Promise<IRequestState<IProject[]>> => {
    const response: IRequestState<IProject[]> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/project/results/`,
    );

    return response;
};

export const apiGetIdeas = async (
    projectId: number | undefined,
    user: string | null,
): Promise<IRequestState<IProject[]>> => {
    const response: IRequestState<IProject[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/projects/results/?project_id=${projectId}&user=${user}`,
    );

    return response;
};
