import { SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';
import { FlexBox, JustifyCenter, JustifyEnd, JustifyStart } from '@components/layout/app-flexbox/AppFlexBox';

export const Left: React.FC<React.ReactNode> = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return <JustifyStart>{children}</JustifyStart>;
};

export const Right: React.FC<React.ReactNode> = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return <JustifyEnd>{children}</JustifyEnd>;
};

export const Center: React.FC<React.ReactNode> = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return <JustifyCenter>{children}</JustifyCenter>;
};

const headerStyle: SxProps<any> = {
    height: 55,
    minHeight: 55,
    px: 5,
    position: 'fixed',
    bgcolor: setColor('bg-white'),
    borderBottom: '1px solid #E9E9E9',

    zIndex: theme => theme.zIndex.drawer,
};

const AppHeader: React.FC<React.ReactNode> = (props: { children?: React.ReactNode }) => {
    const { children } = props;
    return <FlexBox sx={{ ...headerStyle }}>{children}</FlexBox>;
};

export default AppHeader;
