import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function UserDetailModal(props) {
    const [newToken, setNewToken] = useState<number>(0);

    const handleOnChange = e => {
        setNewToken(e.target.value);
    };

    return (
        <Modal show={props.show} animation={false} centered>
            <Modal.Header>
                <Modal.Title>Update Token Limit</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="contentType">
                    <Form.Label>New Token Limit:</Form.Label>
                    <Form.Control size="sm" type="text" onChange={event => handleOnChange(event)} autoFocus />
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="default" className="box-shadow" size="sm" onClick={() => props.onCancel()}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={() => props.onSave(newToken)}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UserDetailModal;
