import { IProject } from '@interfaces/api/IProject';
import { externalFetch, IRequestState } from './apiUtils';

export const apiAddProject = async (data: string): Promise<IRequestState<IProject>> => {
    const response: IRequestState<IProject> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/projects/list/`,
    );

    return response;
};

export const apiGetAllProjects = async (user: string | null): Promise<IRequestState<IProject[]>> => {
    const response: IRequestState<IProject[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/projects/list/user/${user}`,
    );

    return response;
};

export const apiGetProject = async (project_id: number | null): Promise<IRequestState<IProject[]>> => {
    const response: IRequestState<IProject[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/projects/results/${project_id}`,
    );

    return response;
};

export const apiUpdateProject = async (
    project_id: number | null | undefined,
    data: string,
): Promise<IRequestState<IProject>> => {
    const response: IRequestState<IProject> = await externalFetch(
        { method: 'PUT', data },
        process.env.REACT_APP_API_URL + `/api/v1/project/${project_id}/`,
    );

    return response;
};

export const apiDuplicateProject = async (
    project_id: number | null,
    data: string,
): Promise<IRequestState<IProject>> => {
    const response: IRequestState<IProject> = await externalFetch(
        { method: 'POST', data },
        process.env.REACT_APP_API_URL + `/api/v1/project/${project_id}/`,
    );

    return response;
};

export const apiDeleteProject = async (project_id: number | null): Promise<IRequestState<IProject>> => {
    const response: IRequestState<IProject> = await externalFetch(
        { method: 'DELETE' },
        process.env.REACT_APP_API_URL + `/api/v1/project/${project_id}/`,
    );

    return response;
};

export const apiGetUserRecentSession = async (
    template_id: number | undefined,
    email: string | null,
): Promise<IRequestState<IProject[]>> => {
    const response: IRequestState<IProject[]> = await externalFetch(
        {},
        process.env.REACT_APP_API_URL + `/api/v1/user/recent-session/?template_id=${template_id}&email=${email}`,
    );

    return response;
};
