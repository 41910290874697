import { IAction } from '@interfaces/api/IAction';
import { ITemplate } from '@interfaces/api/ITemplate';
import { TemplateActions } from '@store/actions/actions';

export const templateReducer = (state: ITemplate[] = [], action: IAction) => {
    switch (action.type) {
        case TemplateActions.SET_TEMPLATE: {
            return [...action.payload.data];
        }
        case TemplateActions.SELECT_TEMPLATE: {
            const { template_id, id } = action.payload.data;

            return [
                ...state.map(s => {
                    return s.id === template_id || s.id === id ? { ...s, selected: !s.selected } : { ...s };
                }),
            ];
        }
        case TemplateActions.CLEAR_SELECTED: {
            return [
                ...state.map(s => {
                    return { ...s, selected: false };
                }),
            ];
        }
        case TemplateActions.CLEAR_TEMPLATE: {
            return [];
        }
        default:
            return state;
    }
};
export default templateReducer;
