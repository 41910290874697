import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextCard from '@components/custom-cards/text-card/TextCard';
import { Box } from '@mui/material';
import { selectAllTextCard, selectTextCard } from '@store/selectors/textCardSelector';
import { IAppState } from '@interfaces/api/IAppState';
import { ITextCard, ITextCardShare } from '@interfaces/api/ITextCard';
import MoveModal from '@components/custom-modals/move-modal/MoveModal';
import { IFolder } from '@interfaces/api/IFolder';
import { selectAllFolder } from '@store/selectors/folderSelector';
import {
    apiDeleteTextCard,
    apiFavoriteTextCard,
    apiMoveTextCardToFolder,
    apiShareTextCard,
    apiUpdateTextCard,
} from '@store/api/apiTextCards';
import CustomToast from '@components/common/custom-toast/CustomToast';
import ShareLinkModal from '@components/custom-modals/share-link-modal/ShareLinkModal';
import FileSaver from 'file-saver';
import ConfirmModal from '@components/custom-modals/confirm-modal/ConfirmModal';
import EditTextCardModal from '@components/custom-modals/edit-textcard-modal/EditTextCardModal';
import { TextCardActions } from '@store/actions/actions';

function TextCardContainer(props: { user: string | null; searchText: string; isFavourite: boolean }) {
    const { user, searchText, isFavourite } = props;

    const dispatch = useDispatch();

    const allFolders = useSelector<IAppState, IFolder[]>(selectAllFolder);
    const selectedTextCard = useSelector(selectTextCard);
    const allTextCard = useSelector<IAppState, ITextCard[]>(selectAllTextCard);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    return (
        <Box width={'100%'} height={'100%'} padding={5} bgcolor={'#F6F6F8'}>
            <Box sx={{ columnCount: { xs: 1, lg: 2, xl: 3 } }}>
                {allTextCard
                    /*eslint array-callback-return: ["off"]*/
                    .filter(textCard => {
                        if (searchText === '') {
                            return textCard;
                        } else if (
                            textCard.results.toString().toLowerCase().includes(searchText.toString().toLowerCase())
                        ) {
                            return textCard;
                        }
                    })
                    .filter(textCard => {
                        if (isFavourite) {
                            return textCard.is_starred === true;
                        } else {
                            return textCard;
                        }
                    })
                    .map((textCard, i) => {
                        return (
                            <div key={`text-card-wrapper-${i}`}>
                                <TextCard
                                    textCard={textCard}
                                    isSelected={!!textCard.id && selectedTextCard.some(s => s.id === textCard?.id)}
                                    onClick={() => {
                                        dispatch({
                                            type: TextCardActions.SELECT_CARD,
                                            payload: { data: textCard },
                                        });
                                    }}
                                    onEdit={() => {
                                        // Clears all of the selected.
                                        dispatch({
                                            type: TextCardActions.CLEAR_SELECTED,
                                        });
                                        // Selects the last selected card.
                                        dispatch({
                                            type: TextCardActions.SELECT_CARD,
                                            payload: { data: textCard },
                                        });

                                        setShowEditModal(true);
                                    }}
                                    onMove={() => {
                                        if (!textCard.selected) {
                                            dispatch({
                                                type: TextCardActions.SELECT_CARD,
                                                payload: { data: textCard },
                                            });
                                        }

                                        setShowMoveModal(true);
                                    }}
                                    onToggleFavorite={async () => {
                                        const data = JSON.stringify({
                                            is_starred: !textCard.is_starred,
                                        });

                                        const response = await apiFavoriteTextCard(textCard.id, data);

                                        if (response.error) {
                                            console.log(response.error);
                                        } else {
                                            dispatch({
                                                type: TextCardActions.ADD_TO_FAVOURITES,
                                                payload: { data: response.data },
                                            });

                                            let msg = '';
                                            if (response.data.is_starred) {
                                                msg = 'Text Card added to Favorites';
                                            } else {
                                                msg = 'Text Card removed from Favorites';
                                            }

                                            CustomToast({
                                                message: msg,
                                                variant: 'ALERT_INFO',
                                                width: '300px',
                                            });
                                        }
                                    }}
                                    onShare={() => {
                                        if (!textCard.selected) {
                                            dispatch({
                                                type: TextCardActions.SELECT_CARD,
                                                payload: { data: textCard },
                                            });
                                        }
                                        setShowShareModal(true);
                                    }}
                                    onDownload={() => {
                                        const csvData = new Blob([textCard.results], {
                                            type: 'text/csv;charset=utf-8;',
                                        });
                                        FileSaver.saveAs(csvData, 'data.txt');
                                    }}
                                    onDelete={() => {
                                        // Clears all of the selected.
                                        dispatch({
                                            type: TextCardActions.CLEAR_SELECTED,
                                        });
                                        // Selects the last selected card.
                                        dispatch({
                                            type: TextCardActions.SELECT_CARD,
                                            payload: { data: textCard },
                                        });
                                        setShowDeleteModal(true);
                                    }}
                                    disabled={true}
                                />
                            </div>
                        );
                    })}
            </Box>

            {showEditModal && (
                <EditTextCardModal
                    header={'Edit Text'}
                    selected={selectedTextCard.find(textCard => textCard.selected === true)}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowEditModal(false),
                    }}
                    confirmButton={{
                        label: 'Save Changes',
                        onConfirm: async (id, value) => {
                            const data = JSON.stringify({
                                results: value,
                            });

                            const response = await apiUpdateTextCard(id, data);

                            if (response.error) {
                                console.log(response.error);
                            } else {
                                dispatch({
                                    type: TextCardActions.UPDATE_CARD,
                                    payload: response.data.restuls,
                                });

                                CustomToast({
                                    message: 'Text Card(s) Updated',
                                    variant: 'ALERT_INFO',
                                    width: '300px',
                                });
                            }

                            setShowEditModal(false);
                        },
                    }}
                />
            )}

            {showMoveModal && (
                <MoveModal
                    header={'Move text to folder'}
                    selections={allFolders}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowMoveModal(false),
                    }}
                    confirmButton={{
                        label: 'Save Changes',
                        onConfirm: async (folderId: string) => {
                            const selectedTextCardId: number[] = selectedTextCard.map(d => d.id);

                            const response = await apiMoveTextCardToFolder(selectedTextCardId, folderId);

                            if (response.error) {
                                console.log(response.error);
                            } else {
                                dispatch({
                                    type: TextCardActions.MOVE_CARDS,
                                    payload: { data: response.data.results },
                                });

                                CustomToast({
                                    message: 'Text Card(s) Moved',
                                    variant: 'ALERT_INFO',
                                    width: '300px',
                                });
                            }

                            setShowMoveModal(false);
                        },
                    }}
                />
            )}

            {showShareModal && (
                <ShareLinkModal
                    header={'Share options'}
                    selections={selectedTextCard}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowShareModal(false),
                    }}
                    confirmButton={{
                        label: 'Copy',

                        onConfirm: async (textCardList: ITextCardShare[], uid: string, link: string) => {
                            const data = JSON.stringify({
                                results: textCardList,
                                uid: uid,
                                created_by: user,
                            });

                            const response = await apiShareTextCard(data);

                            if (response.error) {
                                console.log(response.error);
                            } else {
                                navigator.clipboard.writeText(link);
                            }
                        },
                    }}
                />
            )}

            {showDeleteModal && (
                <ConfirmModal
                    header={'Are you sure?'}
                    body={`Are you sure you want to delete the selected text(s)?`}
                    cancelButton={{
                        label: 'Cancel',
                        onCancel: () => setShowDeleteModal(false),
                    }}
                    confirmButton={{
                        label: 'Delete',
                        onConfirm: async () => {
                            for (let i = 0; i < selectedTextCard.length; i++) {
                                const response = await apiDeleteTextCard(selectedTextCard, i);

                                if (response.error) {
                                    console.log(response.error);
                                } else {
                                    dispatch({
                                        type: TextCardActions.DELETE_CARDS,
                                        payload: { data: selectedTextCard[i].id },
                                    });

                                    CustomToast({
                                        message: 'Text Card(s) Deleted',
                                        variant: 'ALERT_INFO',
                                        width: '300px',
                                    });

                                    setShowDeleteModal(false);
                                }
                            }
                        },
                    }}
                />
            )}
        </Box>
    );
}

export default TextCardContainer;
