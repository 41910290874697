import { P2 } from '@components/common/typography/Typography';
import { SxProps, Tab, Tabs } from '@mui/material';
import { setColor } from '@utils/colorUtils';
import { useState } from 'react';

interface ITabProps {
    /**
     * tab selections
     */
    selections?: any[];
    /**
     * set current active tab
     */
    onClick?: (id: number) => void;
}

/**
 * input field custom styling
 */
const tabStyle: SxProps<any> = {
    minHeight: 32,
    '& .MuiTab-root': {
        py: 2,
        px: 4,
        minWidth: 0,
        minHeight: 32,
        '& .MuiTypography-root': {
            color: setColor('fc-grey'),
        },
        /*
         * removing ripple animation on click of the pagination items
         */
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
        /*
         * on hover and selected change
         */
        ':hover, &.Mui-selected': {
            borderBottom: `2px solid ${setColor('hl-purple')}`,

            '& .MuiTypography-root': {
                fontWeight: 700,
                color: setColor('fc-black'),
            },
        },
    },
    /*
     * selected tab indicator
     */
    '& .MuiTabs-indicator': {
        bgcolor: setColor('hl-purple'),
    },
};

const AppTabs: React.FC<ITabProps> = ({ selections = [], onClick = () => {} }) => {
    const [currentTab, setCurrentTab] = useState<number>(0);

    return (
        <Tabs
            value={currentTab}
            onChange={(e, val) => {
                setCurrentTab(val);
            }}
            sx={{ ...tabStyle }}
        >
            {selections?.map((selection, idx) => {
                return (
                    <Tab
                        label={<P2>{selection.category_name}</P2>}
                        id={`simple-tab-${idx}`}
                        aria-controls={`simple-tabpanel-${idx}`}
                        key={selection.id}
                        onClick={() => {
                            onClick(selection.id);
                        }}
                        sx={{ minHeight: 32 }}
                    />
                );
            })}
        </Tabs>
    );
};

export default AppTabs;
