/*
 *   Folder Actions
 */
export enum FolderActions {
    SET_FOLDER = 'SET_FOLDER',
    ADD_FOLDER = 'ADD_FOLDER',
    UPDATE_FOLDER = 'UPDATE_FOLDER',
    DELETE_FOLDER = 'DELETE_FOLDER',
    DUPLICATE_FOLDER = 'DUPLICATE_FOLDER',
}
/*
 *   Project Actions
 */
export enum ProjectActions {
    SET_PROJECT = 'SET_PROJECT',
    SELECT_PROJECT = 'SELECT_PROJECT',
    DESELECT_PROJECT = 'DESELECT_PROJECT',
    ADD_PROJECT = 'ADD_PROJECT',
    UPDATE_PROJECT = 'UPDATE_PROJECT',
    DELETE_PROJECT = 'DELETE_PROJECT',
    DUPLICATE_PROJECT = 'DUPLICATE_PROJECT',
    EDIT_PROJECT_NAME = 'EDIT_PROJECT_NAME',
}
/*
 *   Textcard Actions
 */
export enum TextCardActions {
    SELECT_CARD = 'SELECT_CARD',
    SELECT_ALL_CARD = 'SELECT_ALL_CARD',
    ADD_TO_FAVOURITES = 'ADD_TO_FAVOURITES',
    REPORTED = 'REPORTED',
    SET_CARDS = 'SET_CARDS',
    CLEAR_CARDS = 'CLEAR_CARDS',
    CLEAR_SELECTED = 'CLEAR_SELECTED',
    DELETE_CARDS = 'DELETE_CARDS',
    UPDATE_CARD = 'UPDATE_CARD',
    MOVE_CARDS = 'MOVE_CARDS',
}
/*
 *   Textcard Collection Actions
 */
export enum TextCardCollectionActions {
    SET_CARD_COLLECTION = 'SET_CARD_COLLECTION',
    SET_PAGE = 'SET_PAGE',
}
/*
 *   Template Actions
 */
export enum TemplateActions {
    SELECT_TEMPLATE = 'SELECT_TEMPLATE',
    SET_TEMPLATE = 'SETSET_TEMPLATE_CARDS',
    CLEAR_TEMPLATE = 'CLEAR_TEMPLATE',
    CLEAR_SELECTED = 'CLEAR_SELECTED',
}
/*
 *   Prompt Actions
 */
export enum PromptActions {
    EDIT_PROMPT = 'EDIT_PROMPT',
    CLEAR_PROMPT = 'CLEAR_PROMPT',
}
/*
 *   Login Actions
 */
export enum LoginActions {
    SET_USER = 'SET_USER',
}
/*
 *   Filter Actions
 */
export enum FilterActions {
    SET_FILTER = 'SET_FILTER',
    CLEAR_FILTER = 'CLEAR_FILTER',
}
/*
 *   Content Filter Actions
 */
export enum ContentFilterActions {
    MESSAGE = 'MESSAGE',
}
/*
 *   Loader Actions
 */
export enum LoaderActions {
    SET_LOADER = 'SET_LOADER',
}
