import { useState } from 'react';
import { Box, Menu, MenuItem, SxProps } from '@mui/material';
import IconCollection, { iconCollections, IconCollections } from '@components/icon-collection/IconCollection';
import CustomTooltip, { ITooltipProps } from '@components/common/custom-tooltip/CustomTooltip';
import { P2 } from '@components/common/typography/Typography';
import RoundButton from '../custom-button/round-button/RoundButton';
import { setColor } from '@utils/colorUtils';

export type PopOverMenuOptions =
    | 'EDIT'
    | 'MOVE_TO'
    | 'ADD_TO_FAVORITES'
    | 'UNFAVORITE'
    | 'SHARE'
    | 'DOWNLOAD'
    | 'DELETE'
    | 'DUPLICATE'
    | 'NEW_TAB'
    | 'RENAME';

interface PopOverMenuProps {
    menus?: PopOverMenuOptions[];
    tooltip?: ITooltipProps;
    onClick?: (menu: PopOverMenuOptions) => void;
    sx?: SxProps;
}

const popOverMenuOptions: {
    [key: string]: { icon: IconCollections; title: string };
} = {
    EDIT: { icon: 'EDIT', title: 'Edit' },
    MOVE_TO: { icon: 'MOVE_SMALL', title: 'Move to' },
    ADD_TO_FAVORITES: { icon: 'STAR_OUTLINED', title: 'Add to Favorites' },
    UNFAVORITE: { icon: 'FAVOURITES', title: 'Unfavorite' },
    SHARE: { icon: 'LINK_SMALL', title: 'Share' },
    DOWNLOAD: { icon: 'DOWNLOAD', title: 'Download' },
    DELETE: { icon: 'DELETE', title: 'Delete' },
    DUPLICATE: { icon: 'DUPLICATE', title: 'Duplicate' },
    NEW_TAB: { icon: 'NEW_TAB', title: 'Open in new tab' },
    RENAME: { icon: 'EDIT_SMALL', title: 'Rename' },
};

export const PopOverMenu: React.FC<PopOverMenuProps> = ({
    menus = ['DELETE', 'DUPLICATE', 'NEW_TAB', 'EDIT'],
    tooltip = { disabled: true, title: '', placement: 'left' },
    onClick = () => {},
    sx = {},
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    return (
        <Box sx={{ ...sx }}>
            {tooltip.disabled ? (
                <RoundButton
                    label={
                        <img
                            src={iconCollections.THREE_DOTS_HORIZONTAL.src}
                            alt={'icon-three-dots'}
                            style={{ width: 14, height: 14 }}
                        />
                    }
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                />
            ) : (
                <CustomTooltip title={tooltip.title} placement={tooltip.placement}>
                    <RoundButton
                        label={
                            <img
                                src={iconCollections.THREE_DOTS_HORIZONTAL.src}
                                alt={'icon-three-dots'}
                                style={{ width: 14, height: 14 }}
                            />
                        }
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    />
                </CustomTooltip>
            )}
            <Menu
                id={'menu'}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transitionDuration={0}
                sx={{
                    '& .MuiList-root': { padding: 0, width: '200px' },
                }}
            >
                {menus?.map(menu => (
                    <MenuItem
                        key={menu}
                        onClick={() => {
                            onClick(menu);
                            setAnchorEl(null);
                        }}
                        sx={{
                            height: 34,
                            color: setColor('fc-black'),
                            fontSize: { xs: 12, md: 14, lg: 14 },
                            '&:hover': { background: '#F1F5FB' },
                        }}
                    >
                        <IconCollection icon={popOverMenuOptions[menu].icon} size={'md'} sx={{ mr: 2 }} />
                        <P2>{popOverMenuOptions[menu].title}</P2>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default PopOverMenu;
