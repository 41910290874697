import RoundButton from '@components/common/custom-button/round-button/RoundButton';
import CustomInput from '@components/common/custom-input/CustomInput';
import CustomTooltip from '@components/common/custom-tooltip/CustomTooltip';
import { P2 } from '@components/common/typography/Typography';
import IconCollection, { iconCollections } from '@components/icon-collection/IconCollection';
import { Box, CircularProgress, ClickAwayListener, MenuItem, SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';
import { useState } from 'react';

interface IFolderItemProps {
    /**
     * optional label of the button
     */
    label?: string;
    /**
     * folder
     */
    folder?: any;
    /**
     * on click event
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * on doubleclick event
     */
    onDoubleClick?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * on duplicate event
     */
    onDuplicate?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * on delete event
     */
    onDelete?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * on clickaway event
     */
    onClickAway?: (event: MouseEvent | TouchEvent) => void;
    /**
     * on press enter event
     */
    onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    /**
     * optional is the type of folder
     */
    type?: 'system' | 'regular';
    /**
     * optional is the total files in the folder
     */
    count?: number | string;
    /**
     * optional is the button renaming
     */
    renaming?: boolean;
    /**
     * optional is the button selected
     */
    selected?: boolean;
    /**
     * optional is the button loading
     */
    loading?: boolean;
    /**
     * custom style
     */
    sx?: SxProps;
}

const FolderItem: React.FC<IFolderItemProps> = ({
    label = 'MadgicxIQ Borderless Button',
    onClick = () => {},
    onDoubleClick = () => {},
    onDuplicate = () => {},
    onDelete = () => {},
    onClickAway = () => {},
    onPressEnter = () => {},
    type = 'regular',
    count = 0,
    renaming = false,
    selected = false,
    loading = false,
    folder,
    sx = {},
}) => {
    const [folderName, setFolderName] = useState<string>(label);

    return (
        <MenuItem
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            sx={[
                {
                    px: 4,
                    py: 1,
                    height: 34,

                    '& .folder-list-icon': {
                        mr: 3,
                        width: { xs: 12, md: 14, lg: 16 },

                        '&.open': {
                            ml: -0.5,
                            width: { xs: 14, md: 16, lg: 18 },
                        },
                    },

                    '& .action-buttons': {
                        display: 'none',
                    },

                    '&:hover': {
                        '& .action-buttons': {
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 'auto',
                            gap: 2,
                        },
                    },
                    /*
                     ** on focus set background color to white
                     */
                    '&.Mui-focusVisible': {
                        bgcolor: setColor('bg-white'),
                    },
                },
                selected === true && {
                    bgcolor: '#F1F5FB',
                },
            ]}
            disableRipple={true}
        >
            <IconCollection
                icon={folder?.folder_name === 'Favorites' ? 'FAVOURITES' : selected ? 'FOLDER_OPEN' : 'FOLDER_CLOSE'}
                className={
                    folder?.folder_name === 'Favorites'
                        ? 'folder-list-icon favorites'
                        : selected
                        ? 'folder-list-icon open'
                        : 'folder-list-icon close'
                }
            />
            {renaming ? (
                <ClickAwayListener onClickAway={onClickAway}>
                    <Box sx={{ width: '90%' }}>
                        <CustomInput
                            value={folderName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFolderName(e.target.value);
                            }}
                            onKeypress={onPressEnter}
                            disabled={!renaming}
                        />
                    </Box>
                </ClickAwayListener>
            ) : (
                <>
                    <P2 sx={{ mr: 3 }}>&#8288;{folderName}</P2>
                    {loading ? (
                        <CircularProgress
                            sx={{
                                width: 16,
                                height: 16,
                            }}
                        />
                    ) : (
                        <P2 color={'fc-grey'}>{count}</P2>
                    )}

                    {type === 'regular' && (
                        <Box className={'action-buttons'}>
                            <CustomTooltip title={'Duplicate folder'} placement={'left'}>
                                <RoundButton
                                    label={
                                        <img
                                            src={iconCollections.DUPLICATE.src}
                                            alt={'icon-edit'}
                                            style={{ width: 14, height: 14 }}
                                        />
                                    }
                                    onClick={onDuplicate}
                                />
                            </CustomTooltip>

                            <CustomTooltip title={'Delete folder'} placement={'right'}>
                                <RoundButton
                                    label={
                                        <img
                                            src={iconCollections.DELETE.src}
                                            alt={'icon-edit'}
                                            style={{ width: 14, height: 14 }}
                                        />
                                    }
                                    onClick={onDelete}
                                />
                            </CustomTooltip>
                        </Box>
                    )}
                </>
            )}
        </MenuItem>
    );
};

export default FolderItem;
