import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, SxProps } from '@mui/material';
import CustomTextArea from '@components/common/custom-textarea/CustomTextArea';
import { H4, P2 } from '@components/common/typography/Typography';
import { IModalProps } from '@components/custom-modals/IModalProps';
import CustomInput from '@components/common/custom-input/CustomInput';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';

const modalStyle: SxProps = {
    '& .MuiPaper-root': {
        width: '500px',
        minWidth: '280px',
        maxWidth: '500px',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};

const RequestModal: React.FC<IModalProps> = ({
    header = 'Add Modal Component',
    subject = '',
    body = '',
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = { label: 'Confirm', onConfirm: () => {} },
}) => {
    const [name, setName] = useState<string>(subject);
    const [description, setDescription] = useState<string>(body);

    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>

            <DialogContent>
                <Grid container spacing={2} mb={6} alignItems={'center'}>
                    <Grid item xs={3}>
                        <P2>Platform Name:</P2>
                    </Grid>
                    <Grid item xs={9}>
                        <CustomInput
                            value={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e.preventDefault();

                                setName(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems={'flex-start'}>
                    <Grid item xs={3}>
                        <P2>Description:</P2>
                    </Grid>
                    <Grid item xs={9}>
                        <CustomTextArea
                            value={description}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e.preventDefault();

                                setDescription(e.target.value);
                            }}
                            maxLength={300}
                            disabled={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ padding: 2 }}>
                <BorderlessButton
                    label={<P2 color={'fc-black'}>{cancelButton?.label}</P2>}
                    onClick={() => cancelButton?.onCancel()}
                />
                <DefaultButton
                    label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                    onClick={() => confirmButton?.onConfirm(name, description)}
                />
            </DialogActions>
        </Dialog>
    );
};

export default RequestModal;
