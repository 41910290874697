import { ILanguages } from '@interfaces/api/ILanguages';
import { Select, MenuItem, Chip, Grid } from '@mui/material';
import { PromptActions } from '@store/actions/actions';
import { apiGetLanguages } from '@store/api/apiLanguages';
import { selectedPrompt } from '@store/selectors/promptSelector';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
interface ILanguageSelectorProps {
    sx: string;
    label: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
}
function LanguageSelector(props: ILanguageSelectorProps) {
    const [focus, setFocus] = useState<boolean>(false);
    const [languageList, setLanguageList] = useState<ILanguages[]>([]);
    const currentPrompt = useSelector(selectedPrompt);
    const dispatch = useDispatch();

    const handleChange = e => {
        props.setLanguage(e.target.value);
        // props.setLanguage((state) =>
        //                     {
        //                      console.log({state})
        //                     return state;
        //                     });
        dispatch({
            type: PromptActions.EDIT_PROMPT,
            payload: {
                ...currentPrompt,
                language: e.target.value,
            },
        });
        // props.onChange(e);
    };

    useEffect(() => {
        let cancelFetching = false;

        const fetchLanguageList = async () => {
            const response = await apiGetLanguages();
            if (!cancelFetching) {
                if (response.error) {
                    console.log(response.error);
                } else {
                    setLanguageList(response.data);
                }
            }
        };

        fetchLanguageList();

        return () => {
            cancelFetching = true;
        };
    }, []);

    return (
        <Select
            sx={{
                textAlign: 'left',
                width: '100%',
                border: '1px solid #E3E5E9',
                borderRadius: 1,
                boxShadow: '0px 3px 4px rgba(0,0,0,0.2)',
                margin: '5px',
                borderColor: focus ? '#006DF0' : '#E3E5E9',
                '& .MuiSelect-select': {
                    padding: 1,
                    border: 0,
                },

                '& .MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                },
            }}
            value={props.language}
            onChange={handleChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
        >
            {languageList.map(language => {
                return language.is_visible ? (
                    <MenuItem
                        key={language.language_code}
                        value={language.language_name}
                        {...(!language.is_supported ? { disabled: true } : { disabled: false })}
                    >
                        <ReactCountryFlag svg style={{ marginRight: '0.5em' }} countryCode={language.country_code} />
                        {language.language_name}
                        {!language.is_supported ? (
                            <Grid container justifyContent="flex-end">
                                <Chip
                                    size="small"
                                    label="Coming Soon"
                                    sx={{ backgroundColor: '#FFB70A', color: 'white' }}
                                />
                            </Grid>
                        ) : null}
                    </MenuItem>
                ) : null;
            })}
        </Select>
    );
}
export default LanguageSelector;
