import IconCollection from '@components/icon-collection/IconCollection';
import { Box, InputBase, SxProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Caption } from '@components/common/typography/Typography';
import { FlexColumn } from '@components/layout/app-flexbox/AppFlexBox';
import { DEFAULT_COLOR, WARNING_COLOR } from '@constants/constants';

interface ICustomTextAreaProps {
    /*
     * Placeholder text
     */
    placeholder?: string;
    /*
     * text value of the component
     */
    value: string;
    /*
     * on change event
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /*
     * optional if this has a value on the copy icon will show
     */
    onCopy?: (event: React.MouseEvent<HTMLElement>) => void;
    /*
     * max length of the text area
     */
    maxLength?: number;
    /*
     * optional is the component disabled
     */
    disabled?: boolean;
    /*
     * optional is the component focused
     */
    onFocus?: (event: React.MouseEvent<HTMLElement>) => void;
    /*
     * optional height of the component
     */
    height?: number;
    /*
     * custom styling
     */
    sx?: SxProps;
    row?: number;
}

const CustomTextArea: React.FC<ICustomTextAreaProps> = ({
    placeholder = '',
    value = '',
    maxLength = 300,
    onChange = () => {},
    onCopy,
    disabled = false,
    onFocus = () => {},
    height,
    sx = {},
    row,
}) => {
    const textAreaRef = useRef(null);

    const [focus, setFocus] = useState<boolean>(false);
    const [color, setColor] = useState(DEFAULT_COLOR);

    useEffect(() => {
        if (value.length >= maxLength) {
            setColor(WARNING_COLOR);
        } else {
            setColor(DEFAULT_COLOR);
        }
    }, [value, maxLength]);

    return (
        <FlexColumn
            sx={{
                position: 'relative',
                height: height,
                borderRadius: 1,
                border: '2px solid #E3E5E9',
                /*
                 * changes border color if focused
                 */
                borderColor: focus === true ? '#006DF0' : '#E3E5E9',
                // borderColor: focus ? "#006DF0" : "#E3E5E9",

                '& .MuiActionButton-root': {
                    position: 'absolute',
                    top: 4,
                    right: 8,
                    opacity: 0,
                },

                '&:hover': {
                    '& .MuiActionButton-root': {
                        opacity: 1,
                    },
                    '& .MuiBox-root': {
                        border: focus === true ? '1px solid black' : '1 px solid #E3E5E9',
                    },
                },
                ...sx,
            }}
        >
            <InputBase
                value={value}
                placeholder={placeholder}
                inputRef={textAreaRef}
                multiline={true}
                rows={row}
                onChange={onChange}
                onFocus={() =>  {
                    setFocus(true)
                }}
                onBlur={() => {
                    setFocus(false);
                }}
                disabled={disabled}
                sx={{
                    width: '100%',
                    alignItems: 'flex-start',
                    // overflowY: 'auto',
                    padding: 0,
                    mb: 12,
                    p: 2,
                    pr: 12,

                    '& textarea': {
                        fontSize: { xs: 12, md: 14, lg: 16 },
                        border: 0,
                        mb: 0,
                        cursor: 'pointer',
                        width: '100%',

                        '&:disabled': {
                            WebkitTextFillColor: '#0D182F',
                        },
                    },
                }}
            />

            <Caption sx={{ position: 'absolute', bottom: 4, right: 8, color: color }}>
                {value?.toString().length}/{maxLength || `100`}
            </Caption>

            {onCopy && (
                <Box className={'MuiActionButton-root'} onClick={onCopy}>
                    <IconCollection icon="DUPLICATE" size="md" />
                </Box>
            )}
        </FlexColumn>
    );
};

export default CustomTextArea;
