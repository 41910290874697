import { DRAWER_WIDTH } from '@constants/constants';
import { Drawer, SxProps } from '@mui/material';

const sidebarStyle: SxProps<any> = {
    position: 'inherit',
    width: DRAWER_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        top: 55,
        width: DRAWER_WIDTH,
        boxSizing: 'border-box',
        overflowY: 'hidden',
    },
};

const AppSidebar: React.FC<any> = (props: { open: boolean; children?: React.ReactNode }) => {
    const { open, children } = props;
    return (
        <Drawer variant="persistent" anchor="left" open={open} sx={{ ...sidebarStyle }}>
            {children}
        </Drawer>
    );
};

export default AppSidebar;
