import { JustifyCenter } from '@components/layout/app-flexbox/AppFlexBox';
import { Card, Skeleton } from '@mui/material';

const TemplateCardSkeleton: React.FC<any> = () => {
    return (
        <>
            <Card
                sx={{
                    display: 'inline-block',
                    position: 'relative',
                    width: 140,
                    height: 140,
                    p: 4,
                    m: 1,
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    border: '1px solid transparent',
                }}
            >
                <JustifyCenter sx={{ mb: 4 }}>
                    <Skeleton variant={'circular'} width={20} height={20} />
                </JustifyCenter>

                <Skeleton variant={'rectangular'} width={'100%'} height={70} />
            </Card>
        </>
    );
};

export default TemplateCardSkeleton;

// <Card
//   sx={{
//     display: "inline-block",
//     position: "relative",
//     width: 140,
//     height: 140,
//     p: 4,
//     m: 1,
//     cursor: "pointer",
//     boxSizing: "border-box",
//     border: "1px solid transparent",
//   }}
// >
//   <JustifyCenter sx={{ mb: 4 }}>
//     <Skeleton variant={"circular"} width={20} height={20} />
//   </JustifyCenter>

//   <Skeleton variant={"rectangular"} width={"100%"} height={70} />
// </Card>
