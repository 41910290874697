import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, SxProps } from '@mui/material';
import CustomTextArea from '@components/common/custom-textarea/CustomTextArea';
import { H4, P2 } from '@components/common/typography/Typography';
import { IModalProps } from '@components/custom-modals/IModalProps';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { FlexCenter } from '@components/layout/app-flexbox/AppFlexBox';

const modalStyle: SxProps = {
    '& .MuiPaper-root': {
        width: '500px',
        minWidth: '280px',
        maxWidth: '500px',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};
const EditTextCardModal: React.FC<IModalProps> = ({
    header = 'Add Modal Component',
    selected,
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = {
        label: 'Confirm',
        onConfirm: (id: number, value: string) => {},
    },
}) => {
    const [value, setValue] = useState<string>(selected?.results);
    const id = selected?.id;

    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>

            <DialogContent>
                <CustomTextArea
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.preventDefault();

                        setValue(e.target.value);
                    }}
                    disabled={false}
                    maxLength={selected?.max_allowed_characters}
                />
            </DialogContent>

            <DialogActions sx={{ padding: 2 }}>
                <BorderlessButton
                    label={<P2 color={'fc-black'}>{cancelButton?.label}</P2>}
                    onClick={() => cancelButton?.onCancel()}
                />
                <DefaultButton
                    label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                    disabled={value.trim() === ''}
                    onClick={() => confirmButton?.onConfirm(id, value)}
                />
            </DialogActions>
        </Dialog>
    );
};

export default EditTextCardModal;
