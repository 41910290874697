import { IAppState } from '@interfaces/api/IAppState';
import { IProject } from '@interfaces/api/IProject';
import { createSelector } from 'reselect';

export const selectAllProject = createSelector(
    (state: IAppState) => state.projects,
    (projects: IProject[]) => projects,
);

export const selectProject = createSelector(
    (state: IAppState) => state.projects,
    (projects: IProject[]) => projects.filter(project => project.selected),
);
