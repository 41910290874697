import { IAction } from '@interfaces/api/IAction';
import { ITextCard } from '@interfaces/api/ITextCard';
import { TextCardActions } from '@store/actions/actions';

export const textCardReducer = (state: ITextCard[] = [], action: IAction) => {
    switch (action.type) {
        case TextCardActions.SET_CARDS: {
            return [...action.payload.data];
        }
        case TextCardActions.UPDATE_CARD: {
            const { id, results } = action.payload;
            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, results } : { ...s };
                }),
            ];
        }
        case TextCardActions.SELECT_CARD: {
            const { id } = action.payload.data;
            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, selected: !s.selected } : { ...s };
                }),
            ];
        }
        case TextCardActions.ADD_TO_FAVOURITES: {
            const { id } = action.payload.data;

            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, is_starred: !s.is_starred } : { ...s };
                }),
            ];
        }
        case TextCardActions.REPORTED: {
            const { id } = action.payload.data;

            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, is_reported: !s.is_reported } : { ...s };
                }),
            ];
        }
        case TextCardActions.CLEAR_SELECTED: {
            return [
                ...state.map(s => {
                    return { ...s, selected: false };
                }),
            ];
        }
        case TextCardActions.SELECT_ALL_CARD: {
            return [
                ...state.map(s => {
                    return { ...s, selected: true };
                }),
            ];
        }
        case TextCardActions.CLEAR_CARDS: {
            return [];
        }
        case TextCardActions.DELETE_CARDS: {
            const { data } = action.payload;

            const updatedTextCards = state.filter(s => s.id !== data);
            return updatedTextCards || [];
        }
        case TextCardActions.MOVE_CARDS: {
            const { data } = action.payload;
            const dataId = data.map((d: ITextCard) => d.id);
            const updatedTextCards =
                data.length > 1 ? state.filter(s => !dataId.includes(s.id)) : state.filter(s => s.id !== data[0].id);

            return updatedTextCards || [];
        }

        default:
            return state;
    }
};
export default textCardReducer;
