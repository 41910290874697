import { P3 } from '@components/common/typography/Typography';
import { ITEMS_PER_PAGE } from '@constants/constants';
import { Pagination, SxProps } from '@mui/material';
import { setColor } from '@utils/colorUtils';
import { useEffect } from 'react';
import { JustifyEnd } from '@components/layout/app-flexbox/AppFlexBox';

interface AppPaginationProps {
    /*
     * current active page number
     */
    page?: number;
    /*
     * sets the current active page
     */
    setPage: React.Dispatch<React.SetStateAction<number>>;
    /*
     * on change event
     */
    onChange?: (e: React.ChangeEvent<unknown>, page: number) => void;
    /*
     * total length of the items
     */
    itemCount?: number;
}

const paginationStyle: SxProps<any> = {
    padding: 5,
    /*
     * custom styling of the pagination component
     */
    '& .MuiPagination-ul': {
        '& li': {
            '& .MuiPaginationItem-root': {
                fontSize: { sm: 10, md: 10, lg: 12 },

                minWidth: 12,
                bgcolor: 'transparent',
                borderRadius: 0,
                borderBottom: '2px solid transparent',
                /*
                 * removing ripple animation on click of the pagination items
                 */
                '& .MuiTouchRipple-root': {
                    display: 'none',
                },
                /*
                 * width height of the arrow keys of the pagination
                 */
                '& svg': {
                    width: 16,
                    height: 16,
                },
                /*
                 * on hover and selected change
                 */
                '&:hover': {
                    fontWeight: 700,
                    borderColor: setColor('hl-light-purple'),
                },
                '&.Mui-selected': {
                    fontWeight: 700,
                    color: setColor('hl-blue'),
                    borderColor: setColor('hl-blue'),
                },
            },
        },
    },
};

const AppPagination: React.FC<AppPaginationProps> = ({ itemCount = 10, page = 1, onChange = () => {}, setPage }) => {
    const numberOfPages = Math.ceil(itemCount / ITEMS_PER_PAGE);
    const itemCountStart = (page - 1) * ITEMS_PER_PAGE + 1;
    const itemCountEnd = page * ITEMS_PER_PAGE;

    /*
     * clean up code - resets the page to 1
     */
    useEffect(() => {
        return () => {
            setPage(1);
        };
    }, [itemCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <JustifyEnd sx={{ ...paginationStyle }}>
            <P3>
                {`Shown cards: ${itemCountStart} - ${
                    itemCountEnd > itemCount ? itemCount : itemCountEnd
                } of ${itemCount}`}
            </P3>
            <Pagination page={page} defaultPage={1} onChange={onChange} count={numberOfPages} />
        </JustifyEnd>
    );
};

export default AppPagination;
