import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import './assets/signup.css';
import madgicxIcon from './assets/images/image01.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { apiCreateNewUser, apiGetUserInfo } from '@store/api/apiSignUp';
import { LoginActions, ProjectActions, PromptActions, TextCardActions, TextCardCollectionActions } from '@store/actions/actions';
import { validationSchema } from '@validations/signUpValidation';
import { persistor } from '@store/root';
import jwt_decode from 'jwt-decode';
import { IAuthJWT } from '@interfaces/api/IAuthJWT';

const SignUp = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        let cancelFetchingSettings = false;
        setTimeout(() => persistor.purge(), 200)
        return () => {
            cancelFetchingSettings = true; // eslint-disable-line react-hooks/exhaustive-deps
        }
    }, []);

    const registerUser = async event => {
        event.preventDefault();

        localStorage.clear();

        let formData = {
            email: event.target[0].value,
            phone: event.target[1].value,
            password: event.target[2].value,
            confirm_password: event.target[3].value,
        };

        const isValid = await validationSchema.isValid(formData);
        const isMatch = formData.password === event.target[3].value;
        const isPhoneValid = phone.length > 9;

        if (isValid) {
            if (isPhoneValid) {
                if (isMatch) {
                    setErrorMessage('');

                    const data = JSON.stringify({
                        email: event.target[0].value,
                        phone_number: event.target[1].value,
                        password: event.target[2].value,
                    });

                    const response = await apiCreateNewUser(data);
                    const user_exists = response.data.email ? response.data.email[0] : '';
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        if (!response.data.detail) {
                            if (!user_exists) {
                                localStorage.clear();
                                persistor.purge();

                                // Save jwt created token in locaStorage
                                localStorage.setItem('authTokens', JSON.stringify(response.data));

                                // Descrypt jwt created token
                                const user = jwt_decode<IAuthJWT>(response.data.access);

                                // Use decoded user_id to get user info from API

                                const response_info = await apiGetUserInfo(user.user_id);

                                localStorage.setItem('isAdmin', JSON.stringify(response_info.data.is_superuser));

                                dispatch({
                                    type: TextCardCollectionActions.SET_PAGE,
                                    payload: { data: 1 },
                                });
    
                                dispatch({
                                    type: ProjectActions.DESELECT_PROJECT,
                                    payload: {},
                                });
    
                                dispatch({
                                    type: TextCardActions.CLEAR_SELECTED,
                                    payload: {},
                                });
    
                                dispatch({
                                    type: TextCardActions.CLEAR_CARDS,
                                    payload: {},
                                });
    
                                dispatch({
                                    type: PromptActions.CLEAR_PROMPT,
                                    payload: {},
                                });
                                
                                dispatch({
                                    type: LoginActions.SET_USER,
                                    payload: response_info.data,
                                });

                                navigate('/generator');
                            } else {
                                setErrorMessage(user_exists);
                            }
                        } else {
                            setErrorMessage(response.data.detail);
                        }
                    }
                } else {
                    setErrorMessage('Passwords do not match.');
                }
            } else {
                setErrorMessage('Phone number is not valid. Please enter a valid phone number.');
            }
        }
    };

    const handleOnChange = value => {
        setPhone(value);
    };

    return (
        <div>
            <title>Sign Up: Madgicx AI Copywriter</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
            <meta name="description" content="Sign Up: Madgicx AI Copywriter sketch" />
            <meta property="og:site_name" content="Sign Up: Madgicx AI Copywriter" />
            <meta property="og:title" content="Sign Up: Madgicx AI Copywriter" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Sign Up: Madgicx AI Copywriter sketch" />
            <link
                href="https://fonts.googleapis.com/css?family=Montserrat:400,400italic,500,500italic,600,600italic"
                rel="stylesheet"
                type="text/css"
            />
            <link rel="stylesheet" href="assets/main.css" />
            <div id="wrapper">
                <div id="main">
                    <div className="inner">
                        <div id="container06" className="container default" style={{ height: '80vh' }}>
                            <div className="wrapper">
                                <div className="inner">
                                    <p id="text07">built by</p>
                                    <div id="image01" className="image">
                                        <a
                                            href="https://madgicx.com?utm_source=ai-copywriter_landing"
                                            className="frame"
                                        >
                                            <img src={madgicxIcon} alt="" />
                                        </a>
                                    </div>
                                    <hr id="divider02" />
                                    <h1 id="text08">Start your 7-day free trial</h1>
                                    <h2 id="text09">No credit card required.</h2>
                                    <form
                                        encType="multipart/form-data"
                                        id="form01"
                                        method="post"
                                        onSubmit={registerUser}
                                    >
                                        <div className="inner">
                                            <div className="field">
                                                <input
                                                    type="email"
                                                    name="enter-your-email"
                                                    id="form01-enter-your-email"
                                                    placeholder="Enter your email"
                                                    maxLength={128}
                                                    required
                                                    autoFocus
                                                />
                                            </div>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                enableSearch={true}
                                                value={phone}
                                                country={'us'}
                                                onChange={handleOnChange}
                                                dropdownStyle={{
                                                    textAlign: 'left',
                                                    fontSize: 'inherit',
                                                    overflowX: 'hidden',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                }}
                                                containerStyle={{
                                                    height: '2.5rem',
                                                    fontSize: '0.625rem',
                                                }}
                                                inputStyle={{
                                                    paddingLeft: '50px',
                                                    fontSize: 'inherit',
                                                    color: '#1F2430',
                                                }}
                                                isValid={(value, country) => {
                                                    if (value.match(/1234/)) {
                                                        return 'Invalid phone number: ' + value;
                                                    } else if (value.match(/123/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                inputProps={{
                                                    required: true,
                                                }}
                                            />
                                            <div className="field">
                                                <input
                                                    type="password"
                                                    name="choose-a-password"
                                                    id="form01-choose-a-password"
                                                    placeholder="Choose a password"
                                                    minLength={8}
                                                    required
                                                />
                                            </div>
                                            <div className="field">
                                                <input
                                                    type="password"
                                                    name="confirm-password"
                                                    id="form01-confirm-password"
                                                    placeholder="Confirm password"
                                                    minLength={8}
                                                    required
                                                />
                                            </div>
                                            {errorMessage !== '' && (
                                                <div style={{ color: 'red', fontSize: '15px' }}>{errorMessage}</div>
                                            )}
                                            <div className="actions">
                                                <button type="submit">Sign up</button>
                                            </div>
                                        </div>
                                    </form>
                                    <h2 id="text03">
                                        Already have an account? <a href={'/login'}>Log in</a>
                                    </h2>
                                    <hr id="divider01" />
                                </div>
                            </div>
                        </div>
                        <div id="container04" className="container default full screen" style={{ height: '2.5vh' }}>
                            <div className="wrapper">
                                <div className="inner">
                                    <p id="text01">© 2021 All rights reserved madgicx.com</p>
                                </div>
                            </div>
                        </div>
                        <div id="container01" className="container columns full screen" style={{ height: '2.5vh' }}>
                            <div className="wrapper">
                                <div className="inner">
                                    <div>
                                        <p id="text05">
                                            <a href="https://madgicx.com/terms-conditions/">Terms of service</a>
                                        </p>
                                    </div>
                                    <div>
                                        <p id="text06">
                                            <a href="https://madgicx.com/privacy-policy/">Privacy policy</a>
                                        </p>
                                    </div>
                                    <div>
                                        <p id="text02">
                                            <a href="https://madgicx.com/cookie-policy/">Cookie policy</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
