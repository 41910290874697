import { IAction } from '@interfaces/api/IAction';
import { IFolder } from '@interfaces/api/IFolder';
import { FolderActions } from '@store/actions/actions';

export const folderReducer = (state: IFolder[] = [], action: IAction) => {
    switch (action.type) {
        case FolderActions.SET_FOLDER: {
            return [...action.payload.data];
        }
        case FolderActions.UPDATE_FOLDER: {
            const { id, results } = action.payload;
            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, results } : { ...s };
                }),
            ];
        }
        case FolderActions.DUPLICATE_FOLDER: {
            const { id, results } = action.payload;
            return [
                ...state.map(s => {
                    return s.id === id ? { ...s, results } : { ...s };
                }),
            ];
        }
        case FolderActions.DELETE_FOLDER: {
            const { id } = action.payload;

            const folders = state.filter(s => s.id !== id);

            return folders || [];
        }
        default:
            return state;
    }
};
export default folderReducer;
