import { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, Divider, SxProps } from '@mui/material';
import { H4, P2 } from '@components/common/typography/Typography';
import { IModalProps } from '@components/custom-modals/IModalProps';
import BorderlessButton from '@components/common/custom-button/borderless-button/BorderlessButton';
import DefaultButton from '@components/common/custom-button/default-button/DefaultButton';
import { AlignStart, FlexCenter, FlexColumn } from '@components/layout/app-flexbox/AppFlexBox';
import SearchInput from '@components/common/search-input/SearchInput';
import AppTabs from '@components/layout/app-tabs/AppTabs';
import { ICategory } from '@interfaces/api/ICategory';
import { ITemplate } from '@interfaces/api/ITemplate';
import { apiGetAllCategories } from '@store/api/apiCategories';
import { useSelector } from 'react-redux';
import { setColor } from '@utils/colorUtils';
import { selectTemplate } from '@store/selectors/templateSelector';
import TemplateCardContainer from '@pages/tool/templatecard-container/TemplateCardContainer';

const modalStyle: SxProps = {
    '& > .MuiDialog-container > .MuiPaper-root': {
        width: 1000,
        maxWidth: 1000,
        minWidth: 280,
        height: '70%',
    },

    '& .MuiDialogActions-root': {
        py: 2,
        px: 6,
    },
};

const TextPurposeModal: React.FC<IModalProps> = ({
    header = 'Add Modal Component',
    cancelButton = { label: 'Cancel', onCancel: () => {} },
    confirmButton = {
        label: 'Confirm',
        onConfirm: (template: ITemplate | undefined) => {},
    },
}) => {
    const defaultTemplate = useSelector(selectTemplate);

    const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>(defaultTemplate[0]);

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [categoryId, setCategoryId] = useState<number>(1);

    const [searchText, setSearchText] = useState<string>('');

    let cancelFetching = false;

    const fetchCategoryList = async () => {
        const response = await apiGetAllCategories();

        if (!cancelFetching) {
            if (response.data) {
                setCategories(response.data);
            }
        }
    };

    useEffect(() => {
        fetchCategoryList();
        return () => {
            cancelFetching = true; // eslint-disable-line react-hooks/exhaustive-deps
        };
    }, []);

    return (
        <Dialog
            open={true}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...modalStyle }}
        >
            <FlexCenter sx={{ height: 60, p: 6, bgcolor: '#F6F6F8' }}>
                <H4 sx={{ fontWeight: 700 }}>{header}</H4>
            </FlexCenter>
            <AlignStart sx={{ px: 4, py: 1 }}>
                <SearchInput
                    label={'Search'}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.preventDefault();

                        setSearchText(e.target.value);
                    }}
                    width={320}
                />
            </AlignStart>
            <Divider />
            <AlignStart sx={{ px: 4, py: 1 }}>
                <AppTabs
                    selections={categories}
                    onClick={selectionId => {
                        setCategoryId(selectionId);
                    }}
                />
            </AlignStart>
            <Divider />
            <DialogContent>
                <FlexColumn sx={{ p: 4, bgcolor: setColor('bg-grey') }}>
                    {categoryId === 1 ? (
                        categories.map(category => {
                            return (
                                <Box py={4} key={category.id}>
                                    <TemplateCardContainer
                                        currentTab="popular"
                                        category={category}
                                        categoryId={category?.id}
                                        selectedTemplate={selectedTemplate}
                                        setSelectedTemplate={setSelectedTemplate}
                                        searchText={searchText}
                                    />
                                </Box>
                            );
                        })
                    ) : (
                        <Box py={4}>
                            <TemplateCardContainer
                                currentTab="other"
                                categoryId={categoryId}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                searchText={searchText}
                            />
                        </Box>
                    )}
                </FlexColumn>
            </DialogContent>

            <DialogActions>
                <BorderlessButton
                    label={<P2 color={'fc-black'}>{cancelButton?.label}</P2>}
                    onClick={() => cancelButton?.onCancel()}
                />
                {!selectedTemplate ? (
                    <DefaultButton
                        disabled
                        label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                        onClick={() => confirmButton?.onConfirm(selectedTemplate)}
                    />
                ) : (
                    <DefaultButton
                        label={<P2 color={'fc-white'}>{confirmButton?.label}</P2>}
                        onClick={() => confirmButton?.onConfirm(selectedTemplate)}
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default TextPurposeModal;
