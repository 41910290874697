import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IAppState } from '@interfaces/api/IAppState';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import folderReducer from '@store/reducers/folderReducer';
import textCardReducer from '@store/reducers/textCardReducer';
import projectReducer from '@store/reducers/projectReducer';
import loaderReducer from '@store/reducers/loaderReducer';
import templateReducer from '@store/reducers/templateReducer';
import promptReducer from '@store/reducers/promptReducer';
import userReducer from '@store/reducers/userReducer';
import filterOptionReducer from '@store/reducers/filterOptionReducer';
import contentFilterReducer from './reducers/contentFilterReducer';
import textCardCollectionReducer from '@store/reducers/textCardCollectionReducer';

export const initialState: IAppState = {
    folders: [],
    projects: [],
    textCards: [],
    textCardCollection: {
        page: 1,
        count: 0,
        results: [],
    },
    templates: [],
    filterOption: [],
    prompt: {
        templateId: 0,
        templateName: '',
        templateIcon: '/assets/default.svg',
        productName: '',
        productDescription: '',
        tone: 'Neutral',
        keywords: '',
        projectId: 0,
        projectName: '',
        language: 'English',
        textLength: '38',
    },
    userInformation: {
        id: 0,
        email: '',
        is_active: false,
        is_banned: false,
        is_superuser: false,
        is_subscribed: false,
        date_joined: '',
        last_login: '',
        phone_number: '',
        total_tokens: 0,
    },
    loader: {
        is_loading: false,
    },
    contentFilter: {
        message: '',
    },
};

export const rootReducers: any = combineReducers({
    folders: folderReducer,
    projects: projectReducer,
    textCards: textCardReducer,
    textCardCollection: textCardCollectionReducer,
    templates: templateReducer,
    prompt: promptReducer,
    filterOption: filterOptionReducer,
    userInformation: userReducer,
    loader: loaderReducer,
    contentFilter: contentFilterReducer,
});

export const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);
